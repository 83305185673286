export const WhiteCloud = () => {
  return (
    // svg from figma has flat line on the right, that's why it needs 110% width and on parent overflow hidden
    <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', overflow: 'hidden' }}>
      <svg width="110%" viewBox="0 0 375 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-35 67.0001C-34.7743 66.0442 -34.3595 65.0425 -33.7189 63.9886C-29.9632 66.6626 -30.536 67.1199 -31.1512 64.6527C-31.2189 64.3936 -31.4813 64.1323 -31.834 64.0082C-37.105 57.6629 -13.1175 40.6565 -6.60212 35.6112C11.9564 21.0262 36.9851 10.0929 61.9207 5.0541C82.3049 1.27175 97.6946 9.79455 114.36 19.8525C118.745 25.98 126.677 21.649 132.695 19.0642C162.29 5.75961 216.114 -11.6562 244.853 10.8441C244.851 10.9813 244.893 11.1207 244.978 11.2644C244.935 11.2731 244.896 11.2927 244.876 11.3362C244.944 11.3645 245.009 11.3928 245.073 11.4212C245.076 11.4212 245.076 11.419 245.079 11.4168C245.897 12.4729 248.815 13.7315 251.908 15.3407C252.828 19.0925 263.316 22.7007 266.222 18.1366C273.034 16.8127 280.72 13.8774 287.755 14.5045C296.034 12.8169 310.075 21.368 319.039 25.1156C322.643 25.4378 338.275 30.3786 340.691 33.5099C352.655 39.7136 366.535 46.4443 373.479 56.2061C373.287 58.584 377.537 65.9832 372.616 67.0023L-35 67.0023L-35 67.0001ZM244.868 12.5164C242.418 10.9987 243.657 12.5034 244.645 12.6406C244.743 12.6231 244.817 12.5818 244.868 12.5164ZM246.414 13.235C246.29 13.1305 246.185 13.1305 246.106 13.235C246.23 13.3395 246.335 13.3395 246.414 13.235ZM249.492 14.4218C249.368 14.3173 249.264 14.3173 249.185 14.4218C249.309 14.5263 249.413 14.5263 249.492 14.4218ZM251.645 15.6085C251.521 15.504 251.417 15.504 251.338 15.6085C251.462 15.713 251.566 15.713 251.645 15.6085Z"
          fill="#FDFDFD"
        />
      </svg>
    </div>
  );
};
