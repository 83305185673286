import { RouteLayout } from 'features/ui/layout/routeLayout';
import paths from 'routing/utils';
import { HeroRibbon } from './heroRibbon';

export const Heroes = () => {
  return (
    <RouteLayout backRoute={paths.profile.base}>
      <div className="mt-5 mx-3">
        <HeroRibbon />
      </div>
    </RouteLayout>
  );
};
