import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Notification } from '../../shared/types/notification';

const SLICE_NAME = 'notificationSlice';

interface NotificationState {
  selectedNotification?: Notification;
}

const initialState: NotificationState = {};

export const notificationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSelectedNotification(state, action: PayloadAction<Notification>) {
      state.selectedNotification = action.payload;
    },
    notificationSliceReset() {
      return initialState;
    }
  }
});

export const { setSelectedNotification, notificationSliceReset } = notificationSlice.actions;

export default notificationSlice.reducer;
