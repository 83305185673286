import { Template } from 'shared/types/template';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTemplates: builder.query<Template[], void>({
      query: () => ({
        url: `/templates`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'Template' as const, id })), { type: 'Template', id: 'LIST' }]
          : [{ type: 'Template', id: 'LIST' }]
    }),
    getTemplateById: builder.query<Template, string>({
      query: id => ({
        url: `/templates/${id}`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetTemplatesQuery, useGetTemplateByIdQuery } = extendedApi;
