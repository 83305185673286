import { Typography } from 'features/ui/typography/typography';
import { Button, Modal } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import giftSpiralImg from './shared/images/gift-spiral.png';
import giftImg from './shared/images/gift.svg';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const GiftModal = (props: Props) => {
  return (
    <Modal centered show={props.open} onHide={() => props.onClose()}>
      <div style={{ position: 'relative', height: 170 }} className="w-100">
        <img src={giftSpiralImg} alt="" style={{ position: 'absolute', top: 0, left: 0, right: 0, margin: 'auto' }} />
        <img src={giftImg} alt="" style={{ position: 'absolute', bottom: 8, left: 0, right: 0, margin: 'auto' }} />
        <Modal.Header closeButton></Modal.Header>
      </div>

      <Modal.Body className="w-100">
        <Typography variant="h1" classNames="pb-1 text-center">
          Gratulacje
        </Typography>
        <Typography classNames="text-center" variant="description">
          Otrzymujesz{' '}
          <Typography variant="h4" inline>
            Pakiet 4 Baśniowych Piór na start!
          </Typography>
        </Typography>
        <Typography classNames="pb-4 text-center" variant="description">
          Stwórz teraz swoje własne bajki.
        </Typography>

        <Typography classNames="pb-4 text-center" variant="h4">
          Zarejestruj się teraz aby przypisać je do swojego konta.
        </Typography>
        <div className="d-flex flex-column align-items-center justify-content-center gap-3">
          <Button
            className="fullWidth"
            variant="primary"
            onClick={() => {
              router.navigate(paths.signUp.options);
              props.onClose();
            }}
          >
            Odbierz nagrodę
          </Button>
          <Button className="fullWidth" variant="outline-secondary" onClick={() => props.onClose()}>
            Zrezygnuj
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
