import styled from 'styled-components';

export const BlurOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transform: translate3d(0, 0, 0) !important;
`;
