import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NotificationSeverity } from 'shared/types/notification';
import { loadState } from 'store/utils';

const SLICE_NAME = 'paymentSlice';

type AwaitingNotification = { transactionId: string; status: NotificationSeverity } | null;

type ModalHandler = 'STORY_CREATOR' | 'DEFAULT' | null;

interface PaymentState {
  awaitingNotification: AwaitingNotification;
  source: string | null;
  modalHandler: ModalHandler;
  isPaymentInProgress: boolean;
  time: number;
}

const initialState: PaymentState = {
  awaitingNotification: null,
  source: null,
  modalHandler: null,
  isPaymentInProgress: false,
  time: 0
};

const persistedState = loadState<PaymentState>(SLICE_NAME);

export const paymentSlice = createSlice({
  name: SLICE_NAME,
  initialState: persistedState ?? initialState,
  reducers: {
    setAwaitingPaymentNotification(state, action: PayloadAction<AwaitingNotification>) {
      state.awaitingNotification = action.payload;
    },
    setIsPaymentInProgress(state, action: PayloadAction<boolean>) {
      state.isPaymentInProgress = action.payload;
    },
    setSource(state, action: PayloadAction<string>) {
      state.source = action.payload;
    },
    setModalHandler(state, action: PayloadAction<ModalHandler>) {
      state.modalHandler = action.payload;
    },
    setPaymentTime(state, action: PayloadAction<number>) {
      state.time = action.payload;
    },
    resetPaymentState() {
      return initialState;
    }
  }
});

export const { setAwaitingPaymentNotification, setIsPaymentInProgress, setPaymentTime, resetPaymentState, setSource, setModalHandler } =
  paymentSlice.actions;

export default paymentSlice.reducer;
