import { Moral } from 'shared/types/moral';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedMorals: builder.query<Moral[], void>({
      query: () => ({
        url: `/public/predefined-morals`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetPredefinedMoralsQuery } = extendedApi;
