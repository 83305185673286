import { Typography } from 'features/ui/typography/typography';
import { cSecondary, cWhite } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';
import tokenStatusSpiralImg from '../../../shared/images/token-status-spiral.png';
import { AddFundsButton } from '../../payments/addFundsButton';

export const CURRENT_BALANCE_CARD_WIDTH = 342;

type Props = {
  source: string;
};

export const CurrentBalanceCard = (props: Props) => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  return (
    <div
      style={{
        maxWidth: CURRENT_BALANCE_CARD_WIDTH,
        height: 172,
        backgroundColor: cSecondary,
        borderRadius: '12px',
        overflow: 'hidden'
      }}
    >
      <div style={{ position: 'relative' }} className="h-100 w-100 d-flex flex-column justify-content-between p-3">
        <img src={tokenStatusSpiralImg} alt="" style={{ position: 'absolute', right: 0, bottom: 0 }} />

        <div>
          <Typography variant="description-sm" styles={{ color: cWhite }}>
            Aktualny stan konta:
          </Typography>
          <div className="d-flex align-items-center gap-2">
            <Typography variant="h1" styles={{ color: cWhite }}>
              {loggedUser?.tokens ?? 0}
            </Typography>
            <div style={{ paddingRight: 4 }}>
              <img src={tokenIcon} alt="" />
            </div>
          </div>
        </div>
        <div style={{ width: 180 }}>
          <AddFundsButton label={'Doładuj konto'} source={props.source} />
        </div>
      </div>
    </div>
  );
};
