import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const WritingStyleTile = (props: TileProps) => {
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(paths.storyCreator.custom.writingStyle);
  };

  const getImageAndDir = () => {
    const writingStyle = storyCreatorSlice.writingStyle?.predefinedWritingStyle;
    const isWritingStylePicked = Boolean(writingStyle);
    const doesWritingStyleHaveImage = Boolean(writingStyle?.image);
    const writingStyleImage = writingStyle?.image;

    if (isWritingStylePicked) {
      if (doesWritingStyleHaveImage) {
        return {
          image: writingStyleImage,
          dir: 'common'
        };
      }
      return { image: 'writing_style.png', dir: 'common' };
    }

    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile cost={0} tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Styl pisania</Typography>
        <Typography variant="description">{storyCreatorSlice.writingStyle?.predefinedWritingStyle?.value ?? 'Losowy'}</Typography>
      </>
    </ElementTile>
  );
};
