import { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { cPrimary } from 'shared/utils/styleCommon';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const SurveyModal = (props: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Modal fullscreen show={props.open} onHide={() => props.onClose()}>
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdEVyw-F2lzmsbqCdqXeXhMSp7sp8ffL8YzYiM-koWAwBBt_Q/viewform?embedded=true"
          width={'100%'}
          height={'100%'}
          style={{ display: isLoaded ? 'block' : 'none' }}
          title="Google forms"
          onLoad={() => {
            setIsLoaded(true);
          }}
        >
          Ładuję…
        </iframe>

        {!isLoaded && (
          <div className="d-flex justify-content-center w-100 p-2">
            <Spinner animation="border" color={cPrimary} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
