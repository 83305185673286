import { World } from 'shared/types/world';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadWorldImage: builder.mutation<void, { userId: string; worldName: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/users/${data.userId}/worlds/${data.worldName}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    createWorld: builder.mutation<World, { userId: string; world: World }>({
      query: data => {
        return {
          url: `/users/${data.userId}/worlds`,
          method: HTTP_METHODS.POST,
          body: data.world
        };
      }
    }),
    updateWorld: builder.mutation<World, { userId: string; worldName: string; world: World }>({
      query: data => {
        return {
          url: `/users/${data.userId}/worlds/${data.worldName}`,
          method: HTTP_METHODS.PUT,
          body: data.world
        };
      }
    }),
    deleteWorld: builder.mutation<void, { userId: string; worldName: string }>({
      query: data => {
        return {
          url: `/users/${data.userId}/worlds/${data.worldName}`,
          method: HTTP_METHODS.DELETE
        };
      }
    })
  }),
  overrideExisting: false
});

export const { useUploadWorldImageMutation, useCreateWorldMutation, useUpdateWorldMutation, useDeleteWorldMutation } = extendedApi;
