import { useLocation } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useWindowDimensions } from 'shared/hooks/useWindowDimentions';
import { NavAddIcon } from 'shared/icons/navAddIcon';
import { NavHomeIcon } from 'shared/icons/navHomeIcon';
import { NavLibraryIcon } from 'shared/icons/navLibraryIcon';
import { NavProfileIcon } from 'shared/icons/navProfileIcon';
import { NavSearchIcon } from 'shared/icons/navSearchIcon';
import { cGrey200, cPrimary, cSecondary, cWhite } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { Typography } from '../typography/typography';

type NavigationButtonProps = {
  icon: JSX.Element;
  label: string;
  action: () => void;
  route: string;
};

const StyledNavigationButton = styled.div<{ isActive: boolean }>`
  color: ${props => (props.isActive ? cPrimary : cSecondary)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
  cursor: pointer;
  &:hover {
    color: ${cPrimary};
  }
`;

export const NavigationButton = (props: NavigationButtonProps) => {
  const location = useLocation();

  const getIsCurrentLocation = () => {
    if (location.pathname === paths.root && props.route === paths.root) {
      return true;
    } else if (location.pathname.startsWith(props.route) && props.route !== paths.root) {
      return true;
    } else {
      return false;
    }
  };

  const isCurrentLocation = getIsCurrentLocation();

  return (
    <StyledNavigationButton isActive={isCurrentLocation} onClick={() => props.action()}>
      {props.icon}
      <Typography variant="description-xxs" styles={{ color: 'inherit' }}>
        {props.label}
      </Typography>
    </StyledNavigationButton>
  );
};

const getNavigationButtons = (isLoggedIn: boolean): NavigationButtonProps[] => {
  return [
    {
      action: () => router.navigate(paths.root),
      icon: <NavHomeIcon />,
      label: 'Home',
      route: paths.root
    },
    {
      action: () => router.navigate(paths.search.base),
      icon: <NavSearchIcon />,
      label: 'Szukaj',
      route: paths.search.base
    },
    {
      action: () => router.navigate(paths.storyCreator.custom.base),
      icon: <NavAddIcon />,
      label: 'Bajka',
      route: paths.storyCreator.custom.base
    },
    {
      action: () => router.navigate(paths.library),
      icon: <NavLibraryIcon />,
      label: 'Biblioteka',
      route: paths.library
    },
    {
      action: () => router.navigate(isLoggedIn ? paths.profile.base : paths.signIn.options),
      icon: <NavProfileIcon />,
      label: isLoggedIn ? 'Profil' : 'Zaloguj',
      route: isLoggedIn ? paths.profile.base : paths.signIn.options
    }
  ];
};

export const BottomNavigation = () => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // other
  const isMobile = useWindowDimensions().width <= 576;

  return (
    <div
      className={`pt-2 pb-4 px-2 d-flex flex-nowrap ${isMobile ? 'justify-content-around' : 'justify-content-center'} align-items-center ${
        isMobile ? 'gap-2' : 'gap-4'
      } w-100`}
      style={{ borderTop: `1px solid ${cGrey200}`, backgroundColor: cWhite, height: 85, maxHeight: 85 }}
    >
      {getNavigationButtons(!!loggedUser).map(b => (
        <NavigationButton action={b.action} icon={b.icon} label={b.label} key={b.route} route={b.route} />
      ))}
    </div>
  );
};
