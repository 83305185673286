import { Divider } from 'features/ui/divider/divider';
import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/esm/Form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { pxToRem } from 'shared/utils/commonUtils';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { cGrey600, cPrimary } from 'shared/utils/styleCommon';
import { useSignInMutation } from 'store/api/endpoints/accountEndpoints';
import { useIsGeneratingQuery } from 'store/api/endpoints/generatorEndpoints';
import { useAppSelector } from 'store/hooks';
import { HeaderStorytailo } from './headerStorytailo';

interface SignInFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const SignInByEmail = (): JSX.Element => {
  const [loginError, setLoginError] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [signIn, { error, isLoading }] = useSignInMutation();
  const { refetch: refetchIsGenerating } = useIsGeneratingQuery();
  // other
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<SignInFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false
    }
  });

  useEffect(() => {
    if (!error) {
      setLoginError('');
      return;
    }
    if ('status' in error && error.status === 401) {
      setLoginError('Niepoprawne dane logowania');
    } else {
      setLoginError('Wystąpił błąd podczas logowania');
    }
  }, [error]);

  useEffect(() => {
    if (loggedUser) {
      navigate(paths.root, { replace: true });
      refetchIsGenerating();
    }
  }, [loggedUser, navigate, refetchIsGenerating]);

  const onSubmit = (data: SignInFormValues) => {
    signIn({ username: data.email.toLowerCase(), password: data.password, rememberMe: data.rememberMe });
  };

  return (
    <RouteLayout backRoute={-1}>
      <Loadable loading={isLoading}>
        <>
          <HeaderStorytailo headerText="Zaloguj się" />
          <div className="d-flex flex-column   pb-5 mx-3">
            <div>
              <div style={{ paddingTop: 32 }}>{loginError ? <FormError error={loginError} /> : ''}</div>
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Form.Group className="mb-3" controlId="signInForm.email">
                  <Form.Label>E-mail</Form.Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} />
                        {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                      </>
                    )}
                    rules={{
                      required: validationMessage.required,
                      validate: validateEmail
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="signInForm.password">
                  <Form.Label>Hasło</Form.Label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} />
                        {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                      </>
                    )}
                    rules={{
                      required: validationMessage.required
                    }}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end align-items-center flex-wrap">
                  <Button
                    variant="link"
                    onClick={() => navigate(paths.resetPassword)}
                    style={{ fontSize: pxToRem(18), color: cGrey600, textDecoration: 'none', padding: 0, fontWeight: '800' }}
                  >
                    Przypomnij hasło
                  </Button>
                </div>

                <JustifyCenter classNames="pt-5 pb-4">
                  <Button className={'long'} type="submit" disabled={isLoading}>
                    Zaloguj się
                  </Button>
                </JustifyCenter>
              </Form>
            </div>
            <Divider spacing={'my-3'} />
            <div className="d-flex flex-column align-items-center mx-4 mb-4">
              <Typography variant="description-md" styles={{ color: cGrey600 }}>
                Nie masz jeszcze konta?
              </Typography>
              <Typography variant="h5" styles={{ color: cPrimary, cursor: 'pointer' }} onClick={() => navigate(paths.signUp.options)}>
                Zarejestruj się
              </Typography>
            </div>
          </div>
        </>
      </Loadable>
    </RouteLayout>
  );
};
