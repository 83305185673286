import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { Product, ProductType, isTokenPackageProduct } from 'shared/types/product';
import { useGetProductsByTypeQuery } from 'store/api/endpoints/productEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPaymentState } from 'store/slices/paymentSlice';
import tokenPackagesSpiralImg from '../../../shared/images/token-packages-spiral.png';
import { PaymentLoader } from './paymentLoader';
import { TokenPackageItem } from './tokenPackageItem';

const defaultProducts: Product[] = [];

export const TokenPackageList = () => {
  const isPaymentInProgress = useAppSelector(state => state.paymentSlice.isPaymentInProgress);
  const dispatch = useAppDispatch();
  const { data: products = defaultProducts } = useGetProductsByTypeQuery({ type: ProductType.TOKEN_PACKAGE });

  return isPaymentInProgress ? (
    <RouteLayout
      hideBottomNavigation
      actions={[
        {
          label: 'Anuluj',
          action: () => {
            dispatch(resetPaymentState());
          }
        }
      ]}
    >
      <PaymentLoader />
    </RouteLayout>
  ) : (
    <RouteLayout hideBottomNavigation backRoute={-1} backRouteText="Doładuj konto">
      <div className="my-4 mx-3">
        <Typography variant="h1" classNames="pb-2">
          Pakiety Baśniowych Piór
        </Typography>
        <Typography variant="description" classNames="pb-4" styles={{ position: 'relative' }}>
          <img src={tokenPackagesSpiralImg} alt="" style={{ position: 'absolute', right: 0, top: '50%' }} />
          Baśniowe Pióra Storytailo to przedmioty, które dają naszym autorom magiczną moc. Dzięki nim możesz słuchać dostosowanych bajek z
          lektorem premium oraz tworzyć własne, zupełnie nowe bajki.
        </Typography>
        <div>
          {_.sortBy(products, 'price')
            .filter(p => isTokenPackageProduct(p))
            .map(p => (
              <TokenPackageItem key={p.id} product={p} />
            ))}
        </div>
      </div>
    </RouteLayout>
  );
};
