export const HeroesIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" width="48" height="48" rx="24" fill="#FDFDFD" />
      <rect x="1.25" y="1" width="46" height="46" rx="23" stroke="#EC8D29" stroke-opacity="0.2" stroke-width="2" />
      <path
        d="M24.0698 18C25.1744 18 26.0698 17.1046 26.0698 16C26.0698 14.8954 25.1744 14 24.0698 14C22.9653 14 22.0698 14.8954 22.0698 16C22.0698 17.1046 22.9653 18 24.0698 18Z"
        fill="#EC8D29"
      />
      <path
        d="M27.9598 20.11C27.5698 19.72 26.8998 19 25.5998 19C25.3898 19 24.1798 19 23.0598 19C20.3098 18.99 18.0698 16.75 18.0698 14H16.0698C16.0698 17.16 18.1798 19.84 21.0698 20.71V34H23.0698V28H25.0698V34H27.0698V22.05L31.0198 26L32.4298 24.59L27.9598 20.11Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
