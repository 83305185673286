export const NavAddIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34 21C34 21.5523 33.5523 22 33 22H23C22.4477 22 22 22.4477 22 23V33C22 33.5523 21.5523 34 21 34H19C18.4477 34 18 33.5523 18 33V23C18 22.4477 17.5523 22 17 22H7C6.44772 22 6 21.5523 6 21V19C6 18.4477 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 18.4477 6 19 6H21C21.5523 6 22 6.44772 22 7V17C22 17.5523 22.4477 18 23 18H33C33.5523 18 34 18.4477 34 19V21Z"
        fill="currentColor"
      />
    </svg>
  );
};
