import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import { useAppDispatch } from 'store/hooks';
import { setTempMainHero, setTempRecipient, setTempSideHero } from 'store/slices/storyCreatorSlice';
import { HeroCreator } from '../hero-profile/heroCreator';
import { ChildProfileCreator } from './childProfileCreator';

type Props = {
  backRoute: string;
};

export const MainHeroChildProfileCreatorWrapper = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const { childId } = useParams();

  return (
    <ChildProfileCreator
      onDeleteCallback={() => {
        dispatch(setTempMainHero(undefined));
        router.navigate(props.backRoute);
      }}
      onSaveCallback={profile => {
        dispatch(setTempMainHero({ random: false, character: { childProfile: true, id: profile.id } }));
        router.navigate(props.backRoute);
      }}
      backRoute={props.backRoute}
      childId={childId}
    />
  );
};

export const MainCharacterHeroCreatorWrapper = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const { heroId } = useParams();

  return (
    <HeroCreator
      onDeleteCallback={() => {
        dispatch(setTempMainHero(undefined));
        router.navigate(props.backRoute);
      }}
      onSaveCallback={hero => {
        dispatch(setTempMainHero({ random: false, character: { childProfile: false, id: hero.id } }));
        router.navigate(props.backRoute);
      }}
      backRoute={props.backRoute}
      heroId={heroId}
    />
  );
};

export const SideCharacterHeroCreatorWrapper = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const { heroId } = useParams();

  return (
    <HeroCreator
      onDeleteCallback={() => {
        dispatch(setTempSideHero(undefined));
        router.navigate(props.backRoute);
      }}
      onSaveCallback={hero => {
        dispatch(setTempSideHero({ random: false, character: { childProfile: false, id: hero.id } }));
        router.navigate(props.backRoute);
      }}
      backRoute={props.backRoute}
      heroId={heroId}
    />
  );
};

export const RecipientChildProfileCreatorWrapper = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const { childId } = useParams();

  return (
    <ChildProfileCreator
      onDeleteCallback={() => {
        dispatch(setTempRecipient(undefined));
        router.navigate(props.backRoute);
      }}
      onSaveCallback={profile => {
        dispatch(setTempRecipient(profile.id));
        router.navigate(props.backRoute);
      }}
      backRoute={props.backRoute}
      childId={childId}
    />
  );
};

export const ChildProfileCreatorWrapper = (props: Props) => {
  const { childId } = useParams();

  return (
    <ChildProfileCreator
      onDeleteCallback={() => router.navigate(props.backRoute)}
      onSaveCallback={() => router.navigate(props.backRoute)}
      backRoute={props.backRoute}
      childId={childId}
    />
  );
};

export const HeroCreatorWrapper = (props: Props) => {
  const { heroId } = useParams();

  return (
    <HeroCreator
      onDeleteCallback={() => router.navigate(props.backRoute)}
      onSaveCallback={() => router.navigate(props.backRoute)}
      backRoute={props.backRoute}
      heroId={heroId}
    />
  );
};
