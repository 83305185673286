import { skipToken } from '@reduxjs/toolkit/query';
import { StoryCreatorPaymentNotificationHandler } from 'features/payments/modals/handlers/storyCreatorPaymentNotificationHandler';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { NarratorType, PredefinedNarrator } from 'shared/types/narrator';
import { TemplateStoryParams } from 'shared/types/story';
import { TemplateParam } from 'shared/types/template';
import { cPrimary } from 'shared/utils/styleCommon';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useGenerateFromTemplateMutation } from 'store/api/endpoints/generatorEndpoints';
import { useGetPredefinedNarratorsQuery } from 'store/api/endpoints/predefinedNarratorsEndpoints';
import { useGetTemplateByIdQuery } from 'store/api/endpoints/templateEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { setModalHandler, setSource } from 'store/slices/paymentSlice';
import { resetCreatorState, setNarrator } from 'store/slices/storyCreatorSlice';
import { resetStoryGeneratorState, setIsGenerating } from 'store/slices/storyGeneratorSlice';
import createStoryImg from './create-story.png';
import { CreateStoryButtonBottomElement } from './createStoryButton';
import { HeroesTile } from './element-tiles/heroesTile';
import { NarratorTile } from './element-tiles/narratorTile';
import { OtherTile } from './element-tiles/otherTile';
import { RecipientTile } from './element-tiles/recipientTile';
import { useParamsToTileSectionsConverter } from './hooks/useParamsToTilesConverter';
import { InsufficientTokensModal } from './insufficientTokensModal';
import { StoryCreatorLoader } from './loader/storyCreatorLoader';
import { getHeroName } from './utils';

const defaultTemplateParams: TemplateParam[] = [];
const defaultPredefinedNarrators: PredefinedNarrator[] = [];

function hasMoreThanOneWord(input?: string): boolean {
  if (!input) {
    return false;
  }
  const regex = /\s+/;
  return regex.test(input.trim());
}

export const TemplateStoryCreator = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [incorrectParams, setIncorrectParams] = useState<string[]>([]);
  const [isInsufficientTokensModalOpen, setIsInsufficientTokensModalOpen] = useState<boolean>(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const generatedStoryId = useAppSelector(state => state.storyGeneratorSlice.generatedStoryId);
  const isGenerating = useAppSelector(state => state.storyGeneratorSlice.isGenerating);
  const templateId = useAppSelector(state => state.storyCreatorSlice.templateId);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  const dispatch = useAppDispatch();
  // rtk
  const { data: template } = useGetTemplateByIdQuery(templateId ?? skipToken);
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const { data: predefinedNarrators = defaultPredefinedNarrators } = useGetPredefinedNarratorsQuery();
  const [generate] = useGenerateFromTemplateMutation();

  const tileSections = useParamsToTileSectionsConverter(template?.templateParams ?? defaultTemplateParams);

  const mainHeroName = getHeroName(loggedUser, storyCreatorSlice.mainHero);

  useEffect(() => {
    if (!narrator) {
      const freeNarrator = predefinedNarrators.find(pn => pn.type === NarratorType.NORMAL);
      freeNarrator && dispatch(setNarrator(freeNarrator));
    }
  }, [dispatch, narrator, predefinedNarrators]);

  useEffect(() => {
    if (!_.isNil(generatedStoryId)) {
      dispatch(resetStoryGeneratorState());
      dispatch(resetCreatorState());
      refetchUser();
      router.navigate(paths.story + `/${generatedStoryId}`, { state: 'FROM_GENERATOR' });
    }
  }, [dispatch, generatedStoryId, refetchUser]);

  const validateCompletion = () => {
    if (!template?.templateParams) {
      return false;
    }

    let areParamsCompleted = true;
    template?.templateParams.forEach(param => {
      if (param.key === 'MAIN_HERO1') {
        if (_.isNil(mainHeroName)) {
          areParamsCompleted = false;
        }
      } else {
        if (_.isNil(storyCreatorSlice.other?.[param.key ?? ''])) {
          areParamsCompleted = false;
        }
      }
    });
    return areParamsCompleted;
  };

  const totalCost = getTotalCost('template', narrator);

  const generateTemplateStory = () => {
    if (!templateId) {
      return;
    }

    if (!validateCompletion()) {
      return;
    }

    if ((loggedUser?.tokens ?? 0) < totalCost) {
      setIsInsufficientTokensModalOpen(true);
      return;
    }

    const map = new Map<string, string | undefined>();

    const paramsWithMoreThanOneWord: string[] = []; // TODO - needed until template generator doesn't have possibility of handling two or more words

    template?.templateParams.forEach(param => {
      switch (param.key) {
        case 'MAIN_HERO1':
          map.set('MAIN_HERO1', mainHeroName);

          if (hasMoreThanOneWord(mainHeroName)) {
            paramsWithMoreThanOneWord.push(`${param.label}: ${mainHeroName}`);
          }
          break;

        default:
          const value = storyCreatorSlice.other?.[param.key ?? ''];
          map.set(param.key, value);

          if (hasMoreThanOneWord(value)) {
            paramsWithMoreThanOneWord.push(`${param.label}: ${value}`);
          }
      }
    });

    if (!_.isEmpty(paramsWithMoreThanOneWord)) {
      setIncorrectParams(paramsWithMoreThanOneWord);
      setIsConfirmationDialogOpen(true);
      return;
    }

    if (!storyCreatorSlice.narrator) {
      dispatch(addAlert({ color: 'warning', text: 'Lektor nie został wybrany' }));
      return;
    }

    let jsonObject: any = {};
    map.forEach((value, key) => {
      jsonObject[key] = value;
    });

    const body: TemplateStoryParams = {
      narrator: storyCreatorSlice.narrator,
      templateParams: jsonObject
    };

    dispatch(setIsGenerating(true));

    generate({ templateStoryParams: body, templateId })
      .unwrap()
      .catch(() => {
        dispatch(setIsGenerating(false));
        dispatch(addAlert({ color: 'danger', text: 'Nie udało się utworzyć bajki' }));
      });
  };

  return isGenerating ? (
    <StoryCreatorLoader />
  ) : (
    <RouteLayout
      bottomElement={
        <CreateStoryButtonBottomElement createStory={generateTemplateStory} disabled={!validateCompletion()} storyType="template" />
      }
    >
      <StoryCreatorPaymentNotificationHandler storyType="template" onAcceptGeneratorPaymentSuccess={generateTemplateStory} />
      <InsufficientTokensModal
        open={isInsufficientTokensModalOpen}
        totalCost={totalCost}
        onClose={() => {
          setIsInsufficientTokensModalOpen(false);
        }}
        onAccept={() => {
          setIsInsufficientTokensModalOpen(false);
          dispatch(setSource(paths.storyCreator.template.base));
          dispatch(setModalHandler('STORY_CREATOR'));
          router.navigate(paths.payments.packages);
        }}
      />
      <Modal centered show={isConfirmationDialogOpen} onHide={() => setIsConfirmationDialogOpen(false)}>
        <Modal.Header closeButton>
          <Typography variant="h1" classNames="pb-3">
            Uwaga!
          </Typography>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Typography variant="description" classNames="pb-3">
            Proszę upewnij się, że każdy z podanych parametrów zawiera tylko jedno słowo, ponieważ w przeciwnym razie nie będzie można
            wygenerować bajki na podstawie szablonu.
          </Typography>
          {incorrectParams.map(ip => (
            <Typography classNames="pb-2" variant="description">
              {ip}
            </Typography>
          ))}
        </Modal.Body>
      </Modal>
      <div style={{ position: 'relative' }}>
        <img src={createStoryImg} alt="" width="100%" />
        <WhiteCloud />
      </div>
      <div className="mx-3">
        <div className="d-flex justify-content-between align-items-center">
          <Typography variant="h1" classNames="pb-5">
            Twoja Bajka
          </Typography>
          <StoryCostBadge cost={totalCost} />
        </div>

        <div className="d-flex flex-column gap-2 pb-4">
          {tileSections.recipient && (
            <>
              <RecipientTile customCreator={false} tileId="RecipientTile" />
              <div className="w-100 my-2" style={{ borderBottom: `2px solid ${cPrimary}` }} />
            </>
          )}

          {(tileSections.mainHero || tileSections.sideHero) && <HeroesTile customCreator={false} tileId="HeroesTile" />}

          <NarratorTile customCreator={false} tileId="NarratorTile" />

          {!_.isEmpty(tileSections.other) && <div className="w-100 my-2" style={{ borderBottom: `2px solid ${cPrimary}` }} />}

          {tileSections.other.map(other => (
            <OtherTile other={other} tileId={`OtherTile-${other.key}`} />
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
