export const TimerForwardIcon = () => {
  return (
    <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.374 6.33334L20.0407 3.33334C19.374 2.66667 19.374 1.66667 19.874 1C20.374 0.333335 21.5407 0.166669 22.2074 0.833335L26.874 5C27.2074 5.33334 27.374 5.83334 27.374 6.16667C27.374 6.66667 27.2074 7 26.874 7.33334L22.374 12C22.0407 12.3333 21.5407 12.5 21.2074 12.5C20.7074 12.5 20.374 12.3333 20.0407 12C19.374 11.3333 19.374 10.3333 20.0407 9.66667L23.374 6.33334Z"
        fill="#112B52"
      />
      <path
        d="M29.7077 14.3333C30.2077 15.8333 30.541 17.3333 30.541 18.8333C30.541 20.6666 30.3743 22.6666 29.7077 24.3333C28.2077 28.6666 24.8743 31.9999 20.541 33.4999C18.8743 33.9999 17.2077 34.3333 15.541 34.3333C13.541 34.3333 11.3743 33.8333 9.54101 32.9999C5.70768 31.3333 2.70768 28.1666 1.37435 24.3333C0.0410137 20.4999 0.20768 16.4999 2.04101 12.8333C3.87435 9.16658 6.70768 6.49992 10.541 5.16659C12.041 4.66659 13.3743 4.33325 14.8743 4.33325L13.541 3.16659C12.8743 2.49992 12.8743 1.49992 13.541 0.833252C14.2077 0.166585 15.2077 0.166585 15.8743 0.833252L20.541 4.99992C20.7077 5.33325 21.041 5.66659 21.041 6.16659C21.041 6.66659 20.8743 6.99992 20.541 7.33325L15.8743 11.8333C15.541 12.1666 15.2077 12.3333 14.7077 12.3333C14.2077 12.3333 13.8743 12.1666 13.541 11.8333C12.8743 11.1666 12.8743 10.1666 13.541 9.49992L15.541 7.66659C14.2077 7.66659 13.041 7.83325 11.7077 8.33325C8.70768 9.33325 6.37435 11.4999 5.04102 14.3333C3.70768 17.1666 3.54101 20.3333 4.54101 23.3333C5.54101 26.3333 7.87435 28.8333 10.8743 30.1666C13.541 31.3333 16.541 31.4999 19.3743 30.4999C22.7077 29.3333 25.3743 26.6666 26.541 23.3333C27.041 21.8333 27.2077 20.3333 27.2077 18.8333C27.2077 17.6666 26.8743 16.4999 26.541 15.3333C26.2077 14.4999 26.7077 13.4999 27.541 13.1666C28.3743 12.9999 29.3743 13.4999 29.7077 14.3333Z"
        fill="#112B52"
      />
      <path
        d="M10.3201 22.3329H11.8463V18.3267C11.8463 18.1305 11.8517 17.9252 11.8626 17.7108L10.9633 18.4576C10.9051 18.5048 10.847 18.5393 10.7888 18.5611C10.7307 18.5793 10.6744 18.5884 10.6199 18.5884C10.5254 18.5884 10.44 18.5684 10.3637 18.5284C10.291 18.4848 10.2365 18.4394 10.2002 18.3922L9.66602 17.6836L12.1406 15.6069H13.5359V22.3329H14.8441V23.5539H10.3201V22.3329Z"
        fill="#112B52"
      />
      <path
        d="M21.3747 19.5804C21.3747 20.2744 21.3002 20.8758 21.1513 21.3845C21.0023 21.8933 20.7952 22.3148 20.5299 22.6491C20.2683 22.9834 19.9558 23.2323 19.5924 23.3958C19.2327 23.5593 18.842 23.6411 18.4205 23.6411C17.999 23.6411 17.6084 23.5593 17.2486 23.3958C16.8925 23.2323 16.5837 22.9834 16.322 22.6491C16.0604 22.3148 15.8551 21.8933 15.7061 21.3845C15.5608 20.8758 15.4881 20.2744 15.4881 19.5804C15.4881 18.8863 15.5608 18.285 15.7061 17.7762C15.8551 17.2675 16.0604 16.846 16.322 16.5117C16.5837 16.1774 16.8925 15.9303 17.2486 15.7704C17.6084 15.6069 17.999 15.5251 18.4205 15.5251C18.842 15.5251 19.2327 15.6069 19.5924 15.7704C19.9558 15.9303 20.2683 16.1774 20.5299 16.5117C20.7952 16.846 21.0023 17.2675 21.1513 17.7762C21.3002 18.285 21.3747 18.8863 21.3747 19.5804ZM19.6305 19.5804C19.6305 19.0317 19.596 18.5829 19.527 18.2341C19.4579 17.8816 19.3653 17.6055 19.249 17.4056C19.1364 17.2057 19.0074 17.0695 18.862 16.9968C18.7167 16.9205 18.5695 16.8823 18.4205 16.8823C18.2715 16.8823 18.1244 16.9205 17.979 16.9968C17.8373 17.0695 17.7101 17.2057 17.5975 17.4056C17.4885 17.6055 17.3994 17.8816 17.3304 18.2341C17.265 18.5829 17.2323 19.0317 17.2323 19.5804C17.2323 20.1327 17.265 20.5833 17.3304 20.9321C17.3994 21.281 17.4885 21.5571 17.5975 21.7606C17.7101 21.9605 17.8373 22.0986 17.979 22.1749C18.1244 22.2475 18.2715 22.2839 18.4205 22.2839C18.5695 22.2839 18.7167 22.2475 18.862 22.1749C19.0074 22.0986 19.1364 21.9605 19.249 21.7606C19.3653 21.5571 19.4579 21.281 19.527 20.9321C19.596 20.5833 19.6305 20.1327 19.6305 19.5804Z"
        fill="#112B52"
      />
    </svg>
  );
};
