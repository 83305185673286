import { cSecondary } from 'shared/utils/styleCommon';
import tokenIcon from '../../shared/icons/tokenIcon.svg';
import tokenBadgeSpiralImg from '../../shared/images/token-badge-spiral.png';
import { AddFundsButton } from '../payments/addFundsButton';
import paths from 'routing/utils';

type Props = {
  tokens: number;
};

export const TokenFloatingButton = (props: Props) => (
  <div
    className="p-3"
    style={{
      zIndex: 100,
      position: 'fixed',
      overflow: 'hidden',
      backgroundColor: cSecondary,
      borderRadius: '40px 0px 0px 40px',
      right: 0,
      top: 0
    }}
  >
    <div style={{ position: 'relative' }} className="h-100 w-100 d-flex justify-content-center align-items-center">
      <img src={tokenBadgeSpiralImg} alt="" width="100%" style={{ position: 'absolute', opacity: 0.1 }} />

      <AddFundsButton label={props.tokens} icon={<img src={tokenIcon} alt="" />} source={paths.root} />
    </div>
  </div>
);
