import { Typography } from 'features/ui/typography/typography';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { cError, cSecondary, cSuccess } from 'shared/utils/styleCommon';

interface PasswordRulesProps {
  password: string;
  disabled: boolean;
}

export const PasswordRules = (props: PasswordRulesProps): JSX.Element => {
  const rules = [
    {
      pattern: /[a-z]/,
      message: 'Co najmniej jedna mała litera'
    },
    {
      pattern: /[A-Z]/,
      message: 'Co najmniej jedna wielka litera'
    },
    {
      pattern: /[0-9]/,
      message: 'Co najmniej jedna cyfra'
    },
    {
      pattern: /[^a-zA-Z0-9]/,
      message: 'Co najmniej jednen znak specjalny'
    },
    {
      pattern: /.{8,}/,
      message: 'Minimalna długość hasła to 8 znaków'
    }
  ];
  const getColor = (pattern: RegExp) => {
    if (props.disabled) {
      return cSecondary;
    } else {
      return props.password.match(pattern) ? cSuccess : cError;
    }
  };

  return (
    <>
      <Typography variant="description-xs">Wymagania dotyczące hasła:</Typography>
      <ListGroup>
        {rules.map((rule, index) => (
          <ListGroup.Item key={index} color={getColor(rule.pattern)} style={{ border: ' none', padding: '0 0 0 4px' }}>
            <Typography variant="description-xs" styles={{ color: getColor(rule.pattern) }}>
              {rule.message}
            </Typography>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};
