import { Hero } from 'shared/types/users';
import { HTTP_METHODS, apiSlice } from '../api';

export type HeroSaveRequest = Omit<Hero, 'id'>;

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadHeroImage: builder.mutation<void, { userId: string; heroId: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/users/${data.userId}/heroes/${data.heroId}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    createHero: builder.mutation<Hero, { userId: string; hero: HeroSaveRequest }>({
      query: data => {
        return {
          url: `/users/${data.userId}/heroes`,
          method: HTTP_METHODS.POST,
          body: data.hero
        };
      }
    }),
    updateHero: builder.mutation<Hero, { userId: string; hero: Hero }>({
      query: data => {
        return {
          url: `/users/${data.userId}/heroes`,
          method: HTTP_METHODS.PUT,
          body: data.hero
        };
      }
    }),
    deleteHero: builder.mutation<void, { userId: string; heroId: string }>({
      query: data => {
        return {
          url: `/users/${data.userId}/heroes/${data.heroId}`,
          method: HTTP_METHODS.DELETE
        };
      }
    })
  }),
  overrideExisting: false
});

export const { useUploadHeroImageMutation, useCreateHeroMutation, useUpdateHeroMutation, useDeleteHeroMutation } = extendedApi;
