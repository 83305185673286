import { PredefinedEpoch } from 'shared/types/epoch';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedEpochs: builder.query<PredefinedEpoch[], void>({
      query: () => ({
        url: `/public/predefined-epochs`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetPredefinedEpochsQuery } = extendedApi;
