import _ from 'lodash';
import { useMemo } from 'react';
import { SubscriptionType } from 'shared/types/users';
import { useAppSelector } from 'store/hooks';

export const convertToAccessLevel = (subscriptionType?: SubscriptionType) => {
  switch (subscriptionType) {
    case SubscriptionType.FREE:
      return 0;
    case SubscriptionType.BASIC:
      return 1;
    case SubscriptionType.ADVANCED:
      return 2;
    default:
      return 0;
  }
};

export const convertToSubscriptionType = (accessLevel?: number) => {
  switch (accessLevel) {
    case 0:
      return SubscriptionType.FREE;
    case 1:
      return SubscriptionType.BASIC;
    case 2:
      return SubscriptionType.ADVANCED;
    default:
      return SubscriptionType.FREE;
  }
};

export const hasAccess = (requiredSubscriptionType?: SubscriptionType, userSubscriptionType?: SubscriptionType) => {
  if (!requiredSubscriptionType || !userSubscriptionType) {
    return requiredSubscriptionType === SubscriptionType.FREE || _.isNil(requiredSubscriptionType);
  }
  const requiredAccessLevel = convertToAccessLevel(requiredSubscriptionType);
  const userAccessLevel = convertToAccessLevel(userSubscriptionType);
  return userAccessLevel >= requiredAccessLevel;
};

export const useHasSufficientSub = (requiredSubscriptionType?: SubscriptionType): boolean => {
  // redux
  const userSubscriptionType = useAppSelector(state => state.authSlice.loggedUser)?.subscription?.type;

  return useMemo(() => {
    return hasAccess(requiredSubscriptionType, userSubscriptionType);
  }, [requiredSubscriptionType, userSubscriptionType]);
};
