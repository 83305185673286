import { useMemo } from 'react';
import { TemplateParam } from 'shared/types/template';

export const useParamsToTileSectionsConverter = (templateParams: TemplateParam[]) => {
  return useMemo(() => {
    const tileSections: {
      recipient: TemplateParam | null;
      mainHero: TemplateParam | null;
      sideHero: TemplateParam | null;
      other: TemplateParam[];
    } = {
      recipient: null,
      mainHero: null,
      sideHero: null,
      other: []
    };

    templateParams.forEach(param => {
      switch (param.key) {
        case 'RECIPIENT':
          tileSections.recipient = param;
          break;
        case 'MAIN_HERO1':
          tileSections.mainHero = param;
          break;
        case 'SIDE_HERO':
          tileSections.sideHero = param;
          break;
        default:
          tileSections.other.push(param);
          break;
      }
    });
    return tileSections;
  }, [templateParams]);
};
