import { PRODUCTION_ENV } from 'index';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

export function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_TRACKING_ID, {
        testMode: process.env.NODE_ENV !== PRODUCTION_ENV
      });
    }
  }, []);

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
}
