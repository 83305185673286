import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loadState } from 'store/utils';

const SLICE_NAME = 'generatorSlice';

interface StoryGeneratorState {
  generatedStoryId: string | null;
  isGenerating: boolean;
  time: number;
}

const initialState: StoryGeneratorState = {
  generatedStoryId: null,
  isGenerating: false,
  time: 0
};

const persistedState = loadState<StoryGeneratorState>(SLICE_NAME);

export const storyGeneratorSlice = createSlice({
  name: SLICE_NAME,
  initialState: persistedState ?? initialState,
  reducers: {
    setGeneratedStoryId(state, action: PayloadAction<string>) {
      state.generatedStoryId = action.payload;
    },
    setIsGenerating(state, action: PayloadAction<boolean>) {
      state.isGenerating = action.payload;
    },
    setGeneratorTime(state, action: PayloadAction<number>) {
      state.time = action.payload;
    },
    resetStoryGeneratorState() {
      return initialState;
    }
  }
});

export const { setGeneratedStoryId, resetStoryGeneratorState, setIsGenerating, setGeneratorTime } = storyGeneratorSlice.actions;

export default storyGeneratorSlice.reducer;
