import { getTotalCost } from 'features/payments/utils.payments';
import { CustomImage } from 'features/ui/image/customImage';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { cPrimary, cWhite } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';
import tokenIcon from '../../shared/icons/tokenIcon.svg';

type CreateStoryButtonBottomElementProps = {
  disabled: boolean;
  storyType: 'custom' | 'template';
  createStory: () => void;
};

export const CreateStoryButtonBottomElement = (props: CreateStoryButtonBottomElementProps) => {
  return (
    <div style={{ position: 'relative', height: 'auto' }}>
      <CustomImage
        directory="common"
        filename="spiral2.png"
        styles={{
          position: 'absolute',
          zIndex: -1,
          right: 0,
          left: 0,
          top: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          opacity: 0.5
        }}
      />
      <div style={{ position: 'relative' }}>
        <JustifyCenter classNames="py-3">
          <CreateStoryButton storyType={props.storyType} onClick={props.createStory} disabled={props.disabled} fullWidth={false} />
        </JustifyCenter>
      </div>
    </div>
  );
};

type Props = {
  storyType: 'custom' | 'template';
  disabled: boolean;
  fullWidth: boolean;
  onClick: () => void;
};

export const CreateStoryButton = (props: Props) => {
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);

  const getCostLabel = () => {
    const totalCost = getTotalCost(props.storyType, narrator);
    switch (totalCost) {
      case 0:
        return (
          <Typography variant="description-sm" styles={{ color: cWhite }} inline>
            {'('}
            <Typography variant="description-sm" styles={{ color: cPrimary }} inline>
              free
            </Typography>
            {')'}
          </Typography>
        );
      default:
        return (
          <Typography
            variant="description-sm"
            styles={{ color: cWhite }}
            inline
            classNames="d-flex justify-content-center align-items-center"
          >
            {'('}
            <Typography
              variant="description-sm"
              styles={{ color: cWhite }}
              inline
              classNames="d-flex justify-content-center align-items-center"
            >
              {totalCost}
              <img src={tokenIcon} alt="" style={{ width: 16, height: 16 }} />
            </Typography>
            {')'}
          </Typography>
        );
    }
  };

  return (
    <Button
      onClick={props.onClick}
      variant="secondary"
      className={` ${props.fullWidth ? 'fullWidth' : ''} d-flex justify-content-center align-items-center gap-2`}
      disabled={props.disabled}
    >
      Stwórz bajkę {getCostLabel()}
    </Button>
  );
};
