import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { APP_VERSION } from 'serviceWorkerRegistration';
import { pxToRem } from 'shared/utils/commonUtils';
import { cGrey200 } from 'shared/utils/styleCommon';

export const Settings = () => (
  <RouteLayout backRoute={-1}>
    <div className="mx-3 pt-5 d-flex h-100 flex-column justify-content-between">
      <div>
        <Typography variant="h1" classNames="pb-4">
          Ustawienia
        </Typography>
        <div
          className="d-flex align-items-center gap-2"
          role="button"
          onClick={() => router.navigate(paths.profile.settings.changePassword)}
        >
          <i style={{ fontSize: pxToRem(18) }} className="bi bi-chevron-right"></i>
          <Typography variant="description">Zmień hasło</Typography>
        </div>
        <div className="w-100 my-3" style={{ borderBottom: `1px solid ${cGrey200}` }}></div>
        <div className="d-flex align-items-center gap-2" role="button" onClick={() => router.navigate(paths.profile.settings.changeEmail)}>
          <i style={{ fontSize: pxToRem(18) }} className="bi bi-chevron-right"></i>
          <Typography variant="description">Zmień email</Typography>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end">
        <Typography variant="description-xxs">v{APP_VERSION}</Typography>
      </div>
    </div>
  </RouteLayout>
);
