export const NoResultIcon = () => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5" clipPath="url(#clip0_1603_24682)">
        <path
          d="M61.9889 23.4407C61.3027 0.244193 27.9045 -8.29065 16.0471 11.6536C11.3757 19.1879 10.3536 28.2705 14.5983 36.2434C15.6176 38.24 16.9167 40.0852 18.4298 41.7395C17.0916 42.8326 15.7746 43.952 14.4697 45.0864C11.2566 47.9008 8.11911 50.8038 5.06147 53.7894C3.50043 55.3169 1.95687 56.8643 0.448009 58.445C-1.09927 60.3139 1.7334 62.6941 3.30436 60.8294C7.36901 55.9764 11.6001 51.2608 16.0297 46.7348C17.2382 45.4891 18.4622 44.2576 19.6884 43.0257C26.3978 49.4277 36.4757 52.3719 45.3434 48.9909C47.1015 48.1155 51.532 47.1187 50.0799 44.4097C49.5274 43.5415 48.374 43.2874 47.5079 43.8428C46.4137 44.5392 45.2591 45.1325 44.0586 45.6094C32.757 50.1747 19.2102 42.5297 15.7005 31.2013C13.7599 25.46 14.904 19.1248 17.8168 13.917C21.2682 7.35594 28.2589 3.06584 35.6676 2.86989C52.5649 2.47216 64.5815 17.1226 57.6786 33.3459C57.4074 33.8858 57.147 34.6907 56.8462 35.2079C56.3014 36.1424 55.7652 37.401 57.0416 38.0121C58.2944 38.5441 58.922 37.3018 59.2669 36.3138C59.6935 35.3539 60.1224 34.2369 60.4517 33.2379C61.512 30.1014 62.0881 26.7557 61.9889 23.4407Z"
          fill="#EC8D29"
        />
        <path
          d="M46.0593 32.0729C43.6772 29.662 41.2065 27.2592 38.6992 24.968C39.731 23.801 40.8218 22.6856 41.9182 21.5778C42.3426 21.1605 42.9004 20.5614 43.3252 20.1438C43.9781 19.1388 46.3195 18.0837 45.242 16.7373C44.7575 16.2359 43.9941 16.3791 43.5852 16.9002C42.3518 18.1048 41.0869 19.263 39.7858 20.3958C38.9318 21.1501 37.6558 22.2165 36.5992 23.0917C34.2835 21.0683 31.9157 19.1081 29.7252 16.9522C28.2207 16.0219 26.7809 18.4061 28.3195 19.2984C29.8443 20.2368 31.2285 21.3816 32.5462 22.589C33.275 23.2675 33.9957 23.9617 34.7078 24.6672C32.343 26.6872 30.0885 28.9219 28.6475 31.6938C28.2007 32.5439 29.1548 33.5866 30.0493 33.1723C32.5648 31.9605 34.6633 29.9891 36.4038 27.8337C36.5986 27.5594 36.7984 27.2892 37.0022 27.0225C38.0081 28.0928 38.9881 29.1854 39.9302 30.2979C41.1575 31.7133 42.2705 33.2187 43.4108 34.6986C45.1291 36.4186 47.7725 33.8165 46.0593 32.0729Z"
          fill="#EC8D29"
        />
      </g>
      <defs>
        <clipPath id="clip0_1603_24682">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
