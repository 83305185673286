import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { rgba } from 'shared/utils/commonUtils';
import { cPrimary } from 'shared/utils/styleCommon';

type Props = {
  count: number;
};

export const ProgressLoader = (props: Props) => {
  return (
    <div style={{ width: 150, height: 150 }}>
      <CircularProgressbar
        value={props.count}
        maxValue={300}
        text={`${props.count}s`}
        styles={{
          path: {
            stroke: cPrimary
          },
          trail: {
            stroke: rgba(cPrimary, 0.3)
          },
          text: {
            fill: cPrimary,
            fontSize: '28px',
            fontWeight: '700'
          }
        }}
      />
    </div>
  );
};
