export const TermsIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" fill="#FFEBD6" />
      <path
        d="M19 22.3332H9C8.33696 22.3332 7.70107 22.0698 7.23223 21.6009C6.76339 21.1321 6.5 20.4962 6.5 19.8332V8.1665C6.5 7.50346 6.76339 6.86758 7.23223 6.39874C7.70107 5.9299 8.33696 5.6665 9 5.6665H19C19.663 5.6665 20.2989 5.9299 20.7678 6.39874C21.2366 6.86758 21.5 7.50346 21.5 8.1665V19.8332C21.5 20.4962 21.2366 21.1321 20.7678 21.6009C20.2989 22.0698 19.663 22.3332 19 22.3332ZM9 7.33317C8.77899 7.33317 8.56702 7.42097 8.41074 7.57725C8.25446 7.73353 8.16667 7.94549 8.16667 8.1665V19.8332C8.16667 20.0542 8.25446 20.2661 8.41074 20.4224C8.56702 20.5787 8.77899 20.6665 9 20.6665H19C19.221 20.6665 19.433 20.5787 19.5893 20.4224C19.7455 20.2661 19.8333 20.0542 19.8333 19.8332V8.1665C19.8333 7.94549 19.7455 7.73353 19.5893 7.57725C19.433 7.42097 19.221 7.33317 19 7.33317H9Z"
        fill="#EC8D29"
      />
      <path
        d="M13.9997 10.2497H10.6663C10.4453 10.2497 10.2334 10.1619 10.0771 10.0056C9.92081 9.84932 9.83301 9.63735 9.83301 9.41634C9.83301 9.19533 9.92081 8.98337 10.0771 8.82709C10.2334 8.67081 10.4453 8.58301 10.6663 8.58301H13.9997C14.2207 8.58301 14.4326 8.67081 14.5889 8.82709C14.7452 8.98337 14.833 9.19533 14.833 9.41634C14.833 9.63735 14.7452 9.84932 14.5889 10.0056C14.4326 10.1619 14.2207 10.2497 13.9997 10.2497Z"
        fill="#EC8D29"
      />
      <path
        d="M17.333 13.3083H10.6663C10.4453 13.3083 10.2334 13.2205 10.0771 13.0642C9.92081 12.9079 9.83301 12.6959 9.83301 12.4749C9.83301 12.2539 9.92081 12.042 10.0771 11.8857C10.2334 11.7294 10.4453 11.6416 10.6663 11.6416H17.333C17.554 11.6416 17.766 11.7294 17.9223 11.8857C18.0785 12.042 18.1663 12.2539 18.1663 12.4749C18.1663 12.6959 18.0785 12.9079 17.9223 13.0642C17.766 13.2205 17.554 13.3083 17.333 13.3083Z"
        fill="#EC8D29"
      />
      <path
        d="M17.333 16.3581H10.6663C10.4453 16.3581 10.2334 16.2703 10.0771 16.114C9.92081 15.9577 9.83301 15.7458 9.83301 15.5247C9.83301 15.3037 9.92081 15.0918 10.0771 14.9355C10.2334 14.7792 10.4453 14.6914 10.6663 14.6914H17.333C17.554 14.6914 17.766 14.7792 17.9223 14.9355C18.0785 15.0918 18.1663 15.3037 18.1663 15.5247C18.1663 15.7458 18.0785 15.9577 17.9223 16.114C17.766 16.2703 17.554 16.3581 17.333 16.3581Z"
        fill="#EC8D29"
      />
      <path
        d="M17.333 19.4167H10.6663C10.4453 19.4167 10.2334 19.3289 10.0771 19.1726C9.92081 19.0163 9.83301 18.8043 9.83301 18.5833C9.83301 18.3623 9.92081 18.1504 10.0771 17.9941C10.2334 17.8378 10.4453 17.75 10.6663 17.75H17.333C17.554 17.75 17.766 17.8378 17.9223 17.9941C18.0785 18.1504 18.1663 18.3623 18.1663 18.5833C18.1663 18.8043 18.0785 19.0163 17.9223 19.1726C17.766 19.3289 17.554 19.4167 17.333 19.4167Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
