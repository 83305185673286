export const validationMessage = {
  required: 'To pole jest obowiązkowe',
  wrongFormat: 'Niepoprawny format',
  wrongMailFormat: 'Niepoprawny adres email',
  noDuplicates: 'Wartość nie może się powtarzać'
};

export const validatePassword = (value: string) => {
  if (!value || value.length < 8) return 'Hasło nie spełnia wymagań';
  return (/[a-z]/.test(value) && /[A-Z]/.test(value) && /\d/.test(value) && /[^a-zA-Z\d]/.test(value)) || 'Hasło nie spełnia wymagań';
};

export const validatePhoneNumber = (value: string) =>
  !value || value.replaceAll(' ', '').length === 9 || 'Numer telefonu ma niepoprawną długość';

export const validateEmail = (value: string) => {
  if (!value) return true;
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value) || validationMessage.wrongMailFormat;
};
