import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Typography } from '../typography/typography';

interface Props {
  title: string;
  content: string;
  onAcceptText: string;
  onCancelText: string;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
}

export const ConfirmationDialog = ({ title, content, onAccept, onAcceptText, onCancelText, onClose, open }: Props) => (
  <Modal centered show={open} onHide={onClose}>
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body className="p-4">
      <Typography variant="h1" classNames="pb-3">
        {title}
      </Typography>
      <Typography classNames="pb-5" variant="description">
        {content}
      </Typography>
      <div className="d-flex flex-column align-items-center justify-content-center gap-3">
        <Button className="fullWidth" variant="primary" onClick={onAccept}>
          {onAcceptText}
        </Button>
        <Button className="fullWidth" variant="outline-primary" onClick={onClose}>
          {onCancelText}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);
