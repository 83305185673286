import { cSecondary } from 'shared/utils/styleCommon';
import styled from 'styled-components';

const rangeGrey = '#ccd2da';

export const RangeInput = styled.input`
  --range-progress: 0;
  -webkit-appearance: none;
  position: relative;
  background: ${rangeGrey};
  width: 100%;
  height: 4px;
  border-radius: 4px;
  cursor: pointer;

  /* Input range - firefox */
  &::-moz-range-track {
    position: relative;
    background: ${rangeGrey};
    width: 100%;
    height: 2px;
    border-radius: 4px;
    cursor: pointer;
  }

  /* played progress length - Chrome & safari*/
  &::before {
    content: '';
    height: 4px;
    background: ${cSecondary};
    width: var(--range-progress);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
  }

  /* played progress length - firefox */
  &::-moz-range-progress {
    background: ${rangeGrey};
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    height: 4px;
  }

  /* slider thumb - chrome and safari */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: none;
    background-color: ${cSecondary};
    cursor: pointer;
    position: relative;
  }

  /* dragging thumb - chrome and safari */
  &:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }

  /* slider thumb - firefox */
  &::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${cSecondary};
    cursor: pointer;
    border: transparent;
    position: relative;
  }
  /* dragging thumb - firefox */
  &:active::-moz-range-thumb {
    transform: scale(1.2);
  }
`;
