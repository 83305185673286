import { PredefinedTag } from 'shared/types/predefinedTag';
import { HTTP_METHODS, apiSlice } from '../api';

interface TagStoriesExistence {
  [key: string]: boolean;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedTags: builder.query<PredefinedTag[], void>({
      query: () => ({
        url: `/public/predefined-tags`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result.map(({ id }) => ({ type: 'PredefinedTag' as const, id })), { type: 'PredefinedTag', id: 'LIST' }]
          : [{ type: 'PredefinedTag', id: 'LIST' }]
    }),
    getTagStoriesExistence: builder.query<TagStoriesExistence, void>({
      query: () => ({
        url: `/public/predefined-tags/story-existence`,
        method: HTTP_METHODS.GET
      })
    })
  }),

  overrideExisting: false
});

export const { useGetPredefinedTagsQuery, useGetTagStoriesExistenceQuery } = extendedApi;
