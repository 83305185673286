import { pxToRem } from './commonUtils';

export const cPrimary = '#ec8d29';
export const cPrimaryLight = '#ef9e49';
export const cLightPrimary = '#FAEAD9';
export const cSecondary = '#112b52';
export const cSecondaryLight = '#29416c';
export const cSecondaryLighter = '#344c79';
export const cError = 'red';
export const cSuccess = 'green';

export const cBeige = '#EEECE3';
export const cWhite = '#fff';
export const cRed = '#ED2E38';

export const cGrey100 = '#FDFDFD';
export const cGrey200 = '#E6E6E6';
export const cGrey600 = '#6B6161';

export const cBlue100 = '#101a33';

export const borderMd = pxToRem(10);
