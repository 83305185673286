import { useCallback, useState } from 'react';
import Slider from 'react-slick';
import { useWindowDimensions } from 'shared/hooks/useWindowDimentions';
import { rgba } from 'shared/utils/commonUtils';
import { cPrimary } from 'shared/utils/styleCommon';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

type Props<T> = {
  items: T[];
  renderItem: (item: T) => JSX.Element;
};

const CustomArrow = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${rgba('#000', 0.0)} !important;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    background-color: ${rgba('#000', 0.05)} !important;
  }
`;

export const Carousel = <T extends any>(props: Props<T>) => {
  const [dragging, setDragging] = useState(false);
  // other
  const shouldShowArrows = useWindowDimensions().width <= 768;

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e: any) => {
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <div className="slider-container">
      <div style={{ display: 'block', width: '100%' }}>
        <Slider
          className="slider variable-width"
          infinite={false}
          arrows={!shouldShowArrows}
          variableWidth
          speed={500}
          slidesToShow={1}
          swipeToSlide
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
          nextArrow={
            <div style={{ position: 'absolute' }}>
              <CustomArrow>
                <i style={{ fontSize: '30px', color: cPrimary, position: 'static' }} className="bi bi-chevron-compact-right" />
              </CustomArrow>
            </div>
          }
          prevArrow={
            <div style={{ position: 'absolute' }}>
              <CustomArrow>
                <i style={{ fontSize: '30px', color: cPrimary, position: 'static' }} className="bi bi-chevron-compact-left" />
              </CustomArrow>
            </div>
          }
        >
          {props.items.map((item, idx) => (
            <div onClickCapture={handleOnItemClick} key={idx}>
              {props.renderItem(item)}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
