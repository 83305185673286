import { skipToken } from '@reduxjs/toolkit/query';
import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { AvatarPreview } from 'features/user-profile/avatarPreview';
import { AVATAR_SIZE } from 'features/user-profile/utils';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AddIcon } from 'shared/icons/addIcon';
import { PersonOutlineIcon } from 'shared/icons/personOutlineIcon';
import { pxToRem, rgba } from 'shared/utils/commonUtils';
import { cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetTemplateByIdQuery } from 'store/api/endpoints/templateEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMainHero, setSideHero, setTempMainHero, setTempSideHero } from 'store/slices/storyCreatorSlice';

type Props = {
  prevRoute: string;
};

export const CharacterPicker = (props: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMainInitialized, setIsMainInitialized] = useState(false);
  const [isSideInitialized, setIsSideInitialized] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const mainHero = useAppSelector(state => state.storyCreatorSlice.mainHero);
  const sideHero = useAppSelector(state => state.storyCreatorSlice.sideHero);
  const tempMainHero = useAppSelector(state => state.storyCreatorSlice.tempMainHero);
  const tempSideHero = useAppSelector(state => state.storyCreatorSlice.tempSideHero);
  const templateId = useAppSelector(state => state.storyCreatorSlice.templateId);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  // other
  const navigate = useNavigate();

  const templateMode = props.prevRoute === paths.storyCreator.template.base;
  const { data: template } = useGetTemplateByIdQuery(templateId ?? skipToken);

  const mainHeroParamExists = template?.templateParams.some(tp => tp.key === 'MAIN_HERO1');
  const sideHeroParamExists = template?.templateParams.some(tp => tp.key === 'SIDE_HERO');

  useEffect(() => {
    if (!tempMainHero && mainHero && !isMainInitialized) {
      dispatch(setTempMainHero(mainHero));
      setIsMainInitialized(true);
    }
  }, [dispatch, mainHero, tempMainHero, isMainInitialized]);

  useEffect(() => {
    if (!tempSideHero && sideHero && !isSideInitialized) {
      dispatch(setTempSideHero(sideHero));
      setIsSideInitialized(true);
    }
  }, [dispatch, sideHero, tempSideHero, isSideInitialized]);

  const pathPostfix = templateMode ? 'template' : 'custom';

  const createChildProfilePath = paths.storyCreator[pathPostfix].characters.createChild;
  const editChildProfilePath = paths.storyCreator[pathPostfix].characters.editChild;

  const createHeroAsMainCharacterPath = paths.storyCreator[pathPostfix].characters.createHeroAsMain;
  const editHeroAsMainCharacterPath = paths.storyCreator[pathPostfix].characters.editHeroAsMain;

  const createHeroAsSideCharacterPath = paths.storyCreator[pathPostfix].characters.createHeroAsSide;
  const editHeroAsSideCharacterPath = paths.storyCreator[pathPostfix].characters.editHeroAsSide;

  const totalCost = getTotalCost(pathPostfix, narrator);

  return (
    <RouteLayout
      backRoute={props.prevRoute}
      backRouteAdditionalAction={() => {
        dispatch(setTempMainHero(undefined));
        dispatch(setTempSideHero(undefined));
      }}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setMainHero(tempMainHero));
            dispatch(setSideHero(tempSideHero));
            dispatch(setTempMainHero(undefined));
            dispatch(setTempSideHero(undefined));
            navigate(props.prevRoute);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />,
          action: () => {}
        }
      ]}
    >
      <Modal centered show={isDialogOpen} onHide={() => setIsDialogOpen(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-4">
          <Typography variant="h1" classNames="pb-3">
            Dodaj profil
          </Typography>
          <Typography classNames="pb-5" variant="description">
            Wybierz czy chcesz stworzyć nowy profil Bohatera czy Dziecka.
          </Typography>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3">
            <Button className="fullWidth" variant="primary" onClick={() => navigate(createHeroAsMainCharacterPath)}>
              Bohatera
            </Button>
            <Button className="fullWidth" variant="outline-primary" onClick={() => navigate(createChildProfilePath)}>
              Dziecka
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="mx-3">
        {(!templateMode || mainHeroParamExists) && (
          <div className="pt-4">
            <div className="d-flex justify-content-between">
              <Typography variant="h1">Wybierz bohatera głównego</Typography>
              <Button variant="link" onClick={() => setIsEditMode(prev => !prev)}>
                {isEditMode ? 'Zakończ' : 'Edytuj'}
              </Button>
            </div>

            <div className="d-flex align-items-start gap-3 pt-3 flex-wrap">
              {loggedUser?.childProfiles.map(p => (
                <div key={p.id}>
                  <AvatarPreview
                    editable={isEditMode}
                    isSelected={tempMainHero?.character?.id === p.id}
                    name={p.name}
                    filename={p.image}
                    onClickCallback={() => {
                      if (isEditMode) {
                        dispatch(setTempMainHero({ random: false, character: { id: p.id, childProfile: true } }));
                        navigate(editChildProfilePath + `/${p.id}`);
                      } else if (tempMainHero?.character?.id === p.id) {
                        dispatch(setTempMainHero(undefined));
                      } else {
                        dispatch(setTempMainHero({ random: false, character: { id: p.id, childProfile: true } }));
                      }
                    }}
                  />
                </div>
              ))}
              {loggedUser?.heroes.map(h => (
                <div key={h.id}>
                  <AvatarPreview
                    editable={isEditMode}
                    isSelected={tempMainHero?.character?.id === h.id}
                    name={h.name}
                    filename={h.image}
                    onClickCallback={() => {
                      if (isEditMode) {
                        dispatch(setTempMainHero({ random: false, character: { id: h.id, childProfile: false } }));
                        navigate(editHeroAsMainCharacterPath + `/${h.id}`);
                      } else if (tempMainHero?.character?.id === h.id) {
                        dispatch(setTempMainHero(undefined));
                      } else {
                        dispatch(setTempMainHero({ random: false, character: { id: h.id, childProfile: false } }));
                      }
                    }}
                  />
                </div>
              ))}
              <div
                style={{ maxWidth: pxToRem(AVATAR_SIZE) }}
                role="button"
                className="d-flex flex-column justify-content-start"
                onClick={() => setIsDialogOpen(true)}
              >
                <AddIcon />
                <Typography variant="description" classNames="text-center pt-1">
                  Dodaj
                </Typography>
              </div>
              {!templateMode && (
                <div
                  style={{ maxWidth: pxToRem(AVATAR_SIZE) }}
                  role="button"
                  className="d-flex flex-column justify-content-start"
                  onClick={() => {
                    if (tempMainHero?.random === true) {
                      dispatch(setTempMainHero(undefined));
                    } else {
                      dispatch(setTempMainHero({ random: true }));
                    }
                  }}
                >
                  <div
                    style={{
                      outline: tempMainHero?.random ? `2px solid ${cPrimary}` : `2px solid ${rgba(cPrimary, 0.2)}`,
                      borderRadius: '50%',
                      width: '78px',
                      height: '78px'
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <PersonOutlineIcon />
                  </div>
                  <Typography
                    variant="description"
                    classNames="text-center pt-1"
                    styles={{ color: tempMainHero?.random ? cPrimary : cSecondary }}
                  >
                    Losowy
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}

        {(!templateMode || sideHeroParamExists) && (
          <div className="pt-5">
            <Typography variant="h1">Wybierz bohatera pobocznego</Typography>
            <div className="d-flex align-items-start gap-3 pt-3 flex-wrap">
              {loggedUser?.heroes.map(h => (
                <div key={h.id}>
                  <AvatarPreview
                    editable={isEditMode}
                    isSelected={tempSideHero?.character?.id === h.id}
                    name={h.name}
                    filename={h.image}
                    onClickCallback={() => {
                      if (isEditMode) {
                        dispatch(setTempSideHero({ random: false, character: { id: h.id, childProfile: false } }));
                        navigate(editHeroAsSideCharacterPath + `/${h.id}`);
                      } else if (tempSideHero?.character?.id === h.id) {
                        dispatch(setTempSideHero(undefined));
                      } else {
                        dispatch(setTempSideHero({ random: false, character: { id: h.id, childProfile: false } }));
                      }
                    }}
                  />
                </div>
              ))}
              <div
                style={{ maxWidth: pxToRem(AVATAR_SIZE) }}
                role="button"
                className="d-flex flex-column justify-content-start align-items-start "
                onClick={() => router.navigate(createHeroAsSideCharacterPath)}
              >
                <AddIcon />
                <Typography variant="description" classNames="text-center pt-1">
                  Dodaj bohatera
                </Typography>
              </div>
              {!templateMode && (
                <div
                  style={{ maxWidth: pxToRem(AVATAR_SIZE) }}
                  role="button"
                  className="d-flex flex-column justify-content-start"
                  onClick={() => {
                    if (tempSideHero?.random === true) {
                      dispatch(setTempSideHero(undefined));
                    } else {
                      dispatch(setTempSideHero({ random: true }));
                    }
                  }}
                >
                  <div
                    style={{
                      outline: tempSideHero?.random ? `2px solid ${cPrimary}` : `2px solid ${rgba(cPrimary, 0.2)}`,
                      borderRadius: '50%',
                      width: '78px',
                      height: '78px'
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <PersonOutlineIcon />
                  </div>
                  <Typography
                    variant="description"
                    classNames="text-center pt-1"
                    styles={{ color: tempSideHero?.random ? cPrimary : cSecondary }}
                  >
                    Losowy
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </RouteLayout>
  );
};
