import { Typography } from 'features/ui/typography/typography';
import React from 'react';
import './storyprintout.scss';
import storytailo from '../../../shared/images/storytailo.svg';
import printSpiral from '../../../shared/images/print-spiral.svg';

type Props = {
  title: string;
  content: TrustedHTML;
  childName?: string;
  email?: string;
};

export const StoryPrintout = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const getStoryGeneratedLabel = () => {
    return `Bajka stworzona dzięki Storytailo${props.childName ? ` dla: ${props.childName}` : ''}${props.email ? ` (${props.email})` : ''}`;
  };

  return (
    <div ref={ref} className="print-content">
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-spacer"></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="content" style={{ position: 'relative' }}>
                <img src={printSpiral} width={313} height={150} alt="" style={{ position: 'absolute', top: -24, right: 0 }} />
                <div>
                  <Typography variant="h1" styles={{ fontSize: '21px', lineHeight: '26px', color: '#112B52' }}>
                    {props.title}
                  </Typography>
                </div>
                <div>
                  <Typography variant="description" styles={{ marginTop: '16px', fontSize: '9pt', lineHeight: '11pt', color: '#939393' }}>
                    {getStoryGeneratedLabel()}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="description"
                    styles={{ marginTop: '32px', fontFamily: '"PT Serif", serif', fontSize: '12pt', lineHeight: '17pt', color: '#000000' }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
                  </Typography>
                  <div
                    className="d-flex w-100 justify-content-center align-items-center"
                    style={{ paddingTop: '64px', position: 'relative' }}
                  >
                    <img src={printSpiral} width={313} height={150} alt="" style={{ position: 'absolute', top: -16 }} />
                    <Typography
                      variant="description"
                      styles={{
                        fontFamily: '"PT Serif", serif',
                        fontSize: '12pt',
                        lineHeight: '17pt',
                        color: '#000000'
                      }}
                    >
                      Koniec.
                    </Typography>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-spacer"></div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="header">
        <img src={storytailo} width={104} height={22} alt="" style={{ marginBottom: '16px' }} />
      </div>
      <div className="footer">
        <Typography variant="description" styles={{ marginTop: '16px', fontSize: '9pt', lineHeight: '11pt', color: '#939393' }}>
          Magia, która dzieje się w świecie Twojego dziecka. | Stwórz swoją bajkę na storytailo.com
        </Typography>
      </div>
    </div>
  );
});
