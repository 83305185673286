import { Divider } from 'features/ui/divider/divider';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useOnScreen } from './useOnScreen';

interface IUnique {
  id?: string;
  label?: string;
}

interface IInfiniteScrollProps<T extends IUnique> {
  list: T[];
  hasMore: boolean;
  isLoading: boolean;
  divider?: boolean;
  loadMore: () => void;
  renderListItem?: (element: T) => JSX.Element;
  renderLoadingRowItem?: () => JSX.Element;
  renderEmptyListMessage?: () => JSX.Element;
}

export const InfiniteScroll = <T extends IUnique>({
  list,
  hasMore,
  isLoading,
  divider,
  loadMore,
  renderListItem,
  renderLoadingRowItem,
  renderEmptyListMessage
}: IInfiniteScrollProps<T>) => {
  const { elementRef, isIntersecting, observer } = useOnScreen({ root: null, rootMargin: '0px', threshold: 0.5 });

  useEffect(() => {
    if (observer && isIntersecting && hasMore) {
      loadMore();
      observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);

  const itemRenderer = useRef<(element: T) => JSX.Element>();
  itemRenderer.current = renderListItem !== undefined ? renderListItem : (e: T) => <div key={e.id}>{e.label}</div>;

  const loadingRow = useRef<() => JSX.Element>();
  loadingRow.current =
    renderLoadingRowItem !== undefined ? renderLoadingRowItem : () => <div style={{ fontWeight: 'bold', margin: '16px' }}>Loading...</div>;

  const emptyListMessage = useRef<() => JSX.Element>();
  emptyListMessage.current =
    renderEmptyListMessage !== undefined ? renderEmptyListMessage : () => <div style={{ fontWeight: 'bold' }}>List is empty</div>;

  return (
    <>
      {_.isEmpty(list) && !isLoading ? (
        emptyListMessage.current()
      ) : (
        <>
          {list.map((element, index) => {
            if (index === list.length - 1 || !divider) {
              return <div ref={elementRef}>{itemRenderer.current?.(element)}</div>;
            }
            return (
              <div>
                {itemRenderer.current?.(element)}
                <Divider spacing="my-4" />
              </div>
            );
          })}
          {hasMore && loadingRow.current()}
        </>
      )}
    </>
  );
};
