export const EditIcon = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="26" height="26" rx="13" fill="#FFEFDF" />
      <path
        opacity="0.4"
        d="M15.3297 10.3511L16.1474 11.1689L8.0952 19.2222H7.27753V18.4044L15.3297 10.3511ZM18.5293 5C18.3071 5 18.076 5.08889 17.9072 5.25778L16.2807 6.88444L19.6136 10.2178L21.24 8.59111C21.5867 8.24444 21.5867 7.68444 21.24 7.33778L19.1603 5.25778C18.9826 5.08 18.7604 5 18.5293 5ZM15.3297 7.83556L5.5 17.6667V21H8.83287L18.6626 11.1689L15.3297 7.83556Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
