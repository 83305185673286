export const HelpIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FFEBD6" />
      <path
        d="M13 20H15V18H13V20ZM14 4C8.48 4 4 8.48 4 14C4 19.52 8.48 24 14 24C19.52 24 24 19.52 24 14C24 8.48 19.52 4 14 4ZM14 22C9.59 22 6 18.41 6 14C6 9.59 9.59 6 14 6C18.41 6 22 9.59 22 14C22 18.41 18.41 22 14 22ZM14 8C11.79 8 10 9.79 10 12H12C12 10.9 12.9 10 14 10C15.1 10 16 10.9 16 12C16 14 13 13.75 13 17H15C15 14.75 18 14.5 18 12C18 9.79 16.21 8 14 8Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
