export const ChildrenProfilesIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" width="48" height="48" rx="24" fill="#FDFDFD" />
      <rect x="1.75" y="1" width="46" height="46" rx="23" stroke="#EC8D29" stroke-opacity="0.2" stroke-width="2" />
      <path d="M29.42 25.13C30.79 26.06 31.75 27.32 31.75 29V32H35.75V29C35.75 26.82 32.18 25.53 29.42 25.13Z" fill="#EC8D29" />
      <path
        d="M27.75 24C29.96 24 31.75 22.21 31.75 20C31.75 17.79 29.96 16 27.75 16C27.28 16 26.84 16.1 26.42 16.24C27.25 17.27 27.75 18.58 27.75 20C27.75 21.42 27.25 22.73 26.42 23.76C26.84 23.9 27.28 24 27.75 24Z"
        fill="#EC8D29"
      />
      <path
        d="M21.75 24C23.96 24 25.75 22.21 25.75 20C25.75 17.79 23.96 16 21.75 16C19.54 16 17.75 17.79 17.75 20C17.75 22.21 19.54 24 21.75 24ZM21.75 18C22.85 18 23.75 18.9 23.75 20C23.75 21.1 22.85 22 21.75 22C20.65 22 19.75 21.1 19.75 20C19.75 18.9 20.65 18 21.75 18Z"
        fill="#EC8D29"
      />
      <path
        d="M21.75 25C19.08 25 13.75 26.34 13.75 29V32H29.75V29C29.75 26.34 24.42 25 21.75 25ZM27.75 30H15.75V29.01C15.95 28.29 19.05 27 21.75 27C24.45 27 27.55 28.29 27.75 29V30Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
