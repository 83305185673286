import { UserStoryInfo } from 'shared/types/users';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    updateUserStoryInfo: builder.mutation<void, { userId: string; userStoryInfo: UserStoryInfo }>({
      query: data => ({
        url: `/users/${data.userId}/user-story-info`,
        method: HTTP_METHODS.PUT,
        body: data.userStoryInfo
      })
    })
  }),
  overrideExisting: false
});

export const { useUpdateUserStoryInfoMutation } = extendedApi;
