import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { TileProps } from './types.elementTiles';

export const NarratorTile = (props: TileProps) => {
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    router.navigate(props.customCreator ? paths.storyCreator.custom.narrator : paths.storyCreator.template.narrator);
  };

  return (
    <ElementTile
      cost={storyCreatorSlice.narrator?.cost ?? 0}
      tileId={props.tileId}
      action={onAction}
      filename={storyCreatorSlice.narrator?.image}
      directory={'common'}
    >
      <>
        <Typography variant="h4">Lektor</Typography>
        <Typography variant="description">{storyCreatorSlice.narrator?.value ?? 'Niezdefiniowany'}</Typography>
      </>
    </ElementTile>
  );
};
