import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { useRequestResetPasswordLinkMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

export const ResetPassword = (): JSX.Element => {
  // redux
  const dispatch = useDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [reset, { isError, isSuccess, isLoading }] = useRequestResetPasswordLinkMutation();
  // other
  const navigate = useNavigate();

  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    isSuccess && navigate(paths.resetPasswordSuccess, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigate]);

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'danger', text: 'Nie udało się wysłać maila aby zresetować hasło' }));
  }, [isError, dispatch]);

  useEffect(() => {
    loggedUser && navigate(paths.signIn.email, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: { email: string }) => {
    reset(data.email);
  };

  return (
    <RouteLayout>
      <Loadable loading={isLoading}>
        <div className="d-flex flex-column h-100 justify-content-between py-5 mx-3">
          <div>
            <Typography variant="h1">Nie pamiętasz hasła?</Typography>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="resetPasswordForm.email">
                <Form.Label>E-mail</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required,
                    validate: validateEmail
                  }}
                />
              </Form.Group>
              <Typography variant="description">
                Podaj adres email podany podczas rejestracji, a my wyślemy Ci link do zmiany hasła.
              </Typography>
              <JustifyCenter classNames="pt-5 pb-2">
                <Button className={'long'} type="submit" disabled={!isValid}>
                  Wyślij
                </Button>
              </JustifyCenter>
            </Form>
          </div>
          <JustifyCenter>
            <Button className={'long'} variant="outline-primary" onClick={() => navigate(paths.signIn.email)}>
              Zaloguj się
            </Button>
          </JustifyCenter>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
