import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { Search } from '../ui/search-story/search';
import { Categories } from './category/categories';

export const SearchStory = () => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isUserRefetch, setIsUserRefetch] = useState(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery(undefined, {
    skip: !loggedUser
  });

  useEffect(() => {
    if (!isUserRefetch && loggedUser) {
      refetchUser();
      setIsUserRefetch(true);
    }
  }, [refetchUser, isUserRefetch, loggedUser]);

  return (
    <RouteLayout>
      <div className="my-5 mx-3">
        <Typography variant="h1" classNames="pb-3">
          Wyszukaj
        </Typography>
        <Search onSearchFocusChange={setIsSearchFocused} onSearchTextChange={setSearchText} />
        {!isSearchFocused && !searchText && <Categories />}
      </div>
    </RouteLayout>
  );
};
