import { Typography } from 'features/ui/typography/typography';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setSelectedTileId, setTempMainHero, setTempSideHero } from 'store/slices/storyCreatorSlice';
import { ElementTile } from '../elementTile';
import { getHeroLabel } from '../utils';
import { TileProps } from './types.elementTiles';

export const HeroesTile = (props: TileProps) => {
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const storyCreatorSlice = useAppSelector(state => state.storyCreatorSlice);
  const dispatch = useAppDispatch();

  const onAction = () => {
    dispatch(setSelectedTileId(props.tileId));
    dispatch(setTempMainHero(undefined));
    dispatch(setTempSideHero(undefined));
    router.navigate(props.customCreator ? paths.storyCreator.custom.characters.base : paths.storyCreator.template.characters.base);
  };

  const getMainHeroImage = () => {
    const childProfileImage = loggedUser?.childProfiles.find(p => p.id === storyCreatorSlice.mainHero?.character?.id)?.image;
    const heroImage = loggedUser?.heroes.find(h => h.id === storyCreatorSlice.mainHero?.character?.id)?.image;
    return childProfileImage || heroImage;
  };

  const getSideHeroImage = () => {
    return loggedUser?.heroes.find(h => h.id === storyCreatorSlice.sideHero?.character?.id)?.image;
  };

  const mainHeroImage = getMainHeroImage();
  const sideHeroImage = getSideHeroImage();

  const getImageAndDir = () => {
    if (mainHeroImage) {
      return { image: mainHeroImage, dir: loggedUser?.id };
    }
    if (sideHeroImage) {
      return { image: sideHeroImage, dir: loggedUser?.id };
    }
    if (storyCreatorSlice.mainHero?.character || storyCreatorSlice.sideHero?.character) {
      return { image: 'characters.png', dir: 'common' };
    }
    return { image: 'random.png', dir: 'common' };
  };

  const { image, dir } = getImageAndDir();

  return (
    <ElementTile cost={0} tileId={props.tileId} action={onAction} filename={image} directory={dir}>
      <>
        <Typography variant="h4">Bohaterzy</Typography>
        <Typography variant="description">Główni: {getHeroLabel(loggedUser, storyCreatorSlice.mainHero)}</Typography>
        {props.customCreator && (
          <Typography variant="description">Poboczni: {getHeroLabel(loggedUser, storyCreatorSlice.sideHero)}</Typography>
        )}
      </>
    </ElementTile>
  );
};
