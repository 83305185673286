export const NavHomeIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3317 10.9189C19.712 10.5766 20.2893 10.5766 20.6696 10.9189L28.003 17.5189C28.2137 17.7085 28.334 17.9787 28.334 18.2622V29.8335C28.334 30.3858 27.8863 30.8335 27.334 30.8335H26.0007C25.4484 30.8335 25.0007 30.3858 25.0007 29.8335V21.8335C25.0007 21.2812 24.5529 20.8335 24.0007 20.8335H16.0007C15.4484 20.8335 15.0007 21.2812 15.0007 21.8335V29.8335C15.0007 30.3858 14.5529 30.8335 14.0007 30.8335H12.6673C12.115 30.8335 11.6673 30.3858 11.6673 29.8335V18.2622C11.6673 17.9787 11.7876 17.7085 11.9984 17.5189L19.3317 10.9189ZM20.6696 6.43556C20.2893 6.09329 19.712 6.09329 19.3317 6.43556L5.27098 19.0902C4.58976 19.7033 5.02346 20.8335 5.93994 20.8335H7.33398C7.88627 20.8335 8.33398 21.2812 8.33398 21.8335V33.1668C8.33398 33.7191 8.7817 34.1668 9.33398 34.1668H17.334C17.8863 34.1668 18.334 33.7191 18.334 33.1668V25.1668C18.334 24.6145 18.7817 24.1668 19.334 24.1668H20.6673C21.2196 24.1668 21.6673 24.6145 21.6673 25.1668V33.1668C21.6673 33.7191 22.115 34.1668 22.6673 34.1668H30.6673C31.2196 34.1668 31.6673 33.7191 31.6673 33.1668V21.8335C31.6673 21.2812 32.115 20.8335 32.6673 20.8335H34.0614C34.9778 20.8335 35.4115 19.7033 34.7303 19.0902L20.6696 6.43556Z"
        fill="currentColor"
      />
    </svg>
  );
};
