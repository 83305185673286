import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { TimerBackwardIcon } from 'shared/icons/timerBackwardIcon';
import { TimerForwardIcon } from 'shared/icons/timerForwardIcon';
import { pxToRem } from 'shared/utils/commonUtils';
import { cSecondary } from 'shared/utils/styleCommon';
import { useUpdateAccountMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppSelector } from 'store/hooks';
import { Typography } from '../typography/typography';
import { RangeInput } from './rangeInput';
import { CustomMenu, CustomToggle, formatTime } from './utils';

export interface Track {
  src: string;
}

type Props = {
  trackSrc: string;
  startingTime: number;
  duration: number;
  onTimeChangedCallback: (seconds: number) => void;
};

export const AudioPlayer = (props: Props) => {
  const [currentTime, setCurrentTime] = useState(props.startingTime);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const progressBarRef = useRef<HTMLInputElement | null>(null);
  const initialized = useRef(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState<'0.9' | '1.0' | '1.1'>('1.0');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [update] = useUpdateAccountMutation();

  const onSpeedChange = (speedParam: '0.9' | '1.0' | '1.1') => {
    if (loggedUser) {
      update({
        id: loggedUser.id,
        firstName: loggedUser.firstName,
        email: loggedUser.email,
        playbackRate: +speedParam,
        surveyShown: loggedUser.surveyShown
      });
    }
    setSpeed(speedParam);
    if (audioRef?.current) {
      audioRef.current.playbackRate = +speedParam;
    }
  };

  useEffect(() => {
    if (audioRef?.current && loggedUser?.playbackRate) {
      audioRef.current.playbackRate = loggedUser.playbackRate;
      setSpeed(() => {
        switch (loggedUser.playbackRate) {
          case 0.9:
            return '0.9';
          case 1.0:
            return '1.0';
          case 1.1:
            return '1.1';
          default:
            return '1.0';
        }
      });
    }
  }, [audioRef, loggedUser]);

  const togglePlayPause = () => {
    setIsPlaying(prev => !prev);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef?.current?.play();
    } else {
      audioRef?.current?.pause();
    }
  }, [isPlaying, audioRef]);

  const hackSafari = (operationOnCurrentTime: VoidFunction) => {
    isPlaying ? audioRef.current?.pause() : audioRef.current?.play();
    operationOnCurrentTime();
    isPlaying ? audioRef.current?.play() : audioRef.current?.pause();
  };

  const skipForward = () => {
    if (audioRef?.current?.currentTime && audioRef?.current?.currentTime < props.duration - 10) {
      hackSafari(() => (audioRef.current!.currentTime += 10));

      setCurrentTime(audioRef.current.currentTime);
      props.onTimeChangedCallback(audioRef.current.currentTime);
    }
  };

  const skipBackward = () => {
    if (audioRef?.current?.currentTime && audioRef?.current?.currentTime > 10) {
      hackSafari(() => (audioRef.current!.currentTime -= 10));

      setCurrentTime(audioRef.current.currentTime);
      props.onTimeChangedCallback(audioRef.current.currentTime);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef?.current && progressBarRef?.current) {
      const currentTime = audioRef.current.currentTime;
      setCurrentTime(currentTime);
      props.onTimeChangedCallback(currentTime);
      progressBarRef.current.value = audioRef.current.currentTime.toString();
      progressBarRef.current?.style.setProperty('--range-progress', `${(currentTime / props.duration) * 100}%`);
    }
  };

  const handleLoadedMetadata = () => {
    setTimeout(() => {
      // Delay to ensure the audio is ready (Safari hack)
      if (audioRef?.current && progressBarRef?.current) {
        progressBarRef.current.max = props.duration.toString();
        progressBarRef.current.value = props.startingTime.toString();
        progressBarRef.current?.style.setProperty('--range-progress', `${(props.startingTime / props.duration) * 100}%`);
      }
    }, 1);
  };

  const onCanPlay = () => {
    if (!initialized.current) {
      // Delay to ensure the audio is ready (Safari hack)
      setTimeout(() => {
        if (audioRef?.current && progressBarRef?.current) {
          audioRef.current.currentTime = props.startingTime;
        }
      }, 1);
      initialized.current = true;
    }
  };

  const onProgressBarClick = () => {
    if (audioRef?.current && progressBarRef?.current) {
      hackSafari(() => {
        audioRef.current!.currentTime = parseInt(progressBarRef.current!.value);
      });

      setCurrentTime(audioRef.current.currentTime);
      props.onTimeChangedCallback(audioRef.current.currentTime);
    }
  };

  if (!props.trackSrc || _.isNil(props.duration) || _.isNil(props.startingTime)) {
    return;
  }

  return (
    <div className="pb-4">
      <audio
        src={props.trackSrc}
        ref={audioRef}
        onLoadedMetadata={handleLoadedMetadata}
        onCanPlay={onCanPlay}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => setIsPlaying(false)}
      />

      <div className="d-flex flex-column justify-content-center align-items-center gap-2 px-3">
        <RangeInput type="range" ref={progressBarRef} defaultValue={props.startingTime} onChange={onProgressBarClick} />
        <div className="d-flex justify-content-between align-items-center gap-2 w-100">
          <Typography variant="description-sm" inline>
            {formatTime(currentTime ?? 0)}
          </Typography>
          <Typography variant="description-sm" inline>
            {formatTime(props.duration)}
          </Typography>
        </div>
      </div>

      <div style={{ marginTop: -20 }}>
        <Container>
          <Row className="d-flex align-items-center justify-content-end gap-3">
            <Col className="d-flex align-items-center justify-content-end">
              <Dropdown drop={'up-centered'} id="audio-speed-dropdown" style={{ zIndex: 100 }}>
                <Dropdown.Toggle as={CustomToggle} data-bs-display="static">
                  <Typography variant="description-sm-bold">{speed} x</Typography>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item eventKey="1" onClick={() => onSpeedChange('0.9')}>
                    <Typography variant="description-md">0.9</Typography>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={() => onSpeedChange('1.0')}>
                    <Typography variant="description-md">1.0</Typography>
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3" onClick={() => onSpeedChange('1.1')}>
                    <Typography variant="description-md">1.1</Typography>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="d-flex align-items-center justify-content-center gap-4" style={{ maxWidth: 'fit-content' }}>
              <div className="d-flex flex-column align-items-center gap-1" onClick={skipBackward} role="button">
                <TimerBackwardIcon />
              </div>

              <div onClick={togglePlayPause} role="button">
                {isPlaying ? (
                  <i style={{ fontSize: pxToRem(50), color: cSecondary, WebkitTextStroke: '1px' }} className="bi bi-pause-circle-fill"></i>
                ) : (
                  <i style={{ fontSize: pxToRem(50), color: cSecondary, WebkitTextStroke: '1px' }} className="bi bi-play-circle-fill"></i>
                )}
              </div>
              <div className="d-flex flex-column align-items-center gap-1" onClick={skipForward} role="button">
                <TimerForwardIcon />
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
