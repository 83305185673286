import { Transaction } from 'shared/types/transaction';
import { HTTP_METHODS, apiSlice } from '../api';

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTransactionById: builder.mutation<Transaction, { transactionId: string }>({
      query: data => ({
        url: `/transactions/${data.transactionId}`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetTransactionByIdMutation } = extendedApi;
