import { ThumbnailSize } from 'features/ui/image/customImage';
import { useEffect, useState } from 'react';
import { validateRequestAuthStatus } from 'shared/utils/commonUtils';
import { API_URL } from 'store/api/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ObjectUrl = string;

/**
 * @param filename - The filename.
 * @param directory - The directory of the file. It can be the common directory: "common" or an user directory: "<userId>"
 * @returns string containing a URL representing the fetched Blob.
 */
export const useFetchBlob = (
  filename?: string,
  directory?: string,
  thumbnail?: ThumbnailSize
): { blob: ObjectUrl | undefined; isError: boolean } => {
  const [blob, setBlob] = useState<string>();
  const [isError, setIsError] = useState(false);
  // redux
  const token = useAppSelector(state => state.authSlice.accessToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let objectUrl: string | undefined;
    if (filename && directory) {
      let thumbnailParam = thumbnail ? `?thumbnail=${thumbnail}` : '';
      fetch(`${API_URL}/folders/${directory}/files/${filename}${thumbnailParam}`, {
        method: 'GET',
        headers: {
          Authorization: token ? `Bearer ${token}` : ''
        }
      })
        .then(response => {
          if (response.ok) {
            response.blob().then(imageBlob => {
              objectUrl = URL.createObjectURL(imageBlob);
              setBlob(objectUrl);
            });
          } else {
            validateRequestAuthStatus(response.status);
            setIsError(true);
          }
        })
        .catch(() => setIsError(true));
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [filename, directory, token, dispatch, thumbnail]);

  return { blob, isError };
};
