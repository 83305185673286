import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { borderMd } from 'shared/utils/styleCommon';
import { Typography } from '../../ui/typography/typography';
import { pxToRem } from 'shared/utils/commonUtils';

type Props = {
  tag: PredefinedTag;
  onCategoryChosenCallback: (tag: PredefinedTag) => void;
};

export const Category = (props: Props) => (
  <div
    className="d-flex flex-column justify-content-start gap-2 pb-3"
    role="button"
    onClick={() => props.onCategoryChosenCallback(props.tag)}
  >
    <CustomImage
      directory="common"
      filename={props.tag.image}
      styles={{ borderRadius: borderMd }}
      width={pxToRem(160)}
      height={pxToRem(160)}
      thumbnail={ThumbnailSize.THUMBNAIL_360X360}
      skeleton
      noImagePlaceholder
    />
    <Typography variant="description">{props.tag.value}</Typography>
  </div>
);
