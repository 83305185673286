import { useCallback, useState } from 'react';

interface IUseOnScreenProps {
  root: HTMLElement | null;
  rootMargin?: string;
  threshold?: number;
}

export const useOnScreen = ({ root = null, rootMargin = '0px', threshold = 0 }: IUseOnScreenProps) => {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [isIntersecting, setIntersecting] = useState(false);

  const elementRef = useCallback(
    (ref: any) => {
      if (ref) {
        const io = new IntersectionObserver(
          ([entry]) => {
            setIntersecting(entry.isIntersecting);
          },
          { root, rootMargin, threshold }
        );

        io.observe(ref);
        setObserver(io);
      }
    },
    [root, rootMargin, threshold]
  );

  return { elementRef, isIntersecting, observer };
};
