import { cError } from 'shared/utils/styleCommon';
import { Typography } from '../typography/typography';

interface ResultMessageProps {
  error?: string;
}
export const FormError = (props: ResultMessageProps): JSX.Element => (
  <Typography variant="description-xs" styles={{ color: cError }}>
    {props.error}
  </Typography>
);
