import { Alert as BootstrapAlert } from 'react-bootstrap';
import { pxToRem } from 'shared/utils/commonUtils';
import { ALERT_Z_INDEX } from 'shared/utils/zindex';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Alert, dismissAlert } from 'store/slices/alertsSlice';

export const Alerts = (): JSX.Element => {
  const alerts = useAppSelector(state => state.alertsSlice.alerts);
  const dispatch = useAppDispatch();

  const generateAlert = (alert: Alert) => (
    <BootstrapAlert key={alert.id} variant={alert.color} onClose={() => dispatch(dismissAlert(alert.id))} dismissible>
      {alert.text}
    </BootstrapAlert>
  );

  return (
    <div style={{ position: 'absolute', top: pxToRem(16), width: '100%', zIndex: ALERT_Z_INDEX }}>
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <>{alerts.map(a => generateAlert(a))}</>
      </div>
    </div>
  );
};
