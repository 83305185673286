export const NoImageIcon = () => {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.74 29.4789L23.5771 8.79058L16.7582 20.6308L12.5241 13.2789L1.78646 29.549H22.1425L22.1021 29.4789H35.74Z"
        fill="#E6E6E6"
      />
      <path
        d="M0.211914 4.16201C0.211914 2.0705 1.90321 0.375 3.98952 0.375C6.07583 0.375 7.76712 2.0705 7.76712 4.16201C7.76712 6.25352 6.07583 7.94903 3.98952 7.94903C1.90321 7.94903 0.211914 6.25352 0.211914 4.16201Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};
