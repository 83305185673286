import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { validatePassword } from 'shared/utils/formUtils';
import { useSaveNewPasswordMutation } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { PasswordRules } from './passwordRules';

export const NewPassword = (): JSX.Element => {
  const [highlightRules, setHighlightRules] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [saveNewPassword, { isError, isSuccess }] = useSaveNewPasswordMutation();
  // other
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const passwordResetKey = searchParams.get('key');

  const { control, formState, handleSubmit, getValues } = useForm<{ password: string; repeatPassword: string }>({
    mode: 'onChange',
    defaultValues: {
      password: ''
    }
  });

  useEffect(() => {
    isError && dispatch(addAlert({ color: 'danger', text: 'Nie udało się zmienić hasła.' }));
  }, [isError, dispatch]);

  useEffect(() => {
    isSuccess && navigate(paths.newPasswordSuccess, { replace: true });
  }, [isSuccess, navigate]);

  useEffect(() => {
    setHighlightRules(!!formState.errors.password);
  }, [formState.errors.password]);

  const onSubmit = (data: { password: string }) => {
    if (!!passwordResetKey) {
      saveNewPassword({ newPassword: data.password, key: passwordResetKey });
    }
  };

  return (
    <RouteLayout>
      <div className="d-flex flex-column h-100 justify-content-between py-5 mx-3">
        <div>
          <Typography variant="h1">Nowe hasło</Typography>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Form.Group className="mb-3" controlId="newPasswordForm.password">
              <Form.Label>Hasło</Form.Label>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{
                  validate: validatePassword
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPasswordForm.repeatPassword">
              <Form.Label>Powtórz hasło</Form.Label>
              <Controller
                name="repeatPassword"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{
                  validate: (value, formValues) => value === formValues.password || 'Hasła nie są identyczne'
                }}
              />
            </Form.Group>

            <PasswordRules password={getValues('password')} disabled={!highlightRules} />

            <JustifyCenter classNames="pt-5">
              <Button className={'long'} type="submit" disabled={!formState.isValid}>
                Utwórz nowe hasło
              </Button>
            </JustifyCenter>
          </Form>
        </div>
        <JustifyCenter>
          <Button
            className={'long'}
            variant="outline-primary"
            as="a"
            onClick={() => (loggedUser ? navigate(paths.root) : navigate(paths.signIn.email))}
          >
            {loggedUser ? 'Wróć do strony głównej' : 'Zaloguj się'}
          </Button>
        </JustifyCenter>
      </div>
    </RouteLayout>
  );
};
