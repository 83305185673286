import { ConfirmationDialog } from 'features/ui/dialogs/confirmationDialog';
import { FormError } from 'features/ui/form/formError';
import { FormLength } from 'features/ui/form/formLength';
import { ImageEdit } from 'features/ui/image-edit/imageEdit';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import {
  useCreateMoralMutation,
  useDeleteMoralMutation,
  useUpdateMoralMutation,
  useUploadMoralImageMutation
} from 'store/api/endpoints/moralEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { setTempMoral } from 'store/slices/storyCreatorSlice';

export const MoralCreator = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [createMoral, createMoralStatus] = useCreateMoralMutation();
  const [updateMoral, updateMoralStatus] = useUpdateMoralMutation();
  const [uploadMoralImage, uploadMoralImageStatus] = useUploadMoralImageMutation();
  const [deleteMoral, deleteMoralStatus] = useDeleteMoralMutation();
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  // other
  const { value } = useParams();
  const moral = loggedUser?.morals.find(w => w.value === value);
  const { blob } = useFetchBlob(moral?.image, loggedUser?.id);

  // location.
  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<{ moralName: string }>({
    mode: 'onChange',
    defaultValues: {
      moralName: value ?? ''
    }
  });

  const onSubmit = (data: { moralName: string }) => {
    if (loggedUser) {
      const request = value
        ? updateMoral({ userId: loggedUser.id, moralName: value, moral: { predefined: false, value: data.moralName } })
        : createMoral({ userId: loggedUser.id, moral: { predefined: false, value: data.moralName } });

      request
        .unwrap()
        .then(async moral => {
          if (avatar) {
            try {
              await uploadMoralImage({ userId: loggedUser.id, moralName: moral.value, file: avatar }).unwrap();
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu morału.' }));
            }
          }
          refetchUser()
            .unwrap()
            .then(() => {
              dispatch(setTempMoral({ random: false, moral }));
              router.navigate(paths.storyCreator.custom.moral.base);
            })
            .catch(() =>
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika, prosimy o odświeżenie strony' }))
            );
        })
        .catch(() => {
          dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać morału.' }));
        });
    }
  };

  const isLoading =
    createMoralStatus.isLoading || updateMoralStatus.isLoading || uploadMoralImageStatus.isLoading || deleteMoralStatus.isLoading;

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.moral.base}
      actions={
        value
          ? [
              {
                action: () => setIsConfirmationDialogOpen(true),
                label: 'Usuń'
              }
            ]
          : []
      }
      bottomActions={[
        {
          label: 'Zapisz',
          submittable: true,
          formId: 'moralForm',
          disabled: !isValid || isLoading
        }
      ]}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usunąć morał?'}
        content={'Czy na pewno chcesz usunąć morał?'}
        onAccept={() => {
          if (loggedUser?.id && value) {
            deleteMoral({ userId: loggedUser.id, moralName: value })
              .unwrap()
              .then(() => {
                refetchUser()
                  .unwrap()
                  .then(() => {
                    dispatch(setTempMoral({ random: true }));
                    router.navigate(paths.storyCreator.custom.moral.base);
                  })
                  .catch(() =>
                    dispatch(
                      addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika, prosimy o odświeżenie strony' })
                    )
                  );
              })
              .catch(e => dispatch(addAlert({ color: 'danger', text: 'Nie udało się usunąć morału' })));
          }
        }}
        onAcceptText={'Tak'}
        onCancelText={'Anuluj'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <Loadable loading={isLoading}>
        <div className="pt-4 mx-3">
          <Typography variant="h1">{moral ? 'Edytuj moral' : 'Dodaj morał'}</Typography>
          <JustifyCenter classNames="mt-5 mb-3">
            <ImageEdit onFileChangeCallback={setAvatar} file={blob} />
          </JustifyCenter>
          <Form onSubmit={handleSubmit(onSubmit)} id="moralForm" noValidate>
            <Form.Group className="mb-3" controlId="moralForm.moralName">
              <Form.Label>Dodaj morał</Form.Label>
              <Controller
                name="moralName"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control
                      {...field}
                      isInvalid={fieldState.invalid}
                      required
                      maxLength={100}
                      placeholder="np. prawdziwych przyjaciół poznaje się w biedzie"
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}</div>
                      <div>
                        <FormLength current={field.value.length} max={100} />
                      </div>
                    </div>
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>
          </Form>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
