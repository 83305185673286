import { PredefinedWritingStyle } from 'shared/types/writingStyle';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedWritingStyles: builder.query<PredefinedWritingStyle[], void>({
      query: () => ({
        url: `/public/predefined-writing-styles`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetPredefinedWritingStylesQuery } = extendedApi;
