import { Middleware } from '@reduxjs/toolkit';
import { ConnectedObject, NotificationSeverity } from 'shared/types/notification';
import SockJS from 'sockjs-client';
import { extendedApi as notificationApi, extendedApi as notificationEndpoints } from 'store/api/endpoints/notificationEndpoint';
import { addAlert } from 'store/slices/alertsSlice';
import { AuthState, LOGOUT_ACTION_TYPE } from 'store/slices/authSlice';
import { setAwaitingPaymentNotification } from 'store/slices/paymentSlice';
import { setGeneratedStoryId, setIsGenerating } from 'store/slices/storyGeneratorSlice';
import Stomp, { Client } from 'webstomp-client';
import { store } from '../store';

export interface NotificationPayload {
  notificationId: string;
  connectedObject: ConnectedObject;
  severity: NotificationSeverity;
}

let stompClient: Client | null = null;
let isConnected = false;
let isPending = false;
let reconnectInterval: number | undefined;
let reconnectIntervalAttempts: number = 0;

const connect = (authState: Required<AuthState>) => {
  if (isPending || isConnected) {
    return;
  }
  isPending = true;

  const socket = new SockJS(`//${process.env.REACT_APP_SERVER_HOST}/websocket/notification`);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });

  stompClient.connect(
    { 'X-Authorization': authState.accessToken },
    () => onConnectedCallback(authState),
    () => onConnectionError(authState)
  );
};

const onConnectedCallback = async (authState: Required<AuthState>) => {
  isPending = false;
  isConnected = true;

  // handle notifications received when websocket was disconnected
  try {
    const notifications = await store
      .dispatch(notificationApi.endpoints.getNotifications.initiate(authState.loggedUser.id, { forceRefetch: true }))
      .unwrap();
    notifications.forEach(async n => {
      await handleNotification({ notificationId: n.id, connectedObject: n.connectedObject, severity: n.severity }, authState.loggedUser.id);
    });
  } catch (e) {
    console.error(e);
  }

  stompClient?.subscribe('/topic/notifications/' + authState.loggedUser.id, (data: any) => {
    const notificationPayload = JSON.parse(data.body) as NotificationPayload;
    handleNotification(notificationPayload, authState.loggedUser.id);
  });
};

const handleNotification = async (notificationPayload: NotificationPayload, userId: string) => {
  switch (notificationPayload.connectedObject.type) {
    case 'STORY_GENERATED':
      store.dispatch(setGeneratedStoryId(notificationPayload.connectedObject.objectId));
      store.dispatch(setIsGenerating(false));
      break;
    case 'GENERATOR_ERROR':
      store.dispatch(addAlert({ color: 'danger', text: 'Nie udało się utworzyć bajki' }));
      store.dispatch(setIsGenerating(false));
      break;
    case 'NOT_ENOUGH_TOKENS':
      store.dispatch(addAlert({ color: 'warning', text: 'Nie masz wystarczającej liczby tokenów aby wygenerować bajkę' }));
      store.dispatch(setIsGenerating(false));
      break;
    case 'TRANSACTION':
      store.dispatch(
        setAwaitingPaymentNotification({
          transactionId: notificationPayload.connectedObject.objectId,
          status: notificationPayload.severity
        })
      );
      break;
    default:
      break;
  }
  await updateReadStatus(userId, notificationPayload.notificationId)
    .unwrap()
    .catch(e => console.error(e));
  store.dispatch(notificationEndpoints.util.invalidateTags([{ type: 'Notification', id: 'LIST' }]));
};

const updateReadStatus = (userId: string, notificationId: string) => {
  return store.dispatch(
    notificationApi.endpoints.toggleReadStatus.initiate({
      userId,
      readStatuses: [{ id: notificationId, read: true }]
    })
  );
};

const onConnectionError = (authState: Required<AuthState>) => {
  disconnect();
  if (authState.loggedUser && authState.accessToken && !isConnected && !isPending) {
    attemptReconnect({ accessToken: authState.accessToken, loggedUser: authState.loggedUser });
  }
};

const attemptReconnect = (authState: Required<AuthState>) => {
  reconnectInterval = window.setInterval(() => {
    if (isPending || isConnected || !authState.loggedUser || !authState.accessToken) {
      reconnectIntervalAttempts = 0;
      window.clearInterval(reconnectInterval);
      return;
    }
    console.debug(`Attempting websocket reconnect... (${reconnectIntervalAttempts})`);
    reconnectIntervalAttempts++;
    connect(authState);
  }, 5000);
};

export const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  isConnected = false;
  isPending = false;
  window.clearInterval(reconnectInterval);
};

const websocketMiddleware: Middleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === LOGOUT_ACTION_TYPE) {
    disconnect();
    return next(action);
  }

  const authState: AuthState = store.getState().authSlice;
  if (authState.loggedUser && authState.accessToken && !isConnected && !isPending) {
    connect({ accessToken: authState.accessToken, loggedUser: authState.loggedUser });
  }

  if (!authState.loggedUser || !authState.accessToken) {
    window.clearInterval(reconnectInterval);
  }

  return next(action);
};

export default websocketMiddleware;
