import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { extendedApi } from 'store/api/endpoints/accountEndpoints';
import { useIsGeneratingQuery } from 'store/api/endpoints/generatorEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateAccessToken } from 'store/slices/authSlice';

export const OAuthRedirectSuccess = (): JSX.Element => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchIsGenerating } = useIsGeneratingQuery();
  // other
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryParamToken = searchParams.get('token');

  useEffect(() => {
    if (queryParamToken) {
      dispatch(updateAccessToken(queryParamToken));
      dispatch(extendedApi.endpoints.getLoggedUser.initiate(undefined, { forceRefetch: true }));
    }
  }, [dispatch, queryParamToken]);

  useEffect(() => {
    if (loggedUser) {
      navigate(paths.root, { replace: true });
      refetchIsGenerating();
    }
  }, [loggedUser, navigate, refetchIsGenerating]);

  return (
    <RouteLayout>
      <Loadable loading={true}>
        <></>
      </Loadable>
    </RouteLayout>
  );
};
