import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { validateEmail, validatePassword, validationMessage } from 'shared/utils/formUtils';
import { cGrey600, cPrimary } from 'shared/utils/styleCommon';
import { useRegisterMutation } from 'store/api/endpoints/accountEndpoints';
import { useValidateRecaptchaTokenMutation } from 'store/api/endpoints/recaptchaEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { PasswordRules } from './passwordRules';

interface SignUpFormValues {
  email: string;
  password: string;
  firstName: string;
  repeatPassword: string;
}

export const SignUpByEmail = (): JSX.Element => {
  const [highlightRules, setHighlightRules] = useState(false);
  const captchaRef = useRef<any>(null);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [register, { isSuccess, isError, isLoading }] = useRegisterMutation();
  const [validateRecaptchaToken] = useValidateRecaptchaTokenMutation();
  // other
  const navigate = useNavigate();
  const location = useLocation();

  const {
    control,
    formState,
    reset: resetForm,
    handleSubmit,
    getValues
  } = useForm<SignUpFormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: ''
    }
  });

  useEffect(() => {
    if (location.state) {
      resetForm({
        email: location.state?.email ?? '',
        password: location.state?.password ?? '',
        repeatPassword: location.state?.repeatPassword ?? '',
        firstName: location.state?.firstName ?? ''
      });
    }
  }, [resetForm, location.state]);

  useEffect(() => {
    isSuccess && navigate(paths.signUpSuccess, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, navigate]);

  useEffect(() => {
    isError &&
      dispatch(
        addAlert({
          color: 'danger',
          text: 'Konto z tym adresem e-mail może już istnieć lub został przesłany błędny formularz.'
        })
      );
  }, [isError, dispatch]);

  useEffect(() => {
    setHighlightRules(!!formState.errors.password);
  }, [formState, formState.errors.password]);

  useEffect(() => {
    loggedUser && navigate(paths.signIn.email, { replace: true });
  }, [loggedUser, navigate]);

  const onSubmit = (data: SignUpFormValues) => {
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      dispatch(addAlert({ color: 'warning', text: 'Zweryfikuj CAPTCHA' }));
      return;
    }

    validateRecaptchaToken(captchaValue)
      .unwrap()
      .then(response => {
        if (!response) {
          dispatch(addAlert({ color: 'danger', text: 'Błąd weryfikacji CAPTCHA' }));
        } else {
          captchaRef.current?.reset();
        }
      })
      .catch(() => dispatch(addAlert({ color: 'danger', text: 'Błąd weryfikacji CAPTCHA' })));
    register({
      email: data.email.toLowerCase(),
      password: data.password,
      firstName: data.firstName
    });
  };

  return (
    <RouteLayout backRoute={-1}>
      <Loadable loading={isLoading}>
        <div className="d-flex flex-column h-100 justify-content-between py-5 mx-3">
          <div>
            <Typography variant="h1" classNames="text-center" styles={{ paddingBottom: 32 }}>
              Zarejestruj się
            </Typography>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Form.Group className="mb-3" controlId="signUpForm.firstName">
                <Form.Label>Imię</Form.Label>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} placeholder="Imię" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required,
                    minLength: { value: 3, message: 'Imię powinno posiadać przynajmniej trzy znaki' }
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.email">
                <Form.Label>E-mail</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    required: validationMessage.required,
                    validate: validateEmail
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.password">
                <Form.Label>Hasło</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="password" placeholder="Hasło" isInvalid={fieldState.invalid} required />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    validate: validatePassword
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="signUpForm.repeatPassword">
                <Form.Label>Powtórz hasło</Form.Label>
                <Controller
                  name="repeatPassword"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Form.Control {...field} type="password" placeholder="Powtórz hasło" required isInvalid={fieldState.invalid} />
                      {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                    </>
                  )}
                  rules={{
                    validate: (value, formValues) => value === formValues.password || 'Hasła nie są identyczne'
                  }}
                />
              </Form.Group>
              <PasswordRules password={getValues('password')} disabled={!highlightRules} />
              <div className="mt-3">
                {process.env.REACT_APP_RECAPTCHA_KEY && <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} ref={captchaRef} />}
              </div>

              <JustifyCenter classNames="pt-5 pb-2">
                <Button type="submit" disabled={!formState.isValid || isLoading}>
                  Zarejestruj się
                </Button>
              </JustifyCenter>
            </Form>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 mb-4 mt-3">
            <Typography variant="description-md" styles={{ color: cGrey600 }}>
              Masz już konto?
            </Typography>
            <Typography
              variant="description-md"
              styles={{ color: cPrimary, cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => navigate(paths.signIn.options)}
            >
              Zaloguj się
            </Typography>
          </div>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
