import { ActivateAccount } from 'features/authentication/activateAccount';
import { Logout } from 'features/authentication/logout';
import { NewPassword } from 'features/authentication/newPassword';
import { OAuthRedirectError } from 'features/authentication/oAuthRedirectError';
import { OAuthRedirectSuccess } from 'features/authentication/oAuthRedirectSuccess';
import { ResetPassword } from 'features/authentication/resetPassword';
import { SignIn } from 'features/authentication/signIn';
import { SignInByEmail } from 'features/authentication/signInByEmail';
import { SignUp } from 'features/authentication/signUp';
import { SignUpByEmail } from 'features/authentication/signUpByEmail';
import { Dashboard } from 'features/dashboard/dashboard';
import { Home } from 'features/home/home';
import { Library } from 'features/library/library';
import { PaymentRedirect } from 'features/payments/paymentRedirect';
import { TokenPackageList } from 'features/payments/token-packages/tokenPackageList';
import { CategoryStories } from 'features/search/category/categoryStories';
import { SearchStory } from 'features/search/searchStory';
import { SeriesRenderer } from 'features/series/seriesRenderer';
import { CustomStoryCreator } from 'features/story-creator/customStoryCreator';
import { StoryCreatorLoader } from 'features/story-creator/loader/storyCreatorLoader';
import { CharacterPicker } from 'features/story-creator/pickers/commonPickers/characterPicker';
import { NarratorPicker } from 'features/story-creator/pickers/commonPickers/narratorPicker';
import { RecipientPicker } from 'features/story-creator/pickers/commonPickers/recipientPicker';
import { MoralCreator } from 'features/story-creator/pickers/customPickers/creators/moralCreator';
import { WorldCreator } from 'features/story-creator/pickers/customPickers/creators/worldCreator';
import { ElementsPicker } from 'features/story-creator/pickers/customPickers/elementsPicker';
import { EpochPicker } from 'features/story-creator/pickers/customPickers/epochPicker';
import { LengthPicker } from 'features/story-creator/pickers/customPickers/lengthPicker';
import { MoralPicker } from 'features/story-creator/pickers/customPickers/moralPicker';
import { WorldPicker } from 'features/story-creator/pickers/customPickers/worldPicker';
import { WritingStylePicker } from 'features/story-creator/pickers/customPickers/writingStylePicker';
import { OtherPicker } from 'features/story-creator/pickers/templatePickers/otherPicker';
import { TemplateStoryCreator } from 'features/story-creator/templateStoryCreator';
import { StoryRating } from 'features/story/storyRating';
import { StoryRenderer } from 'features/story/storyRenderer';
import { Unlock } from 'features/subscription/unlock';
import { AlertPage } from 'features/ui/alert-page/alertPage';
import { Typography } from 'features/ui/typography/typography';
import { ChildProfiles } from 'features/user-profile/child-profile/childProfiles';
import {
  ChildProfileCreatorWrapper,
  HeroCreatorWrapper,
  MainCharacterHeroCreatorWrapper,
  MainHeroChildProfileCreatorWrapper,
  RecipientChildProfileCreatorWrapper,
  SideCharacterHeroCreatorWrapper
} from 'features/user-profile/child-profile/creatorWrappers';
import { Heroes } from 'features/user-profile/hero-profile/heroes';
import { PaymentHistory } from 'features/user-profile/payment-history/paymentHistory';
import { EmailSettings } from 'features/user-profile/settings/emailSettings';
import { PasswordSettings } from 'features/user-profile/settings/passwordSettings';
import { Settings } from 'features/user-profile/settings/settings';
import { SubscriptionPlans } from 'features/user-profile/subscription-plans/subscriptionPlans';
import { UserProfile } from 'features/user-profile/userProfile';
import { createBrowserRouter } from 'react-router-dom';
import { AuthorityType } from 'shared/types/account';
import { securedGuard } from './guards/utils';
import paths from './utils';

const storyCreatorTemplateRoutes = [
  {
    path: paths.storyCreator.template.base,
    element: securedGuard(<TemplateStoryCreator />, AuthorityType.USER)
  },
  // recipient
  {
    path: paths.storyCreator.template.recipient.base,
    element: securedGuard(<RecipientPicker prevRoute={paths.storyCreator.template.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.template.recipient.createChild,
    element: securedGuard(
      <RecipientChildProfileCreatorWrapper backRoute={paths.storyCreator.template.recipient.base} />,
      AuthorityType.USER
    )
  },
  {
    path: paths.storyCreator.template.recipient.editChild + '/:childId',
    element: securedGuard(
      <RecipientChildProfileCreatorWrapper backRoute={paths.storyCreator.template.recipient.base} />,
      AuthorityType.USER
    )
  },
  // characters
  {
    path: paths.storyCreator.template.characters.base,
    element: securedGuard(<CharacterPicker prevRoute={paths.storyCreator.template.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.template.characters.createChild,
    element: securedGuard(
      <MainHeroChildProfileCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />,
      AuthorityType.USER
    )
  },
  {
    path: paths.storyCreator.template.characters.editChild + '/:childId',
    element: securedGuard(
      <MainHeroChildProfileCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />,
      AuthorityType.USER
    )
  },
  {
    path: paths.storyCreator.template.characters.createHeroAsMain,
    element: securedGuard(<MainCharacterHeroCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.template.characters.editHeroAsMain + '/:heroId',
    element: securedGuard(<MainCharacterHeroCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.template.characters.createHeroAsSide,
    element: securedGuard(<SideCharacterHeroCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.template.characters.editHeroAsSide + '/:heroId',
    element: securedGuard(<SideCharacterHeroCreatorWrapper backRoute={paths.storyCreator.template.characters.base} />, AuthorityType.USER)
  },
  // narrator
  {
    path: paths.storyCreator.template.narrator,
    element: securedGuard(<NarratorPicker prevRoute={paths.storyCreator.template.base} />, AuthorityType.USER)
  },
  // other
  {
    path: paths.storyCreator.template.other + '/:key/:label',
    element: securedGuard(<OtherPicker />, AuthorityType.USER)
  }
];

const storyCreatorCustomRoutes = [
  {
    path: paths.storyCreator.custom.base,
    element: securedGuard(<CustomStoryCreator />, AuthorityType.USER)
  },
  // recipient
  {
    path: paths.storyCreator.custom.recipient.base,
    element: securedGuard(<RecipientPicker prevRoute={paths.storyCreator.custom.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.recipient.createChild,
    element: securedGuard(<RecipientChildProfileCreatorWrapper backRoute={paths.storyCreator.custom.recipient.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.recipient.editChild + '/:childId',
    element: securedGuard(<RecipientChildProfileCreatorWrapper backRoute={paths.storyCreator.custom.recipient.base} />, AuthorityType.USER)
  },
  // characters
  {
    path: paths.storyCreator.custom.characters.base,
    element: securedGuard(<CharacterPicker prevRoute={paths.storyCreator.custom.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.createChild,
    element: securedGuard(<MainHeroChildProfileCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.editChild + '/:childId',
    element: securedGuard(<MainHeroChildProfileCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.createHeroAsMain,
    element: securedGuard(<MainCharacterHeroCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.editHeroAsMain + '/:heroId',
    element: securedGuard(<MainCharacterHeroCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.createHeroAsSide,
    element: securedGuard(<SideCharacterHeroCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.characters.editHeroAsSide + '/:heroId',
    element: securedGuard(<SideCharacterHeroCreatorWrapper backRoute={paths.storyCreator.custom.characters.base} />, AuthorityType.USER)
  },
  // narrator
  {
    path: paths.storyCreator.custom.narrator,
    element: securedGuard(<NarratorPicker prevRoute={paths.storyCreator.custom.base} />, AuthorityType.USER)
  },
  // moral
  {
    path: paths.storyCreator.custom.moral.base,
    element: securedGuard(<MoralPicker />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.moral.createMoral,
    element: securedGuard(<MoralCreator />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.moral.editMoral + '/:value',
    element: securedGuard(<MoralCreator />, AuthorityType.USER)
  },
  // world
  {
    path: paths.storyCreator.custom.world.base,
    element: securedGuard(<WorldPicker />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.world.createWorld,
    element: securedGuard(<WorldCreator />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.world.editWorld + '/:value',
    element: securedGuard(<WorldCreator />, AuthorityType.USER)
  },
  // rest
  {
    path: paths.storyCreator.custom.writingStyle,
    element: securedGuard(<WritingStylePicker />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.epoch,
    element: securedGuard(<EpochPicker />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.elements,
    element: securedGuard(<ElementsPicker />, AuthorityType.USER)
  },
  {
    path: paths.storyCreator.custom.length,
    element: securedGuard(<LengthPicker />, AuthorityType.USER)
  }
];

const profileRoutes = [
  {
    path: paths.profile.base,
    element: securedGuard(<UserProfile />, AuthorityType.USER)
  },
  {
    path: paths.profile.plans,
    element: securedGuard(<SubscriptionPlans />, AuthorityType.USER)
  },
  {
    path: paths.profile.childProfiles,
    element: securedGuard(<ChildProfiles />, AuthorityType.USER)
  },
  {
    path: paths.profile.heroes,
    element: securedGuard(<Heroes />, AuthorityType.USER)
  },
  {
    path: paths.profile.characters.createChild,
    element: securedGuard(<ChildProfileCreatorWrapper backRoute={paths.profile.childProfiles} />, AuthorityType.USER)
  },
  {
    path: paths.profile.characters.editChild + '/:childId',
    element: securedGuard(<ChildProfileCreatorWrapper backRoute={paths.profile.childProfiles} />, AuthorityType.USER)
  },
  {
    path: paths.profile.characters.createHero,
    element: securedGuard(<HeroCreatorWrapper backRoute={paths.profile.heroes} />, AuthorityType.USER)
  },
  {
    path: paths.profile.characters.editHero + '/:heroId',
    element: securedGuard(<HeroCreatorWrapper backRoute={paths.profile.heroes} />, AuthorityType.USER)
  },
  {
    path: paths.profile.settings.base,
    element: securedGuard(<Settings />, AuthorityType.USER)
  },
  {
    path: paths.profile.settings.changePassword,
    element: securedGuard(<PasswordSettings />, AuthorityType.USER)
  },
  {
    path: paths.profile.settings.changeEmail,
    element: securedGuard(<EmailSettings />, AuthorityType.USER)
  }
];

export const router = createBrowserRouter([
  {
    path: paths.root,
    element: <Dashboard />,
    errorElement: (
      <AlertPage
        title="Ups"
        content={
          <Typography variant="description" classNames="pt-4 text-center">
            Coś poszło nie tak, spróbuj powrócić do głównej strony
          </Typography>
        }
        status="error"
        actionName="Powrót"
        actionCallback={() => router.navigate(paths.root)}
      />
    ),

    children: [
      // home
      {
        element: <Home />,
        index: true
      },
      // subscriptions
      {
        path: paths.subscription.base + '/:requiredSubscription',
        element: <Unlock />
      },
      {
        path: paths.subscription.subscriptionSuccess,
        element: (
          <AlertPage
            title="Wspaniale!"
            content={
              <Typography variant="description" classNames="pt-4 text-center">
                Twoja subskrypcja została pomyślnie aktywowana. Teraz możesz cieszyć się pełnym zakresem personalizacji i tworzyć bajki
                dokładnie tak, jak chcesz.
              </Typography>
            }
            status="success"
            actionName="Wybierz bajkę"
            actionCallback={() => router.navigate(paths.root)}
          />
        )
      },
      {
        path: paths.subscription.subscriptionError,
        element: (
          <AlertPage
            title="Oho!"
            content={
              <Typography variant="description" classNames="pt-4 text-center">
                Wygląda na to, że coś poszło nie tak z wykupieniem subskrypcji. Nie martw się, spróbuj ponownie.
              </Typography>
            }
            status="error"
            actionName="Wybierz bajkę"
            actionCallback={() => router.navigate(paths.root)}
          />
        )
      },
      // account
      {
        path: paths.signIn.options,
        element: <SignIn />
      },
      {
        path: paths.signIn.email,
        element: <SignInByEmail />
      },
      {
        path: paths.signUp.email,
        element: <SignUpByEmail />
      },
      {
        path: paths.signUp.options,
        element: <SignUp />
      },
      {
        path: paths.resetPassword,
        element: <ResetPassword />
      },
      {
        path: paths.logout,
        element: <Logout />
      },
      {
        path: paths.activate,
        element: <ActivateAccount />
      },
      {
        path: paths.newPassword,
        element: <NewPassword />
      },
      {
        path: paths.oauthSuccess,
        element: <OAuthRedirectSuccess />
      },
      {
        path: paths.oauthFailure,
        element: <OAuthRedirectError />
      },
      {
        path: paths.newPasswordSuccess,
        element: (
          <AlertPage
            title="Hasło pomyślnie zmienione"
            content={
              <Typography variant="description" classNames="pt-4 text-center">
                Twoje hasło jest już zmienione, możesz ponownie zalogować się do aplikacji.
              </Typography>
            }
            status="success"
            actionName="Zaloguj się"
            actionCallback={() => router.navigate(paths.root)}
          />
        )
      },
      {
        path: paths.resetPasswordSuccess,
        element: (
          <AlertPage
            title="Link do resetu hasła został wysłany"
            content={
              <Typography variant="description" classNames="pt-4 text-center">
                Na Twój e-mail została wysłana wiadomość z linkiem do resetu hasła. Sprawdź folder Spam
              </Typography>
            }
            status="success"
            actionName="Wróć do logowania"
            actionCallback={() => router.navigate(paths.root)}
          />
        )
      },
      {
        path: paths.signUpSuccess,
        element: (
          <AlertPage
            title="Link do aktywacji konta został wysłany"
            content={
              <Typography variant="description" classNames="pt-4 text-center">
                Na Twój e-mail została wysłana wiadomość z linkiem do aktywacji konta. Sprawdź folder Spam
              </Typography>
            }
            status="success"
            actionName="Wróć do logowania"
            actionCallback={() => router.navigate(paths.root)}
          />
        )
      },
      // profile
      ...profileRoutes,
      // story creator
      ...storyCreatorTemplateRoutes,
      ...storyCreatorCustomRoutes,
      // search
      {
        path: paths.search.base,
        element: <SearchStory />
      },
      {
        path: paths.search.category.base + '/:categoryName',
        element: <CategoryStories />
      },
      // library
      {
        path: paths.library,
        element: securedGuard(<Library />, AuthorityType.USER)
      },
      // story
      {
        path: paths.story + '/:storyId',
        element: <StoryRenderer />
      },
      {
        path: paths.rating + '/:storyId',
        element: securedGuard(<StoryRating />, AuthorityType.USER)
      },
      // series
      {
        path: paths.series + '/:seriesId',
        element: <SeriesRenderer />
      },
      {
        path: '/loader',
        element: securedGuard(<StoryCreatorLoader />, AuthorityType.USER)
      },
      // payments
      {
        path: paths.payments.packages,
        element: <TokenPackageList />
      },
      {
        path: paths.payments.history,
        element: <PaymentHistory />
      },
      {
        path: paths.payments.redirect,
        element: <PaymentRedirect />
      }
    ]
  }
]);
