import { ConfirmationDialog } from 'features/ui/dialogs/confirmationDialog';
import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { AgeInput } from 'features/ui/masked-inputs/ageInput';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { ChildProfile, Gender } from 'shared/types/users';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import {
  useCreateProfileMutation,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
  useUploadProfileImageMutation
} from 'store/api/endpoints/childProfileEndpoints';
import { useGetPredefinedTagsQuery } from 'store/api/endpoints/predefinedTagsEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { ImageEdit } from '../../ui/image-edit/imageEdit';

interface ProfileFormValues {
  name: string;
  age: string;
  gender: Gender;
}

const defaultPredefinedTags: PredefinedTag[] = [];

type Props = {
  childId?: string;
  backRoute?: string;
  onSaveCallback: (profile: ChildProfile) => void;
  onDeleteCallback: (childId: string) => void;
};

export const ChildProfileCreator = (props: Props) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [selectedTags, setSelectedTags] = useState<PredefinedTag[]>([]);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const { data: predefinedTags = defaultPredefinedTags } = useGetPredefinedTagsQuery();
  const [uploadProfileImage, updateChildProfileImageStatus] = useUploadProfileImageMutation();
  const [createProfile, createChildProfileStatus] = useCreateProfileMutation();
  const [updateProfile, updateChildProfileStatus] = useUpdateProfileMutation();
  const [deleteProfile, deleteChildProfileStatus] = useDeleteProfileMutation();
  // other
  const profile = loggedUser?.childProfiles.find(h => h.id === props.childId);
  const { blob: image } = useFetchBlob(profile?.image, loggedUser?.id);

  const {
    control,
    handleSubmit,
    reset: resetForm
  } = useForm<ProfileFormValues>({
    mode: 'onBlur'
  });

  useEffect(() => {
    if (profile) {
      resetForm({
        name: profile.name,
        age: profile.age.toString(),
        gender: profile.gender
      });

      setSelectedTags(predefinedTags.filter(dpt => profile?.tags.find(t => t.predefined && t.value === dpt.value) !== undefined));
    }
  }, [profile, resetForm, predefinedTags]);

  const onSubmit = (data: ProfileFormValues) => {
    if (!loggedUser?.id) return;

    const profileData = {
      name: data.name,
      age: parseInt(data.age),
      gender: data.gender,
      tags: selectedTags.map(st => ({ predefined: true, value: st.value }))
    };

    const request = profile
      ? updateProfile({ userId: loggedUser.id, profile: { id: profile.id, ...profileData } })
      : createProfile({ userId: loggedUser.id, profile: profileData });

    request
      .unwrap()
      .then(async profile => {
        if (avatar) {
          try {
            await uploadProfileImage({ userId: loggedUser.id, profileId: profile.id, file: avatar }).unwrap();
          } catch (error) {
            dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać awataru.' }));
          }
        }
        refetchUser()
          .unwrap()
          .then(() => props.onSaveCallback(profile))
          .catch(() =>
            dispatch(addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika, prosimy o odświeżenie strony' }))
          );
      })
      .catch(() => {
        dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać profilu.' }));
      });
  };

  const onTagClick = (tag: PredefinedTag) => {
    setSelectedTags(prev => {
      if (prev.find(pt => pt.id === tag.id)) {
        return prev.filter(pt => pt.id !== tag.id);
      } else {
        return [...prev, tag];
      }
    });
  };

  const isLoading =
    createChildProfileStatus.isLoading ||
    updateChildProfileStatus.isLoading ||
    deleteChildProfileStatus.isLoading ||
    updateChildProfileImageStatus.isLoading;

  return (
    <RouteLayout
      backRoute={props.backRoute}
      actions={
        props.childId
          ? [
              {
                action: () => setIsConfirmationDialogOpen(true),
                label: 'Usuń'
              }
            ]
          : []
      }
      bottomActions={[
        {
          label: 'Zapisz',
          submittable: true,
          formId: 'childCreatorForm',
          disabled: isLoading
        }
      ]}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usunąć profil?'}
        content={'Czy na pewno chcesz usunąć profil?'}
        onAccept={() => {
          if (loggedUser?.id && props.childId) {
            deleteProfile({ userId: loggedUser.id, profileId: props.childId })
              .unwrap()
              .then(() => {
                refetchUser()
                  .unwrap()
                  .then(() => {
                    props.childId && props.onDeleteCallback(props.childId);
                  })
                  .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się usunąć profilu' })));
              });
          }
        }}
        onAcceptText={'Tak'}
        onCancelText={'Anuluj'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <Loadable loading={isLoading}>
        <div className="mx-3">
          <div className="pt-5 pb-5">
            <Typography variant="h1">{props.childId ? 'Edytuj profil' : 'Dodaj profil'}</Typography>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} id="childCreatorForm" noValidate>
            <JustifyCenter classNames="mb-3">
              <ImageEdit onFileChangeCallback={setAvatar} file={image} />
            </JustifyCenter>

            <Form.Group className="mb-3" controlId="childCreatorForm.name">
              <Form.Label>Imię *</Form.Label>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control {...field} isInvalid={fieldState.invalid} required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="childCreatorForm.age">
              <Form.Label>Wiek *</Form.Label>
              <Controller
                name="age"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <AgeInput className="form-control" {...field} />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="childCreatorForm.gender">
              <Form.Label>Płeć *</Form.Label>
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Select {...field} isInvalid={fieldState.invalid} defaultValue={'-1'}>
                      <option disabled hidden value={'-1'}>
                        Wybierz płeć
                      </option>
                      <option value={Gender.MALE}>Męska</option>
                      <option value={Gender.FEMALE}>Żeńska</option>
                    </Form.Select>
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>
            <Typography variant="description" classNames="mb-3">
              Zainteresowania
            </Typography>
            <JustifyCenter classNames="gap-2 flex-wrap pb-5">
              {predefinedTags
                ?.filter((t: PredefinedTag): t is PredefinedTag & { id: string } => !_.isNil(t.id))
                .map(t => (
                  <TagBadge
                    key={t.id}
                    value={t.value}
                    isActive={selectedTags.find(st => t.id === st.id) !== undefined}
                    onClickCallback={() => onTagClick(t)}
                  />
                ))}
            </JustifyCenter>
          </Form>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
