export const Storytailo = () => {
  return (
    <svg width="104" height="22" viewBox="0 0 104 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2152_3421)">
        <path
          d="M10.5022 7.04939C10.4841 7.04045 10.2918 6.96299 10.2738 6.95703C11.031 6.48628 12.3471 5.32133 13.0052 4.40069C13.8586 3.20893 14.3814 0.867108 12.5695 0.149069C11.7312 -0.184625 10.5923 0.101399 9.84412 0.488722C8.67224 1.09354 7.55745 2.01418 6.76117 3.054C5.72751 4.40367 4.77799 6.59354 6.28341 7.99089C6.36454 8.06835 6.45468 8.13688 6.54182 8.20838C4.97931 8.7417 3.40178 8.70297 1.91739 8.3514C1.35249 8.21732 -0.612669 10.4161 0.186615 10.6396C1.2954 10.9763 2.68362 10.6724 3.75935 10.3446C5.00936 9.96326 6.19927 9.42101 7.34711 8.79831C8.3327 9.43292 9.45951 9.90665 10.4751 10.3625C11.8483 10.9792 12.9541 11.9863 13.0773 13.2674C13.3147 15.7672 11.043 17.0453 8.85854 17.0453C6.67403 17.0453 6.54783 15.9042 6.51778 15.4931C6.47271 14.8853 6.96851 14.4056 6.86634 13.4164C6.76418 12.454 6.11514 11.8731 4.96128 11.8522C3.93063 11.8314 3.06223 12.7222 2.99913 13.9557C2.89997 15.9191 4.53159 18.0256 7.23593 18.4308C9.47753 18.7674 12.0437 18.3444 13.7895 17.0364C15.9469 15.4215 17.4283 11.9088 14.8291 9.42399C13.6993 8.34246 11.9325 7.63932 10.4992 7.05237L10.5022 7.04939ZM9.24917 2.62794C9.78703 2.34192 10.7396 2.20189 11.016 2.86034C11.5298 4.0819 10.0575 5.76824 9.32429 6.49522C9.32429 6.49522 9.32128 6.4982 9.31828 6.50118C8.55205 6.08108 7.86695 5.49414 7.90601 4.62415C7.94507 3.81077 8.54303 3.00633 9.25217 2.62794H9.24917Z"
          fill="#EC8D29"
        />
        <path
          d="M22.5173 7.95484C22.8869 7.95484 23.1934 7.83865 23.4368 7.60029C23.6832 7.36492 23.8064 7.06996 23.8064 6.72137C23.8064 6.37277 23.6832 6.051 23.4368 5.80669C23.1904 5.56237 22.8839 5.44022 22.5173 5.44022H20.8827V3.97732C20.8827 3.58106 20.7565 3.25035 20.5041 2.98518C20.2517 2.72001 19.9242 2.58594 19.5245 2.58594C19.1249 2.58594 18.7913 2.72001 18.5239 2.98518C18.2565 3.25035 18.1213 3.58404 18.1213 3.97732V5.44022H17.0606C16.691 5.44022 16.3845 5.55939 16.1411 5.79477C15.8947 6.03014 15.7715 6.3251 15.7715 6.6737C15.7715 7.02229 15.8947 7.34406 16.1411 7.58838C16.3875 7.83269 16.694 7.95484 17.0606 7.95484H18.1213V14.2563C18.1213 15.0012 18.2354 15.5881 18.4668 16.0142C18.6982 16.4402 18.9836 16.756 19.3292 16.9616C19.6748 17.1672 20.0443 17.2983 20.435 17.3489C20.8256 17.4026 21.1832 17.4294 21.5047 17.4294C22.0426 17.4294 22.5444 17.2923 23.0131 17.0182C23.4819 16.7441 23.7162 16.3955 23.7162 15.9665C23.7162 15.6149 23.6291 15.3438 23.4518 15.1442C23.2745 14.9475 23.0792 14.8462 22.8659 14.8462C22.6676 14.8462 22.4903 14.879 22.337 14.9505C22.1838 15.019 22.0155 15.0518 21.8292 15.0518C21.6609 15.0518 21.5017 15.022 21.3575 14.9594C21.2102 14.8999 21.096 14.7986 21.0119 14.6615C20.9278 14.5245 20.8857 14.3487 20.8857 14.1371V7.94888H22.5203L22.5173 7.95484Z"
          fill="#EC8D29"
        />
        <path
          d="M34.1626 5.79771C33.2191 5.25843 32.1614 4.9873 30.9955 4.9873C29.8297 4.9873 28.772 5.25843 27.8284 5.79771C26.8849 6.33996 26.1307 7.08481 25.5718 8.03525C25.0099 8.98568 24.7305 10.0881 24.7305 11.3364C24.7305 12.5848 25.0099 13.6842 25.5718 14.6347C26.1307 15.5851 26.8849 16.327 27.8284 16.8603C28.772 17.3936 29.8297 17.6588 30.9955 17.6588C32.1614 17.6588 33.2191 17.3936 34.1626 16.8603C35.1061 16.327 35.8603 15.5851 36.4192 14.6347C36.9781 13.6842 37.2606 12.5848 37.2606 11.3364C37.2606 10.0881 36.9811 8.98866 36.4192 8.03525C35.8603 7.08481 35.1061 6.33698 34.1626 5.79771ZM34.0244 13.3326C33.7089 13.9047 33.2882 14.3486 32.7564 14.6674C32.2275 14.9862 31.6386 15.1471 30.9955 15.1471C30.3525 15.1471 29.7636 14.9862 29.2347 14.6674C28.7059 14.3486 28.2822 13.9017 27.9667 13.3326C27.6512 12.7606 27.4949 12.0962 27.4949 11.3335C27.4949 10.5707 27.6512 9.88248 27.9667 9.31342C28.2822 8.74137 28.7028 8.29744 29.2347 7.97864C29.7636 7.65984 30.3525 7.49895 30.9955 7.49895C31.6386 7.49895 32.2275 7.65984 32.7564 7.97864C33.2852 8.29744 33.7089 8.74435 34.0244 9.31342C34.3399 9.88546 34.4962 10.5588 34.4962 11.3335C34.4962 12.1081 34.3399 12.7606 34.0244 13.3326Z"
          fill="#EC8D29"
        />
        <path
          d="M46.3201 5.37463C46.0045 5.11542 45.6169 4.9873 45.1572 4.9873C44.7726 4.9873 44.388 5.032 44.0063 5.12436C43.6217 5.21672 43.2611 5.35675 42.9246 5.54743C42.5881 5.73812 42.2846 5.97349 42.0141 6.25654C41.8188 6.45914 41.6596 6.69451 41.5183 6.9418V6.61109C41.5183 6.21482 41.3891 5.88411 41.1277 5.61894C40.8663 5.35377 40.5358 5.2197 40.1361 5.2197C39.7365 5.2197 39.406 5.35377 39.1445 5.61894C38.8831 5.88411 38.7539 6.2178 38.7539 6.61109V16.0409C38.7539 16.4372 38.8831 16.7679 39.1445 17.0331C39.406 17.2982 39.7365 17.4323 40.1361 17.4323C40.5358 17.4323 40.8663 17.2982 41.1277 17.0331C41.3891 16.7679 41.5183 16.4372 41.5183 16.0409V10.6303C41.5183 10.0821 41.5905 9.6054 41.7377 9.20318C41.8849 8.80095 42.0742 8.4613 42.3146 8.1872C42.552 7.91309 42.8134 7.71049 43.0989 7.58237C43.3813 7.45426 43.6638 7.38871 43.9402 7.38871C44.2467 7.38871 44.5202 7.44234 44.7575 7.5496C44.9949 7.65686 45.2594 7.71049 45.5508 7.71049C45.8573 7.71049 46.1428 7.60025 46.4042 7.37977C46.6656 7.1593 46.7948 6.79879 46.7948 6.29527C46.7948 5.94668 46.6386 5.6398 46.3231 5.38059L46.3201 5.37463Z"
          fill="#EC8D29"
        />
        <path
          d="M56.9718 5.21387C56.5722 5.21387 56.2416 5.34794 55.9802 5.61311C55.7188 5.87828 55.5896 6.21197 55.5896 6.60525V12.4509C55.5896 13.0289 55.4904 13.5205 55.2891 13.9227C55.0908 14.3249 54.8053 14.6318 54.4357 14.8374C54.0661 15.043 53.6154 15.1443 53.0776 15.1443C52.2933 15.1443 51.6833 14.9417 51.2476 14.5394C50.8089 14.1372 50.5926 13.44 50.5926 12.4509V6.60525C50.5926 6.20899 50.4634 5.87828 50.2019 5.61311C49.9405 5.34794 49.61 5.21387 49.2103 5.21387C48.8107 5.21387 48.4802 5.34794 48.2188 5.61311C47.9573 5.87828 47.8281 6.21197 47.8281 6.60525V13.2732C47.8281 14.1253 47.9964 14.8821 48.3359 15.5465C48.6725 16.2079 49.1442 16.7263 49.7512 17.0988C50.3582 17.4712 51.0673 17.6589 51.8816 17.6589C52.5727 17.6589 53.1857 17.5755 53.7236 17.4086C54.2615 17.2418 54.7242 16.9945 55.1178 16.6667C55.2981 16.5148 55.4604 16.342 55.6136 16.1543V16.4045C55.6136 17.1822 55.4934 17.793 55.2561 18.2428C55.0187 18.6927 54.6641 19.0115 54.1953 19.2022C53.7266 19.3929 53.1407 19.4882 52.4345 19.4882C51.9117 19.4882 51.4519 19.4078 51.0523 19.2469C50.6527 19.086 50.3311 18.9698 50.0847 18.8924C49.7452 18.7702 49.4237 18.7911 49.1172 18.949C48.8107 19.1099 48.5883 19.4078 48.4501 19.8517C48.342 20.1854 48.378 20.5012 48.5553 20.7992C48.7326 21.0971 49.0511 21.3444 49.5108 21.5411C49.8624 21.693 50.3161 21.8062 50.869 21.8837C51.4219 21.9582 51.9447 21.9969 52.4345 21.9969C53.5403 21.9969 54.5379 21.8152 55.4273 21.4487C56.3168 21.0822 57.0289 20.4863 57.5577 19.6551C58.0866 18.8238 58.351 17.7095 58.351 16.3092V6.60823C58.351 6.21197 58.2188 5.88126 57.9604 5.61609C57.699 5.35092 57.3684 5.21685 56.9688 5.21685L56.9718 5.21387Z"
          fill="#EC8D29"
        />
        <path
          d="M66.0212 7.95484C66.3908 7.95484 66.6973 7.83865 66.9407 7.60029C67.1871 7.36492 67.3103 7.06996 67.3103 6.72137C67.3103 6.37277 67.1871 6.051 66.9407 5.80669C66.6943 5.56237 66.3878 5.44022 66.0212 5.44022H64.3866V3.97732C64.3866 3.58106 64.2604 3.25035 64.008 2.98518C63.7556 2.72001 63.4281 2.58594 63.0284 2.58594C62.6288 2.58594 62.2952 2.72001 62.0278 2.98518C61.7604 3.25035 61.6252 3.58404 61.6252 3.97732V5.44022H60.5645C60.1949 5.44022 59.8884 5.55939 59.645 5.79477C59.3986 6.03014 59.2754 6.3251 59.2754 6.6737C59.2754 7.02229 59.3986 7.34406 59.645 7.58838C59.8914 7.83269 60.1979 7.95484 60.5645 7.95484H61.6252V14.2563C61.6252 15.0012 61.7393 15.5881 61.9707 16.0142C62.2021 16.4402 62.4875 16.756 62.8331 16.9616C63.1787 17.1672 63.5482 17.2983 63.9389 17.3489C64.3295 17.4026 64.6871 17.4294 65.0086 17.4294C65.5465 17.4294 66.0483 17.2923 66.517 17.0182C66.9858 16.7441 67.2201 16.3955 67.2201 15.9665C67.2201 15.6149 67.133 15.3438 66.9557 15.1442C66.7784 14.9475 66.5831 14.8462 66.3698 14.8462C66.1685 14.8462 65.9942 14.879 65.8409 14.9505C65.6877 15.019 65.5194 15.0518 65.3331 15.0518C65.1648 15.0518 65.0056 15.022 64.8614 14.9594C64.7141 14.8999 64.5999 14.7986 64.5158 14.6615C64.4317 14.5245 64.3896 14.3487 64.3896 14.1371V7.94888H66.0242L66.0212 7.95484Z"
          fill="#EC8D29"
        />
        <path
          d="M79.0118 4.98754C78.6121 4.98754 78.2816 5.12161 78.0202 5.38678C77.7588 5.65493 77.6296 5.98564 77.6296 6.37892V6.41766C77.4913 6.28954 77.3501 6.16143 77.1818 6.03629C76.7762 5.73239 76.2954 5.48212 75.7425 5.2825C75.1896 5.08586 74.5827 4.98456 73.9216 4.98456C72.894 4.98456 71.9565 5.25569 71.1121 5.79496C70.2677 6.33721 69.5947 7.08207 69.0959 8.0325C68.5971 8.98293 68.3477 10.0853 68.3477 11.3337C68.3477 12.5821 68.5971 13.6606 69.0959 14.6111C69.5947 15.5615 70.2737 16.3093 71.1331 16.8486C71.9925 17.3908 72.9541 17.659 74.0118 17.659C74.5947 17.659 75.1356 17.5637 75.6344 17.373C76.1332 17.1823 76.5659 16.9499 76.9355 16.6758C77.2149 16.4672 77.4373 16.2587 77.6266 16.0441C77.6266 16.4374 77.7588 16.7652 78.0172 17.0303C78.2786 17.2955 78.6091 17.4296 79.0088 17.4296C79.4084 17.4296 79.7389 17.2955 80.0004 17.0303C80.2618 16.7652 80.391 16.4345 80.391 16.0382V6.37892C80.391 5.96777 80.2588 5.63407 80.0004 5.37486C79.7389 5.11565 79.4084 4.98754 79.0088 4.98754H79.0118ZM77.3201 13.2763C77.0346 13.8543 76.641 14.3131 76.1332 14.6468C75.6253 14.9835 75.0364 15.1503 74.3603 15.1503C73.6842 15.1503 73.1163 14.9835 72.6115 14.6468C72.1037 14.3131 71.7101 13.8543 71.4246 13.2763C71.1391 12.6983 70.9979 12.0517 70.9979 11.3367C70.9979 10.6216 71.1391 9.95124 71.4246 9.37324C71.707 8.79523 72.1037 8.33938 72.6115 8.00271C73.1193 7.66901 73.7023 7.49919 74.3603 7.49919C75.0184 7.49919 75.6283 7.66603 76.1332 8.00271C76.641 8.33938 77.0346 8.79523 77.3201 9.37324C77.6055 9.95124 77.7467 10.6067 77.7467 11.3367C77.7467 12.0666 77.6055 12.6983 77.3201 13.2763Z"
          fill="#EC8D29"
        />
        <path
          d="M83.6869 5.21387C83.2873 5.21387 82.9567 5.34794 82.6953 5.61311C82.4339 5.87828 82.3047 6.21197 82.3047 6.60525V16.0351C82.3047 16.4314 82.4339 16.7621 82.6953 17.0272C82.9567 17.2924 83.2873 17.4265 83.6869 17.4265C84.0866 17.4265 84.4171 17.2924 84.6785 17.0272C84.9399 16.7621 85.0691 16.4314 85.0691 16.0351V6.60525C85.0691 6.20899 84.9399 5.87828 84.6785 5.61311C84.4171 5.34794 84.0866 5.21387 83.6869 5.21387Z"
          fill="#EC8D29"
        />
        <path
          d="M88.6161 0.533203C88.2165 0.533203 87.883 0.667277 87.6155 0.932445C87.3481 1.19761 87.2129 1.53131 87.2129 1.92459V16.0381C87.2129 16.4343 87.3421 16.7651 87.6035 17.0302C87.8649 17.2954 88.1865 17.4295 88.5711 17.4295C88.9557 17.4295 89.3043 17.2954 89.5717 17.0302C89.8391 16.7651 89.9743 16.4343 89.9743 16.0381V1.92757C89.9743 1.53131 89.8481 1.20059 89.5927 0.935424C89.3373 0.670256 89.0128 0.536183 88.6131 0.536183L88.6161 0.533203Z"
          fill="#EC8D29"
        />
        <path
          d="M103.159 8.03525C102.601 7.08481 101.846 6.33698 100.903 5.79771C99.9593 5.25843 98.9016 4.9873 97.7358 4.9873C96.5699 4.9873 95.5122 5.25843 94.5687 5.79771C93.6252 6.33996 92.8709 7.08481 92.3121 8.03525C91.7501 8.98568 91.4707 10.0881 91.4707 11.3364C91.4707 12.5848 91.7501 13.6842 92.3121 14.6347C92.8709 15.5851 93.6252 16.327 94.5687 16.8603C95.5122 17.3936 96.5699 17.6588 97.7358 17.6588C98.9016 17.6588 99.9593 17.3936 100.903 16.8603C101.846 16.327 102.601 15.5851 103.159 14.6347C103.718 13.6842 104.001 12.5848 104.001 11.3364C104.001 10.0881 103.721 8.98866 103.159 8.03525ZM100.765 13.3326C100.449 13.9047 100.028 14.3486 99.4966 14.6674C98.9677 14.9862 98.3788 15.1471 97.7358 15.1471C97.0927 15.1471 96.5038 14.9862 95.9749 14.6674C95.4461 14.3486 95.0224 13.9017 94.7069 13.3326C94.3914 12.7606 94.2351 12.0962 94.2351 11.3335C94.2351 10.5707 94.3914 9.88248 94.7069 9.31342C95.0224 8.74137 95.4431 8.29744 95.9749 7.97864C96.5038 7.65984 97.0927 7.49895 97.7358 7.49895C98.3788 7.49895 98.9677 7.65984 99.4966 7.97864C100.025 8.29744 100.449 8.74435 100.765 9.31342C101.08 9.88546 101.236 10.5588 101.236 11.3335C101.236 12.1081 101.08 12.7606 100.765 13.3326Z"
          fill="#EC8D29"
        />
        <path
          d="M84.1653 0.569064C82.762 0.235369 81.53 1.45693 81.8666 2.84832C82.0258 3.50379 82.5667 4.04008 83.2247 4.19799C84.628 4.53466 85.863 3.31012 85.5234 1.91874C85.3642 1.26327 84.8233 0.726973 84.1622 0.572043L84.1653 0.569064Z"
          fill="#EC8D29"
        />
      </g>
      <defs>
        <clipPath id="clip0_2152_3421">
          <rect width="104" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
