import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    validateRecaptchaToken: builder.mutation<any, string>({
      query: token => ({
        url: `/public/recaptcha/validate?token=${token}`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useValidateRecaptchaTokenMutation } = extendedApi;
