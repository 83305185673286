import { useState } from 'react';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { NoImageIcon } from 'shared/icons/noImageIcon';
import { cGrey200 } from 'shared/utils/styleCommon';
import { Skeleton } from '../skeleton/skeleton';

export enum ThumbnailSize {
  THUMBNAIL_156X156 = 'THUMBNAIL_156X156',
  THUMBNAIL_200X200 = 'THUMBNAIL_200X200',
  THUMBNAIL_360X360 = 'THUMBNAIL_360X360'
}

type Props = {
  filename?: string;
  directory?: string;
  height?: string;
  width?: string;
  classNames?: string;
  styles?: React.CSSProperties;
  skeleton?: boolean;
  noImagePlaceholder?: boolean;
  thumbnail?: ThumbnailSize;
  onLoad?: () => void;
};

export const CustomImage = (props: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  // other
  const { blob, isError } = useFetchBlob(props.filename, props.directory, props.thumbnail);

  if ((!props.filename && props.noImagePlaceholder) || isError) {
    return (
      <div
        style={{
          outline: `2px solid ${cGrey200}`,
          borderRadius: '10px',
          ...props.styles,
          width: props.width,
          height: props.height
        }}
        className={`d-flex align-items-center justify-content-center ${props.classNames}`}
      >
        <NoImageIcon />
      </div>
    );
  }

  return (
    <>
      <img
        src={blob}
        alt=""
        onLoad={() => {
          props.onLoad?.();
          setIsLoaded(true);
        }}
        style={{
          display: isLoaded ? 'block' : 'none',
          ...props.styles,
          width: !isLoaded ? 0 : props.width,
          height: !isLoaded ? 0 : props.height
        }}
        className={props.classNames}
      />
      {!isLoaded && props.skeleton && (
        <div>
          <Skeleton height={props.height ?? '100%'} width={props.width ?? '100%'} />
        </div>
      )}
    </>
  );
};
