import _ from 'lodash';
import { useEffect, useState } from 'react';
import { isTokenPackageProduct } from 'shared/types/product';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { extendedApi as productApi } from 'store/api/endpoints/productEndpoints';
import { extendedApi as transactionsApi } from 'store/api/endpoints/transactionEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPaymentState } from 'store/slices/paymentSlice';
import { GeneratorPaymentSuccessModal } from '../generatorPaymentSuccessModal';
import { PaymentErrorModal } from '../paymentErrorModal';

type Props = {
  storyType: 'custom' | 'template';
  onAcceptGeneratorPaymentSuccess: () => void;
};

export const StoryCreatorPaymentNotificationHandler = (props: Props) => {
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);
  const [isPaymentErrorModalOpen, setIsPaymentErrorModalOpen] = useState(false);
  const [tokens, setTokens] = useState(0);
  // redux
  const awaitingNotification = useAppSelector(state => state.paymentSlice.awaitingNotification);
  const modalHandler = useAppSelector(state => state.paymentSlice.modalHandler);

  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();

  useEffect(() => {
    if (!_.isNil(awaitingNotification) && modalHandler === 'STORY_CREATOR') {
      dispatch(resetPaymentState());
      dispatch(transactionsApi.endpoints.getTransactionById.initiate({ transactionId: awaitingNotification.transactionId }))
        .unwrap()
        .then(transaction => {
          dispatch(productApi.endpoints.getProductById.initiate(transaction.productId))
            .unwrap()
            .then(product => {
              if (isTokenPackageProduct(product)) {
                if (awaitingNotification.status === 'INFO') {
                  refetchUser();
                  setTokens(product.properties.tokens);
                  setIsPaymentSuccessModalOpen(true);
                } else {
                  setIsPaymentErrorModalOpen(true);
                }
              }
            })
            .catch(e => console.error(e));
        })
        .catch(e => console.error(e));
    } else if (!_.isNil(awaitingNotification) && modalHandler === null) {
      dispatch(resetPaymentState());
      refetchUser();
    }
  }, [dispatch, awaitingNotification, refetchUser, modalHandler]);

  return (
    <>
      <GeneratorPaymentSuccessModal
        open={isPaymentSuccessModalOpen}
        storyType={props.storyType}
        tokens={tokens}
        onAccept={() => props.onAcceptGeneratorPaymentSuccess()}
        onClose={() => setIsPaymentSuccessModalOpen(false)}
      />
      <PaymentErrorModal open={isPaymentErrorModalOpen} onClose={() => setIsPaymentErrorModalOpen(false)} />
    </>
  );
};
