import { ConfirmationDialog } from 'features/ui/dialogs/confirmationDialog';
import { CustomImage } from 'features/ui/image/customImage';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SubscriptionType } from 'shared/types/users';
import { cGrey200, cRed, cSuccess } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';

export interface PlanItem {
  isIncluded: boolean;
  title: string;
  description: string;
}

export interface Plan {
  image: string;
  title: string;
  description: string;
  planItems: PlanItem[];
  price?: string;
  subscriptionType: SubscriptionType;
}

type Props = {
  plan: Plan;
};

export const SubscriptionPlan = (props: Props) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  // redux
  const userSubscriptionType = useAppSelector(state => state.authSlice.loggedUser)?.subscription?.type;

  const lastPlanItemIdx = props.plan.planItems.length - 1;

  const cancelSubscription = () => {};

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Zakończyć subskrypcje?'}
        content={'Jeżeli zakończysz subskrypcje teraz będziesz mógł korzystać z pełnej wersji jeszcze do 28.08.'}
        onAccept={() => {}}
        onAcceptText={'Tak'}
        onCancelText={'Anuluj'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />

      <CustomImage directory="common" filename={props.plan.image} width="100%" />
      <div className="mx-3">
        <Typography variant="h1" classNames="pt-4">
          {props.plan.title}
        </Typography>
        <Typography variant="description">{props.plan.description}</Typography>

        <div className="w-100 my-4" style={{ borderBottom: `1px solid ${cGrey200}` }}></div>

        <Typography variant="h4" classNames="pb-3">
          Plan obejmuje
        </Typography>

        {props.plan.planItems.map((pi, idx) => (
          <div
            style={{ borderBottom: idx !== lastPlanItemIdx ? `1px solid ${cGrey200}` : 'none' }}
            className="d-flex gap-3 align-items-center py-2"
            key={idx}
          >
            <i className="bi bi-check-circle-fill" style={{ color: pi.isIncluded ? cSuccess : cGrey200 }}></i>
            <div>
              <Typography variant="h4">{pi.title}</Typography>
              <Typography variant="description">{pi.description}</Typography>
            </div>
          </div>
        ))}

        {userSubscriptionType === SubscriptionType.FREE && props.plan.subscriptionType !== SubscriptionType.FREE && (
          <JustifyCenter classNames="pt-4">
            <Button variant="primary">Subskrybuj za {props.plan.price} zł</Button>
          </JustifyCenter>
        )}

        {userSubscriptionType === props.plan.subscriptionType && props.plan.subscriptionType !== SubscriptionType.FREE && (
          <div onClick={cancelSubscription} className="py-5">
            <Typography variant="h4">Zakończ subskrypcje</Typography>
            <Typography variant="description-sm" classNames="pt-3">
              Przejście na darmową wersje znacząco ograniczy dostępne opcje.
            </Typography>
            <div className="pt-1" onClick={() => setIsConfirmationDialogOpen(true)} role="button">
              <Typography variant="description-sm" styles={{ color: cRed, textDecoration: 'underline' }}>
                Chcę korzystać z ograniczonej funkcjonalności apliakcji i zakończyć subskrypcje
              </Typography>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
