import { Divider } from 'features/ui/divider/divider';
import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { pxToRem } from 'shared/utils/commonUtils';
import { cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useUpdateUserStoryInfoMutation } from 'store/api/endpoints/userStoryInfoEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';

export const StoryRating = () => {
  const [selectedRating, setSelectedRating] = useState<1 | 2 | 3>(2);
  const [comment, setComment] = useState('');
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const [updateUserStoryInfo] = useUpdateUserStoryInfoMutation();
  // other
  const { storyId } = useParams();

  const rateStory = () => {
    const userStoryInfo = loggedUser?.userStoryInfo.find(usi => usi.storyId === storyId);
    if (userStoryInfo && loggedUser) {
      updateUserStoryInfo({ userId: loggedUser.id, userStoryInfo: { ...userStoryInfo, rating: { rating: selectedRating, comment } } })
        .then(() => {
          refetchUser();
          router.navigate(paths.root);
        })
        .catch(() => {
          dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać oceny.' }));
        });
    }
  };

  const getCommentLabel = () => {
    switch (selectedRating) {
      case 1:
        return 'Co Ci się nie podobało?';
      case 2:
        return 'Co możemy poprawić?';
      case 3:
        return 'Co Ci się podobało?';
    }
  };

  return (
    <RouteLayout
      bottomActions={[
        {
          label: 'Zapisz ocenę',
          action: rateStory
        }
      ]}
    >
      <div className="my-5 mx-3 d-flex flex-column justify-content-between">
        <Typography classNames="text-center pb-5" variant="h1">
          Jak bardzo podobała Ci się bajka
        </Typography>
        <div className="d-flex align-items-start justify-content-center gap-4">
          <div
            role="button"
            onClick={() => setSelectedRating(1)}
            className="d-flex flex-column align-items-center gap-2"
            style={{ maxWidth: pxToRem(78) }}
          >
            <CustomImage
              directory="common"
              filename="rating_1.png"
              styles={{ borderRadius: '50%', outline: selectedRating === 1 ? `2px solid ${cPrimary}` : 'none' }}
              width={pxToRem(78)}
              height={pxToRem(78)}
              thumbnail={ThumbnailSize.THUMBNAIL_156X156}
              skeleton
            />
            <Typography
              classNames="text-center pt-1"
              styles={{ color: selectedRating === 1 ? cPrimary : cSecondary }}
              variant="description-sm"
            >
              Nie podobała
            </Typography>
          </div>
          <div
            onClick={() => setSelectedRating(2)}
            className="d-flex flex-column align-items-center gap-2"
            role="button"
            style={{ maxWidth: pxToRem(78) }}
          >
            <CustomImage
              directory="common"
              filename="rating_2.png"
              styles={{ borderRadius: '50%', outline: selectedRating === 2 ? `2px solid ${cPrimary}` : 'none' }}
              width={pxToRem(78)}
              height={pxToRem(78)}
              thumbnail={ThumbnailSize.THUMBNAIL_156X156}
              skeleton
            />
            <Typography
              classNames="text-center pt-1"
              styles={{ color: selectedRating === 2 ? cPrimary : cSecondary }}
              variant="description-sm"
            >
              Była w porządku
            </Typography>
          </div>
          <div
            onClick={() => setSelectedRating(3)}
            className="d-flex flex-column align-items-center gap-2"
            role="button"
            style={{ maxWidth: pxToRem(78) }}
          >
            <CustomImage
              directory="common"
              filename="rating_3.png"
              styles={{ borderRadius: '50%', outline: selectedRating === 3 ? `2px solid ${cPrimary}` : 'none' }}
              width={pxToRem(78)}
              height={pxToRem(78)}
              thumbnail={ThumbnailSize.THUMBNAIL_156X156}
              skeleton
            />
            <Typography classNames="text-center " styles={{ color: selectedRating === 3 ? cPrimary : cSecondary }} variant="description-sm">
              Bardzo się podobała
            </Typography>
          </div>
        </div>
        <Divider spacing={'my-4'} />
        <Typography variant="h4">Powiedz nam coś więcej</Typography>
        <Form.Group className="my-3">
          <Form.Label>{getCommentLabel()}</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="Opisz w killku słowach..." onChange={e => setComment(e.target.value)} />
        </Form.Group>
      </div>
    </RouteLayout>
  );
};
