export const GoogleIcon = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.0737305" width="30" height="30" rx="15" fill="#FDFDFD" />
      <g clip-path="url(#clip0_2831_6091)">
        <path
          d="M23.8 15.2822C23.8 14.6322 23.7417 14.0072 23.6333 13.4072H15V16.9572H19.9333C19.7167 18.0989 19.0667 19.0656 18.0917 19.7156V22.0239H21.0667C22.8 20.4239 23.8 18.0739 23.8 15.2822Z"
          fill="#4285F4"
        />
        <path
          d="M15 24.2404C17.475 24.2404 19.55 23.4237 21.0667 22.0237L18.0917 19.7154C17.275 20.2654 16.2333 20.5987 15 20.5987C12.6167 20.5987 10.5917 18.9904 9.86665 16.8237H6.81665V19.1904C8.32498 22.1821 11.4166 24.2404 15 24.2404Z"
          fill="#34A853"
        />
        <path
          d="M9.86659 16.8153C9.68325 16.2653 9.57492 15.682 9.57492 15.0737C9.57492 14.4653 9.68325 13.882 9.86659 13.332V10.9653H6.81659C6.19159 12.1987 5.83325 13.5903 5.83325 15.0737C5.83325 16.557 6.19159 17.9487 6.81659 19.182L9.19159 17.332L9.86659 16.8153Z"
          fill="#FBBC05"
        />
        <path
          d="M15 9.55723C16.35 9.55723 17.55 10.0239 18.5083 10.9239L21.1333 8.29889C19.5417 6.81556 17.475 5.90723 15 5.90723C11.4166 5.90723 8.32498 7.96556 6.81665 10.9656L9.86665 13.3322C10.5917 11.1656 12.6167 9.55723 15 9.55723Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_2831_6091">
          <rect width="20" height="20" fill="white" transform="translate(5 5.07373)" />
        </clipPath>
      </defs>
    </svg>
  );
};
