import { ConfirmationDialog } from 'features/ui/dialogs/confirmationDialog';
import { FormError } from 'features/ui/form/formError';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { Hero } from 'shared/types/users';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import {
  useCreateHeroMutation,
  useDeleteHeroMutation,
  useUpdateHeroMutation,
  useUploadHeroImageMutation
} from 'store/api/endpoints/heroEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { ImageEdit } from '../../ui/image-edit/imageEdit';
import { categories } from './categories';

interface HeroFormValues {
  name: string;
  category: string;
  subcategory: string;
}

type Props = {
  heroId?: string;
  backRoute?: string;
  onSaveCallback: (hero: Hero) => void;
  onDeleteCallback: (heroId: string) => void;
};

export const HeroCreator = (props: Props) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  const [uploadHeroImage, uploadHeroImageStatus] = useUploadHeroImageMutation();
  const [createHero, createHeroStatus] = useCreateHeroMutation();
  const [updateHero, updateHeroStatus] = useUpdateHeroMutation();
  const [deleteHero, deleteHeroStatus] = useDeleteHeroMutation();
  // other
  const hero = loggedUser?.heroes.find(h => h.id === props.heroId);
  const { blob: image } = useFetchBlob(hero?.image, loggedUser?.id);

  const {
    control,
    handleSubmit,
    reset: resetForm,
    unregister,
    watch
  } = useForm<HeroFormValues>({
    mode: 'onBlur'
  });

  useEffect(() => {
    if (hero) {
      resetForm({
        name: hero.name,
        category: hero.category,
        subcategory: hero.subcategory
      });
    }
  }, [hero, resetForm]);

  const onSubmit = (data: HeroFormValues) => {
    if (!loggedUser?.id) return;

    const heroData = {
      name: data.name,
      category: data.category,
      subcategory: data.subcategory
    };

    const request = hero
      ? updateHero({ userId: loggedUser.id, hero: { id: hero.id, ...heroData } })
      : createHero({ userId: loggedUser.id, hero: heroData });

    request
      .unwrap()
      .then(async hero => {
        if (avatar) {
          try {
            await uploadHeroImage({ userId: loggedUser.id, heroId: hero.id, file: avatar }).unwrap();
          } catch (error) {
            dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać awataru.' }));
          }
        }
        refetchUser()
          .unwrap()
          .then(() => props.onSaveCallback(hero))
          .catch(() =>
            dispatch(addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika, prosimy o odświeżenie strony' }))
          );
      })
      .catch(() => {
        dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać bohatera.' }));
      });
  };

  const selectedCategory = watch('category');

  let categoryControl: JSX.Element | null = null;

  if (!selectedCategory) {
    categoryControl = <></>;
  } else if (selectedCategory === 'Inne') {
    categoryControl = (
      <Form.Group className="pb-5" controlId="heroCreatorForm.subcategory">
        <Form.Label>Podkategoria</Form.Label>
        <Controller name="subcategory" control={control} render={({ field }) => <Form.Control {...field} />} />
      </Form.Group>
    );
  } else {
    categoryControl = (
      <Form.Group className="pb-5" controlId="heroCreatorForm.subcategory">
        <Form.Label>Podkategoria *</Form.Label>
        <Controller
          name="subcategory"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Form.Select {...field} isInvalid={fieldState.invalid} defaultValue={'-1'}>
                <option disabled hidden value={'-1'}>
                  Wybierz podkategorię
                </option>
                {categories
                  .find(c => c.category === selectedCategory)
                  ?.subcategories.map(sc => (
                    <option value={sc} key={sc}>
                      {sc}
                    </option>
                  ))}
              </Form.Select>
              {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
            </>
          )}
          rules={{ required: validationMessage.required }}
        />
      </Form.Group>
    );
  }

  const isLoading =
    createHeroStatus.isLoading || updateHeroStatus.isLoading || deleteHeroStatus.isLoading || uploadHeroImageStatus.isLoading;

  return (
    <RouteLayout
      backRoute={props.backRoute}
      actions={
        props.heroId
          ? [
              {
                action: () => setIsConfirmationDialogOpen(true),
                label: 'Usuń'
              }
            ]
          : []
      }
      bottomActions={[
        {
          label: 'Zapisz',
          submittable: true,
          formId: 'heroCreatorForm',
          disabled: isLoading
        }
      ]}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usunąć bohatera?'}
        content={'Czy na pewno chcesz usunąć bohatera?'}
        onAccept={() => {
          if (loggedUser?.id && props.heroId) {
            deleteHero({ userId: loggedUser.id, heroId: props.heroId })
              .unwrap()
              .then(() => {
                refetchUser()
                  .unwrap()
                  .then(() => {
                    props.heroId && props.onDeleteCallback(props.heroId);
                  })
                  .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się usunąć bohatera' })));
              });
          }
        }}
        onAcceptText={'Tak'}
        onCancelText={'Anuluj'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <Loadable loading={isLoading}>
        <div className="mx-3">
          <div className="pt-5 pb-5">
            <Typography variant="h1">Dodaj bohatera</Typography>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate id="heroCreatorForm">
            <JustifyCenter>
              <ImageEdit onFileChangeCallback={setAvatar} file={image} />
            </JustifyCenter>

            <Form.Group className="mb-3" controlId="heroCreatorForm.name">
              <Form.Label>Imię *</Form.Label>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control {...field} isInvalid={fieldState.invalid} required />
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="heroCreatorForm.category">
              <Form.Label>Kategoria *</Form.Label>
              <Controller
                name="category"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Select
                      {...field}
                      isInvalid={fieldState.invalid}
                      defaultValue={'-1'}
                      onChange={e => {
                        if (e.target.value === 'Inne') {
                          unregister('subcategory');
                        }
                        field.onChange(e);
                      }}
                    >
                      <option disabled hidden value="-1">
                        Wybierz kategorię
                      </option>
                      {categories.map(c => (
                        <option value={c.category} key={c.category}>
                          {c.category}
                        </option>
                      ))}
                    </Form.Select>
                    {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>

            {categoryControl}
          </Form>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
