import { Typography } from 'features/ui/typography/typography';
import homeHeaderSpiralImg from '../../shared/images/home-header-spiral.png';
import storytailo from '../../shared/images/storytailo.svg';

type Props = {
  headerText: string;
};

export const HeaderStorytailo = (props: Props) => (
  <div className="d-flex flex-column align-items-center" style={{ position: 'relative', overflow: 'hidden' }}>
    <img src={homeHeaderSpiralImg} alt="" style={{ position: 'absolute', bottom: 0 }} />
    <img src={storytailo} alt="" width="242px" style={{ paddingTop: 52, paddingBottom: 30 }} />
    <Typography variant="h1">{props.headerText}</Typography>
  </div>
);
