import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Search } from 'features/ui/search-story/search';
import { HorizontalStoryPreview } from 'features/ui/story-preview/horizontalStoryPreview';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { StoryPreview } from 'shared/types/story';
import { pxToRem } from 'shared/utils/commonUtils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { Operator, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import { useAppSelector } from 'store/hooks';
import { Typography } from '../ui/typography/typography';

export const Library = () => {
  const [searchText, setSearchText] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();

  const [allStories, setAllStories] = useState<StoryPreview[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // rtk
  const [searchStories, { isLoading }] = useSearchStoriesMutation();

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  const storiesInLibrary = useMemo(() => {
    return loggedUser?.userStoryInfo.map(s => s.storyId);
  }, [loggedUser]);

  const requestStories = useCallback(
    (page: number) => {
      if (storiesInLibrary) {
        searchStories({
          page: page,
          size: 10,
          searchRequest: {
            operator: Operator.AND,
            searchCriteriaGroups: [
              {
                groupOperator: Operator.AND,
                searchCriteria: [
                  {
                    filterKey: 'id',
                    values: storiesInLibrary,
                    operation: SearchOperation.INCLUDES
                  }
                ]
              }
            ]
          }
        })
          .unwrap()
          .then(result => {
            setAllStories(prev => (page > 0 ? [...prev, ...result.content] : result.content));
            setTotalPages(result.totalPages);
          })
          .catch(() => {
            setAllStories([]);
            setTotalPages(0);
          });
      }
    },
    [storiesInLibrary, searchStories]
  );

  useEffect(() => {
    if (_.isEmpty(searchText)) {
      requestStories(0);
    }
  }, [requestStories, searchText]);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    requestStories(nextPage);
  };

  const infiniteScrollStories = allStories?.map(s => ({ ...s, id: s.storyId, label: s.title }));

  return (
    <RouteLayout>
      <div className="my-5 mx-3">
        <Typography variant="h1" classNames="pb-3">
          Przeczytane bajki
        </Typography>

        {storiesInLibrary && !_.isEmpty(storiesInLibrary) && (
          <Search
            onSearchFocusChange={setIsSearchFocused}
            onSearchTextChange={setSearchText}
            baseSearchCriteria={[{ filterKey: 'id', values: storiesInLibrary, operation: SearchOperation.INCLUDES }]}
          />
        )}

        {!isSearchFocused && !searchText && (
          <InfiniteScroll
            divider
            list={infiniteScrollStories}
            isLoading={isLoading}
            hasMore={currentPage !== totalPages - 1}
            loadMore={loadMore}
            renderListItem={element => <HorizontalStoryPreview story={element} />}
            renderEmptyListMessage={() => (
              <>
                <CustomImage directory="common" filename="library.png" width={pxToRem(62)} height={pxToRem(62)} />
                <Typography variant="h4">Przeczytane bajki</Typography>
                <Typography variant="description">Jeszcze nie przeczytałeś żadnej bajki.</Typography>
              </>
            )}
            renderLoadingRowItem={() => (
              <div className="d-flex justify-content-center p-4">
                <Typography variant="h4">Ładowanie...</Typography>
              </div>
            )}
          />
        )}
      </div>
    </RouteLayout>
  );
};
