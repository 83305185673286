import { CustomImage } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { StorySeries } from 'shared/types/series';
import { pxToRem } from 'shared/utils/commonUtils';
import { borderMd, cSecondary } from 'shared/utils/styleCommon';

type Props = {
  storySeries: StorySeries;
};

export const SeriesBanner = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div style={{ position: 'relative', borderRadius: borderMd, width: pxToRem(336), height: pxToRem(230), zIndex: 0, overflow: 'hidden' }}>
      <CustomImage
        directory="common"
        filename={props.storySeries.image}
        styles={{ position: 'absolute', borderRadius: borderMd, zIndex: 1, right: 0 }}
        height={pxToRem(230)}
      />
      <CustomImage
        directory="common"
        filename="story_series_banner.png"
        styles={{ position: 'absolute', height: pxToRem(230), zIndex: 2, left: 0 }}
        height={pxToRem(230)}
        skeleton
      />
      <CustomImage directory="common" filename="spiral.png" styles={{ position: 'absolute', zIndex: 3, right: 0, bottom: 0 }} />

      <div
        className="d-flex flex-column justify-content-between align-items-start h-100 p-4"
        style={{ position: 'absolute', zIndex: 4, width: '70%' }}
      >
        <div className="d-flex flex-column gap-2">
          <Typography variant="description-sm" classNames="pb-2" styles={{ color: cSecondary }}>
            Seria bajek
          </Typography>
          <Typography variant="h4">{props.storySeries.title}</Typography>
        </div>
        <Button className="shorter" onClick={() => navigate(paths.series + `/${props.storySeries.id}`)}>
          Czytaj teraz
        </Button>
      </div>
    </div>
  );
};
