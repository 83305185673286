import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Cropper, { Area } from 'react-easy-crop';
import { EditIcon } from 'shared/icons/editIcon';
import { PersonOutlineIcon } from 'shared/icons/personOutlineIcon';
import { pxToRem } from 'shared/utils/commonUtils';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { AVATAR_SIZE } from '../../user-profile/utils';
import { Typography } from '../typography/typography';
import { getCroppedImg } from './croppingUtils';

type Props = {
  file?: string;
  onFileChangeCallback: (file: File) => void;
};

export const ImageEdit = (props: Props) => {
  const [objectUrl, setObjectUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isCroppingModalOpen, setIsCroppingModalOpen] = useState(false);
  // redux
  const dispatch = useAppDispatch();

  useEffect(() => {
    props.file && setObjectUrl(props.file);
  }, [props.file]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files?.[0];
    if (image) {
      const selectableMaxFileSize = 1024 * 1024 * 15; // 15 Megabytes
      if (image.size > selectableMaxFileSize) {
        dispatch(addAlert({ color: 'danger', text: 'Przekroczyłeś maksymalny limit wielkości pliku, który wynosi 15 MB.' }));
        return;
      }
      props.onFileChangeCallback(image);

      setObjectUrl(URL.createObjectURL(image));
      setIsCroppingModalOpen(true);
    }
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    getCroppedImg(objectUrl as string, croppedAreaPixels as any, 0).then(file => {
      if (file) {
        setObjectUrl(URL.createObjectURL(file));
        props.onFileChangeCallback(file);
      }
    });
  };

  const onCloseCropping = () => {
    setIsCroppingModalOpen(false);
    setObjectUrl(props.file ?? null);
  };

  const onAcceptCropping = () => {
    showCroppedImage();
    setIsCroppingModalOpen(false);
  };

  const avatarSize = pxToRem(AVATAR_SIZE);

  return (
    <>
      <Modal fullscreen centered show={isCroppingModalOpen} onHide={onCloseCropping}>
        <Modal.Header closeButton>
          <Typography variant="h1" classNames="pb-3">
            Przytnij obraz
          </Typography>
        </Modal.Header>
        <Modal.Body className="p-4 d-flex flex-column">
          <div style={{ position: 'relative', width: '100%', flexGrow: 1 }}>
            <Cropper image={objectUrl ?? undefined} crop={crop} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center gap-3 pt-4">
            <Button className="shorter" variant="primary" onClick={onAcceptCropping}>
              Zatwierdź
            </Button>
            <Button className="shorter" variant="outline-primary" onClick={onCloseCropping}>
              Anuluj
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <label htmlFor="file-upload" role="button">
        {objectUrl ? (
          <div style={{ position: 'relative', width: avatarSize, height: avatarSize, pointerEvents: 'none' }}>
            <img src={objectUrl} style={{ borderRadius: '50%', width: avatarSize, height: avatarSize }} alt="img" />
            <div style={{ position: 'absolute', top: -2, right: -2 }}>
              <EditIcon />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: 'relative',
              width: avatarSize,
              height: avatarSize,
              borderRadius: '50%',
              border: `2px solid rgba(236, 141, 41, 0.2)`,
              pointerEvents: 'none'
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <PersonOutlineIcon />
            <div style={{ position: 'absolute', top: -2, right: -2 }}>
              <EditIcon />
            </div>
          </div>
        )}
      </label>
      <input id="file-upload" type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} onChange={handleFileChange} />
    </>
  );
};
