import { format, parseISO } from 'date-fns';
import { Typography } from 'features/ui/typography/typography';
import { TokenTrack } from 'shared/types/tokenTrack';
import { cGrey600, cPrimary, cSecondary, cWhite } from 'shared/utils/styleCommon';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';

type Props = {
  tokenTrack: TokenTrack;
};

export const PaymentHistoryItem = (props: Props) => (
  <div key={props.tokenTrack.id}>
    <div className="d-flex align-items-center gap-2">
      <div style={{ width: 100, height: 100, position: 'relative' }} className="d-flex justify-content-center align-items-center">
        <div style={{ width: 50, height: 50 }}>
          <img src={tokenIcon} alt="" width={'100%'} />
        </div>

        <div
          style={{
            width: 16,
            height: 16,
            backgroundColor: props.tokenTrack.amount < 0 ? cPrimary : cSecondary,
            color: cWhite,
            position: 'absolute',
            borderRadius: 5,
            bottom: 16
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>{props.tokenTrack.amount < 0 ? '-' : '+'}</div>
        </div>
      </div>

      <div className="d-flex flex-column ">
        <Typography variant="h5">{props.tokenTrack.amount}</Typography>
        <Typography variant="description" styles={{ color: cGrey600 }}>
          {format(parseISO(props.tokenTrack.createdDate), 'yyyy.MM.dd')} | {props.tokenTrack.description}
        </Typography>
      </div>
    </div>
  </div>
);
