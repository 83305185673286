import { CustomImage } from 'features/ui/image/customImage';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { VerticalStoryPreview } from 'features/ui/story-preview/verticalStoryPreview';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfiniteScroll } from 'shared/hooks/useInfiniteScroll';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { StoryPreview } from 'shared/types/story';
import { pxToRem } from 'shared/utils/commonUtils';
import { useGetPredefinedTagsQuery } from 'store/api/endpoints/predefinedTagsEndpoint';
import { Operator, SearchOperation, useSearchStoriesMutation } from 'store/api/endpoints/storyEndpoints';
import { Typography } from '../../ui/typography/typography';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { addAlert } from 'store/slices/alertsSlice';
import { useAppDispatch } from 'store/hooks';

const defaultPredefinedTags: PredefinedTag[] = [];

export const CategoryStories = () => {
  const [allStories, setAllStories] = useState<StoryPreview[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  // redux
  const dispatch = useAppDispatch();
  // rtk
  const [searchStories, { isLoading }] = useSearchStoriesMutation();
  const { data: predefinedTags = defaultPredefinedTags } = useGetPredefinedTagsQuery();
  // other
  let { categoryName } = useParams();

  const image = predefinedTags.find(pt => pt.value === categoryName)?.image;

  const requestStories = useCallback(
    (page: number) => {
      if (categoryName) {
        searchStories({
          page: page,
          size: 10,
          searchRequest: {
            operator: Operator.AND,
            searchCriteriaGroups: [
              {
                groupOperator: Operator.AND,
                searchCriteria: [
                  {
                    filterKey: 'tags.value',
                    value: categoryName,
                    operation: SearchOperation.EQUAL
                  }
                ]
              }
            ]
          }
        })
          .unwrap()
          .then(result => {
            setAllStories(prev => (page > 0 ? [...prev, ...result.content] : result.content));
            setTotalPages(result.totalPages);
          })
          .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się pobrać bajek.' })));
      }
    },
    [categoryName, searchStories, dispatch]
  );

  useEffect(() => {
    requestStories(0);
  }, [requestStories]);

  const loadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    requestStories(nextPage);
  };

  const infiniteScrollStories = allStories?.map(s => ({ ...s, id: s.storyId, label: s.title }));

  const isNoResult = !isLoading && _.isEmpty(allStories);

  return (
    <RouteLayout backRoute={-1}>
      <div className="d-flex flex-column justify-content-start gap-2">
        <div style={{ position: 'relative' }}>
          <CustomImage directory="common" filename={image ?? 'category_stories.png'} width="100%" />
          <WhiteCloud />
        </div>
        <div className="mx-3">
          <Typography variant="h1" classNames="py-4">
            {categoryName}
          </Typography>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(auto-fill, minmax(${pxToRem(170)}, 1fr))`
            }}
          >
            <InfiniteScroll
              list={infiniteScrollStories}
              isLoading={isLoading}
              hasMore={currentPage !== totalPages - 1}
              loadMore={loadMore}
              renderListItem={element => <VerticalStoryPreview story={element} />}
              renderEmptyListMessage={() =>
                isNoResult ? (
                  <Typography variant="description" classNames="pb-5">
                    Brak bajek w tej kategorii...
                  </Typography>
                ) : (
                  <></>
                )
              }
              renderLoadingRowItem={() => (
                <div className="d-flex justify-content-center p-4">
                  <Typography variant="h4">Ładowanie...</Typography>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </RouteLayout>
  );
};
