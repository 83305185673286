import { CustomStoryParams, TemplateStoryParams } from 'shared/types/story';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    isGenerating: builder.query<boolean, void>({
      query: () => ({
        url: '/public/generating',
        method: HTTP_METHODS.GET
      })
    }),
    generateFromTemplate: builder.mutation<void, { templateStoryParams: TemplateStoryParams; templateId: string }>({
      query: data => ({
        url: `/stories/generate-from-template?templateId=${data.templateId}`,
        method: HTTP_METHODS.POST,
        body: data.templateStoryParams
      })
    }),
    generateCustomStory: builder.mutation<void, CustomStoryParams>({
      query: body => ({
        url: '/stories/generate-custom',
        method: HTTP_METHODS.POST,
        body: body
      })
    })
  }),
  overrideExisting: false
});

export const { useIsGeneratingQuery, useGenerateCustomStoryMutation, useGenerateFromTemplateMutation } = extendedApi;
