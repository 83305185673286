import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import alertsReducer from 'store/slices/alertsSlice';
import authReducer, { LOGOUT_ACTION_TYPE } from 'store/slices/authSlice';
import notificationsReducer from 'store/slices/notificationSlice';
import { apiMiddleware, apiReducer, apiReducerPath } from './api/api';
import { rtkQueryRequestMiddleware } from './middlewares/rtkQueryRequestMiddleware';
import websocketMiddleware from './middlewares/websocketMiddleware';
import paymentReducer from './slices/paymentSlice';
import storyCreatorReducer from './slices/storyCreatorSlice';
import storyGeneratorReducer from './slices/storyGeneratorSlice';
import { saveState } from './utils';

const reducers = {
  authSlice: authReducer,
  alertsSlice: alertsReducer,
  notificationsSlice: notificationsReducer,
  storyCreatorSlice: storyCreatorReducer,
  storyGeneratorSlice: storyGeneratorReducer,
  paymentSlice: paymentReducer,
  [apiReducerPath]: apiReducer
};

const combinedReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction): RootState => {
  if (action.type === LOGOUT_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([websocketMiddleware, apiMiddleware, rtkQueryRequestMiddleware])
});

store.subscribe(() => {
  saveState({
    authSlice: store.getState().authSlice,
    generatorSlice: store.getState().storyGeneratorSlice,
    paymentSlice: store.getState().paymentSlice,
    storyCreatorSlice: store.getState().storyCreatorSlice
  });
});

export type AppDispatch = typeof store.dispatch;
