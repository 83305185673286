import { PredefinedNarrator } from 'shared/types/narrator';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getPredefinedNarrators: builder.query<PredefinedNarrator[], void>({
      query: () => ({
        url: `/public/predefined-narrators`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetPredefinedNarratorsQuery } = extendedApi;
