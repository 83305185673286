export const loadState = <T>(sliceName: string): Partial<T> | undefined => {
  try {
    const serialState = localStorage.getItem(sliceName);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState) as Partial<T>;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    for (const key in state) {
      const serialState = JSON.stringify(state[key]);
      localStorage.setItem(key, serialState);
    }
  } catch (e) {
    console.error(e);
  }
};
