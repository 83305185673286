export const NavProfileIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9993 9.83317C21.9327 9.83317 23.4993 11.3998 23.4993 13.3332C23.4993 15.2665 21.9327 16.8332 19.9993 16.8332C18.066 16.8332 16.4993 15.2665 16.4993 13.3332C16.4993 11.3998 18.066 9.83317 19.9993 9.83317ZM19.9993 24.8332C24.9493 24.8332 30.166 27.2665 30.166 28.3332V30.1665H9.83268V28.3332C9.83268 27.2665 15.0493 24.8332 19.9993 24.8332ZM19.9993 6.6665C16.316 6.6665 13.3327 9.64984 13.3327 13.3332C13.3327 17.0165 16.316 19.9998 19.9993 19.9998C23.6827 19.9998 26.666 17.0165 26.666 13.3332C26.666 9.64984 23.6827 6.6665 19.9993 6.6665ZM19.9993 21.6665C15.5493 21.6665 6.66602 23.8998 6.66602 28.3332V33.3332H33.3327V28.3332C33.3327 23.8998 24.4493 21.6665 19.9993 21.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};
