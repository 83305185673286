import { Modal } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const DrawerModal = styled(Modal)`
  .modal-dialog {
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    width: 100%;
    max-width: 100%;
    animation: ${slideUp} 0.4s ease-out;
    transform: none;
    transition: transform 0.3s ease-out;
  }

  .modal-content {
    border-radius: 10px 10px 0 0;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
  }
`;

export const DrawerModalBody = styled(Modal.Body)`
  padding: 1rem 2rem;
`;
