type Props = {
  classNames?: string;
  children: React.ReactNode;
  styles?: React.CSSProperties;
};

export const JustifyCenter = (props: Props) => (
  <div className={`d-flex justify-content-center w-100 ${props.classNames}`} style={props.styles}>
    {props.children}
  </div>
);
