import { NoResultIcon } from 'shared/icons/noResultIcon';
import { Typography } from '../typography/typography';

export const NoResult = () => (
  <div className="d-flex flex-column gap-3 align-items-center py-3">
    <NoResultIcon />
    <Typography variant="h4">Nie udało się nic znaleźć</Typography>
    <Typography variant="description">Może spróbujesz wprowadzić inne słowa kluczowe?</Typography>
  </div>
);
