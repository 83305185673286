import { PRODUCTION_ENV } from 'index';
import { ReactNode, useEffect } from 'react';

interface Props {
  children: ReactNode;
}

export const Hotjar = (props: Props) => {
  useEffect(() => {
    if (process.env.NODE_ENV === PRODUCTION_ENV && process.env.REACT_APP_HOT_JAR_ID) {
      const script = document.createElement('script');

      script.innerHTML = `(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.REACT_APP_HOT_JAR_ID},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }, []);

  return <>{props.children}</>;
};
