import { Typography } from 'features/ui/typography/typography';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';

const StyledBadge = styled(Badge)`
  .badge {
    padding: 4px, 8px, 4px, 12px;
    display: flex;
    justify-content: space-between;
  }
`;

type Props = {
  cost: number;
};

export const StoryCostBadge = (props: Props) => {
  return (
    <StyledBadge bg="story-cost-badge">
      <Typography variant="description">
        Koszt bajki:{' '}
        <Typography variant="h4" inline>
          {props.cost}
        </Typography>
      </Typography>
      <div style={{ width: 24, height: 24 }}>
        <img src={tokenIcon} alt="" width={'100%'} />
      </div>
    </StyledBadge>
  );
};
