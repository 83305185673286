export const PersonOutlineIcon = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path
          d="M20.4974 9.83317C22.4307 9.83317 23.9974 11.3998 23.9974 13.3332C23.9974 15.2665 22.4307 16.8332 20.4974 16.8332C18.5641 16.8332 16.9974 15.2665 16.9974 13.3332C16.9974 11.3998 18.5641 9.83317 20.4974 9.83317ZM20.4974 24.8332C25.4474 24.8332 30.6641 27.2665 30.6641 28.3332V30.1665H10.3307V28.3332C10.3307 27.2665 15.5474 24.8332 20.4974 24.8332ZM20.4974 6.6665C16.8141 6.6665 13.8307 9.64984 13.8307 13.3332C13.8307 17.0165 16.8141 19.9998 20.4974 19.9998C24.1807 19.9998 27.1641 17.0165 27.1641 13.3332C27.1641 9.64984 24.1807 6.6665 20.4974 6.6665ZM20.4974 21.6665C16.0474 21.6665 7.16406 23.8998 7.16406 28.3332V33.3332H33.8307V28.3332C33.8307 23.8998 24.9474 21.6665 20.4974 21.6665Z"
          fill="#EC8D29"
        />
      </g>
    </svg>
  );
};
