import { Typography } from 'features/ui/typography/typography';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { cPrimary, cPrimaryLight, cWhite } from 'shared/utils/styleCommon';
import styled from 'styled-components';
import plusIcon from '../../shared/icons/plusIcon.svg';
import { useAppDispatch } from 'store/hooks';
import { setSource, setModalHandler } from 'store/slices/paymentSlice';

const PlusIcon = styled.div`
  background-color: ${cPrimary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  padding: 12px;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${cPrimaryLight};
  }
`;

type Props = {
  label: string | number;
  icon?: JSX.Element;
  source: string;
};

export const AddFundsButton = (props: Props) => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const navigate = useNavigate();
  return (
    <div
      style={{ border: `2px solid ${cPrimary}`, borderRadius: 40, position: 'relative', overflow: 'hidden' }}
      className="d-flex align-items-center gap-2"
    >
      <div className="w-100 h-100" style={{ position: 'absolute', background: cPrimary, opacity: 0.1 }}></div>

      <PlusIcon
        onClick={() => {
          dispatch(setSource(props.source));
          dispatch(setModalHandler('DEFAULT'));
          navigate(paths.payments.packages);
        }}
      >
        <img src={plusIcon} alt="" width="100%" />
      </PlusIcon>

      <Typography variant="description" styles={{ color: cWhite, whiteSpace: 'nowrap' }}>
        {props.label}
      </Typography>

      <div className="pe-2">{props.icon}</div>
    </div>
  );
};
