interface CategoryTree {
  category: string;
  subcategories: string[];
}

export const categories: CategoryTree[] = [
  {
    category: 'Rodzina',
    subcategories: ['Mama', 'Tata', 'Brat', 'Siostra', 'Babcia', 'Dziadek', 'Wujek', 'Ciocia', 'Kuzyn', 'Kuzynka']
  },
  {
    category: 'Pojazdy',
    subcategories: [
      'Samochód',
      'Statek piracki',
      'Magiczna karoca',
      'Podwodna łódź',
      'Kosmiczny statek',
      'Pociąg',
      'Balon powietrzny',
      'Śmigłowiec',
      'Smolot'
    ]
  },
  {
    category: 'Rośliny',
    subcategories: ['Drzewo', 'Kwiatek', 'Las', 'Kamień']
  },
  {
    category: 'Magiczne Stworzenia',
    subcategories: ['Smok', 'Jednorożec', 'Syrena', 'Feniks', 'Troll', 'Elf', 'Krasnolud', 'Ogr', 'Centaur', 'Wróżka']
  },
  {
    category: 'Zwierzęta Leśne',
    subcategories: ['Lis', 'Jeleń', 'Sowa', 'Dzik', 'Zając', 'Wiewiórka', 'Niedźwiedź', 'Wilk', 'Borsuk', 'Łoś']
  },
  {
    category: 'Postacie Bajkowe',
    subcategories: ['Księżniczka', 'Rycerz', 'Czarodziej', 'Pirat', 'Łotr', 'Król', 'Czarownica', 'Wojownik', 'Kapitan', 'Strażnik']
  },
  {
    category: 'Zwierzęta Domowe',
    subcategories: ['Pies', 'Kot', 'Kanarek', 'Chomik', 'Królik', 'Papuga', 'Rybka', 'Świnka morska', 'Szynszyla', 'Żółw']
  },
  {
    category: 'Eksploratorzy i Odkrywcy',
    subcategories: [
      'Podróżnik',
      'Poszukiwacz skarbów',
      'Naukowiec',
      'Archeolog',
      'Astronauta',
      'Łowca nagród',
      'Badacz głębin',
      'Alpinista',
      'Pilot',
      'Biolog morski',
      'Podróżnik w czasie'
    ]
  },
  {
    category: 'Zwierzęta morskie',
    subcategories: ['Delfin', 'Rekin', 'Krab', 'Żółw morski', 'Ośmiornica']
  },
  {
    category: 'Postacie z Kosmosu',
    subcategories: [
      'Kosmita',
      'Robot',
      'Pilot kosmiczny',
      'Strażnik galaktyczny',
      'Kosmiczny pirat',
      'Obserwator gwiazd',
      'Inżynier kosmiczny',
      'Komandor statku'
    ]
  },
  {
    category: 'Inne',
    subcategories: []
  }
];
