import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useAppDispatch } from 'store/hooks';
import { alertsSliceReset } from 'store/slices/alertsSlice';

export const Dashboard = () => {
  // redux
  const dispatch = useAppDispatch();
  // other
  const { pathname } = useLocation();
  useAnalytics();

  useEffect(() => {
    dispatch(alertsSliceReset());
  }, [pathname, dispatch]);

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <Outlet />
    </div>
  );
};
