export interface Product {
  id: string;
  price: number;
  name: string;
  description?: string;
  type: ProductType;
  properties: ProductProperties;
}

export enum ProductType {
  TOKEN_PACKAGE = 'TOKEN_PACKAGE'
}

export type ProductProperties = TokenPackageProperties;

export interface TokenPackageProperties {
  tokens: number;

  popularChoice?: boolean;

  bestValue?: boolean;
}

export const isTokenPackageProduct = (product: Product): product is Product & { properties: TokenPackageProperties } => {
  return product.type === ProductType.TOKEN_PACKAGE;
};
