import { Typography } from 'features/ui/typography/typography';
import { Badge } from 'react-bootstrap';
import { cWhite } from 'shared/utils/styleCommon';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';

type Props = {
  cost: number;
};

export const StoryElementCostBadge = (props: Props) => {
  return (
    <Badge bg="cost-badge">
      <Typography variant="description-sm" styles={{ color: cWhite }}>
        {props.cost}
      </Typography>
      <div style={{ width: 16, height: 16 }}>
        <img src={tokenIcon} alt="" width={'100%'} />
      </div>
    </Badge>
  );
};
