import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routing/utils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPaymentState, setAwaitingPaymentNotification } from 'store/slices/paymentSlice';
import { PaymentLoader } from './token-packages/paymentLoader';

export const PaymentRedirect = () => {
  // redux
  const source = useAppSelector(state => state.paymentSlice.source);
  const dispatch = useAppDispatch();
  // rtk
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  // other
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('transactionId');
  const status = searchParams.get('status');

  useEffect(() => {
    if (!_.isNil(transactionId) && source) {
      dispatch(
        setAwaitingPaymentNotification({
          transactionId: transactionId,
          status: status === 'success' ? 'INFO' : 'ERROR'
        })
      );
      navigate(source);
    } else if (!_.isNil(transactionId)) {
      dispatch(resetPaymentState());
      refetchUser();
      navigate(paths.root);
    }
  }, [transactionId, source, status, dispatch, navigate, refetchUser]);

  return <PaymentLoader />;
};
