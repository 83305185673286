import { TokenTrack } from 'shared/types/tokenTrack';
import { HTTP_METHODS, apiSlice } from '../api';
import { Page } from './storyEndpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTokenTracks: builder.mutation<Page<TokenTrack>, { page: number; size: number }>({
      query: data => ({
        url: `/token-tracks?page=${data.page}&size=${data.size}`,
        method: HTTP_METHODS.POST
      })
    })
  }),
  overrideExisting: false
});

export const { useGetTokenTracksMutation } = extendedApi;
