import { ConfirmationDialog } from 'features/ui/dialogs/confirmationDialog';
import { FormError } from 'features/ui/form/formError';
import { FormLength } from 'features/ui/form/formLength';
import { ImageEdit } from 'features/ui/image-edit/imageEdit';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Loadable } from 'features/ui/loadable/loadable';
import { Typography } from 'features/ui/typography/typography';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import {
  useCreateWorldMutation,
  useDeleteWorldMutation,
  useUpdateWorldMutation,
  useUploadWorldImageMutation
} from 'store/api/endpoints/worldEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { setTempWorld } from 'store/slices/storyCreatorSlice';

export const WorldCreator = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  // rtk
  const [createWorld, createWorldStatus] = useCreateWorldMutation();
  const [updateWorld, updateWorldStatus] = useUpdateWorldMutation();
  const [uploadWorldImage, uploadWorldImageStatus] = useUploadWorldImageMutation();
  const [deleteWorld, deleteWorldStatus] = useDeleteWorldMutation();
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  // other
  const { value } = useParams();
  const world = loggedUser?.worlds.find(w => w.value === value);
  const { blob } = useFetchBlob(world?.image, loggedUser?.id);

  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm<{ worldName: string }>({
    mode: 'onChange',
    defaultValues: {
      worldName: value ?? ''
    }
  });

  const onSubmit = (data: { worldName: string }) => {
    if (loggedUser) {
      const request = value
        ? updateWorld({ userId: loggedUser.id, worldName: value, world: { predefined: false, value: data.worldName } })
        : createWorld({ userId: loggedUser.id, world: { predefined: false, value: data.worldName } });

      request
        .unwrap()
        .then(async world => {
          if (avatar) {
            try {
              await uploadWorldImage({ userId: loggedUser.id, worldName: world.value, file: avatar }).unwrap();
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu świata.' }));
            }
          }
          refetchUser()
            .unwrap()
            .then(() => {
              dispatch(setTempWorld({ random: false, world }));
              router.navigate(paths.storyCreator.custom.world.base + `?selected=${world.value}`);
            })
            .catch(() =>
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zaktualizować danych użytkownika, prosimy o odświeżenie strony' }))
            );
        })
        .catch(() => {
          dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać profilu.' }));
        });
    }
  };

  const isLoading =
    createWorldStatus.isLoading || updateWorldStatus.isLoading || uploadWorldImageStatus.isLoading || deleteWorldStatus.isLoading;

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.world.base + `?selected=${value}`}
      actions={
        value
          ? [
              {
                action: () => setIsConfirmationDialogOpen(true),
                label: 'Usuń'
              }
            ]
          : []
      }
      bottomActions={[
        {
          label: 'Zapisz',
          submittable: true,
          formId: 'worldForm',
          disabled: !isValid || isLoading
        }
      ]}
    >
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={'Usunąć świat?'}
        content={'Czy na pewno chcesz usunąć świat?'}
        onAccept={() => {
          if (loggedUser?.id && value) {
            deleteWorld({ userId: loggedUser.id, worldName: value })
              .unwrap()
              .then(() => {
                refetchUser()
                  .unwrap()
                  .then(() => {
                    dispatch(setTempWorld({ random: true }));
                    router.navigate(paths.storyCreator.custom.world.base);
                  })
                  .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się usunąć świata' })));
              });
          }
        }}
        onAcceptText={'Tak'}
        onCancelText={'Anuluj'}
        onClose={() => setIsConfirmationDialogOpen(false)}
      />
      <Loadable loading={isLoading}>
        <div className="pt-4 mx-3">
          <Typography variant="h1">{world ? 'Edytuj świat' : 'Stwórz świat'}</Typography>
          <JustifyCenter classNames="mt-5 mb-3">
            <ImageEdit onFileChangeCallback={setAvatar} file={blob} />
          </JustifyCenter>
          <Form onSubmit={handleSubmit(onSubmit)} id="worldForm" noValidate>
            <Form.Group className="mb-3" controlId="worldForm.worldName">
              <Form.Label>Dodaj świat</Form.Label>
              <Controller
                name="worldName"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <Form.Control {...field} isInvalid={fieldState.invalid} required maxLength={50} />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}</div>
                      <div>
                        <FormLength current={field.value.length} max={50} />
                      </div>
                    </div>
                  </>
                )}
                rules={{ required: validationMessage.required }}
              />
            </Form.Group>
          </Form>
        </div>
      </Loadable>
    </RouteLayout>
  );
};
