import React from 'react';

export const formatTime = (time: number) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return '00:00';
};

export const CustomToggle = React.forwardRef<HTMLDivElement, { children: React.ReactNode; onClick: (e: React.MouseEvent) => void }>(
  ({ children, onClick }, ref) => (
    <div
      role="button"
      data-bs-display="static"
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

export const CustomMenu = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; style: React.CSSProperties; className: string; labeledBy?: string }
>(({ children, style, className, labeledBy }, ref) => (
  <div ref={ref} style={{ ...style, height: 116 }} className={className} aria-labelledby={labeledBy}>
    <ul className="list-unstyled">{children}</ul>
  </div>
));
