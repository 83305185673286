import _ from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useInterval } from 'shared/hooks/useInterval';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPaymentTime } from 'store/slices/paymentSlice';
import { setGeneratorTime } from 'store/slices/storyGeneratorSlice';

const PaymentTimerContext = createContext(0);

type Props = {
  children: React.ReactNode;
};

export const PaymentTimerProvider = (props: Props) => {
  // redux
  const isPaymentInProgress = useAppSelector(state => state.paymentSlice.isPaymentInProgress);
  const time = useAppSelector(state => state.paymentSlice.time);
  const dispatch = useAppDispatch();

  // other
  const prevIsPaymentInProgress = usePrevious(isPaymentInProgress);

  useEffect(() => {
    if (prevIsPaymentInProgress && !isPaymentInProgress) {
      dispatch(setGeneratorTime(0));
    }
  }, [prevIsPaymentInProgress, isPaymentInProgress, dispatch]);

  useInterval(
    () => {
      if (!_.isNil(time)) {
        dispatch(setPaymentTime(time + 1));
      }
    },
    isPaymentInProgress ? 1000 : null
  );

  return <PaymentTimerContext.Provider value={!_.isNil(time) ? time : 0}>{props.children}</PaymentTimerContext.Provider>;
};

export const usePaymentLoader = () => useContext(PaymentTimerContext);
