import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { useEffect, useState } from 'react';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { QuestionMarkIcon } from 'shared/icons/questionMarkIcon';
import { PredefinedWritingStyle } from 'shared/types/writingStyle';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useGetPredefinedWritingStylesQuery } from 'store/api/endpoints/predefinedWritingStyleEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { WritingStyleParam, setWritingStyle } from 'store/slices/storyCreatorSlice';
import { PickerItemPreview } from '../pickerItemPreview';

const defaultPredefinedWritingStyles: PredefinedWritingStyle[] = [];

export const WritingStylePicker = () => {
  const [selectedWritingStyle, setSelectedWritingStyle] = useState<WritingStyleParam>();
  // redux
  const dispatch = useAppDispatch();
  const writingStyle = useAppSelector(state => state.storyCreatorSlice.writingStyle);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);
  // rtk
  const { data: predefinedWritingStyles = defaultPredefinedWritingStyles } = useGetPredefinedWritingStylesQuery();

  const totalCost = getTotalCost('custom', narrator);

  useEffect(() => {
    setSelectedWritingStyle(writingStyle);
  }, [writingStyle]);

  return (
    <RouteLayout
      backRoute={paths.storyCreator.custom.base}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setWritingStyle(selectedWritingStyle));
            router.navigate(paths.storyCreator.custom.base);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />,
          action: () => {}
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <Typography variant="h1">Wybierz styl pisania</Typography>
        <div className="d-flex justify-content-center align-items-start gap-3 mt-5 flex-wrap">
          <div role="button" className="d-flex flex-column justify-content-start" onClick={() => setSelectedWritingStyle({ random: true })}>
            <div
              style={{
                outline: selectedWritingStyle?.random ? `2px solid ${cPrimary}` : `2px solid ${cGrey200}`,
                borderRadius: '10px',
                width: '160px',
                height: '166px'
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <QuestionMarkIcon fill={selectedWritingStyle?.random ? cPrimary : '#112B52'} />
            </div>
            <Typography variant="description" classNames="pt-2" styles={{ color: selectedWritingStyle?.random ? cPrimary : cSecondary }}>
              Losowy
            </Typography>
          </div>
          {predefinedWritingStyles.map(ws => (
            <div key={ws.id}>
              <PickerItemPreview
                isSelected={selectedWritingStyle?.predefinedWritingStyle?.id === ws.id}
                filename={ws.image}
                directory={'common'}
                name={ws.value}
                onClickCallback={() => {
                  setSelectedWritingStyle({ random: false, predefinedWritingStyle: ws });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </RouteLayout>
  );
};
