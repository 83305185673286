import _ from 'lodash';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { pxToRem } from 'shared/utils/commonUtils';
import { useGetPredefinedTagsQuery, useGetTagStoriesExistenceQuery } from 'store/api/endpoints/predefinedTagsEndpoint';
import { Typography } from '../../ui/typography/typography';
import { Category } from './category';

const defaultPredefinedTags: PredefinedTag[] = [];

export const Categories = () => {
  const { data: predefinedTags = defaultPredefinedTags } = useGetPredefinedTagsQuery();
  const { data: storyCountPerTag } = useGetTagStoriesExistenceQuery();

  if (!storyCountPerTag) {
    return <></>;
  }

  return (
    <div>
      <Typography variant="h4" classNames="pb-3">
        Kategorie
      </Typography>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${pxToRem(170)}, 1fr))`
        }}
      >
        {_.sortBy(predefinedTags, 'order').map(pt => {
          return (
            pt.id &&
            storyCountPerTag[pt.id] && (
              <div className="d-flex justify-content-center" key={pt.id}>
                <Category onCategoryChosenCallback={() => router.navigate(paths.search.category.base + `/${pt.value}`)} tag={pt} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
