import _ from 'lodash';
import { useEffect, useState } from 'react';
import { isTokenPackageProduct } from 'shared/types/product';
import { extendedApi } from 'store/api/endpoints/accountEndpoints';
import { extendedApi as productApi } from 'store/api/endpoints/productEndpoints';
import { extendedApi as transactionsApi } from 'store/api/endpoints/transactionEndpoint';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPaymentState } from 'store/slices/paymentSlice';
import { PaymentErrorModal } from '../paymentErrorModal';
import { PaymentSuccessModal } from '../paymentSuccessModal';

export const DefaultPaymentNotificationHandler = () => {
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] = useState(false);
  const [isPaymentErrorModalOpen, setIsPaymentErrorModalOpen] = useState(false);
  const [tokens, setTokens] = useState(0);
  // redux
  const awaitingNotification = useAppSelector(state => state.paymentSlice.awaitingNotification);
  const modalHandler = useAppSelector(state => state.paymentSlice.modalHandler);
  const dispatch = useAppDispatch();
  // rtk

  useEffect(() => {
    if (!_.isNil(awaitingNotification) && modalHandler === 'DEFAULT') {
      dispatch(resetPaymentState());
      dispatch(transactionsApi.endpoints.getTransactionById.initiate({ transactionId: awaitingNotification.transactionId }))
        .unwrap()
        .then(transaction => {
          dispatch(productApi.endpoints.getProductById.initiate(transaction.productId))
            .unwrap()
            .then(product => {
              if (isTokenPackageProduct(product)) {
                if (awaitingNotification.status === 'INFO') {
                  dispatch(extendedApi.endpoints.getLoggedUser.initiate(undefined, { forceRefetch: true }));
                  setTokens(product.properties.tokens);
                  setIsPaymentSuccessModalOpen(true);
                } else {
                  setIsPaymentErrorModalOpen(true);
                }
              }
            })
            .catch(e => console.error(e));
        })
        .catch(e => console.error(e));
    } else if (!_.isNil(awaitingNotification) && modalHandler === null) {
      dispatch(resetPaymentState());
      dispatch(extendedApi.endpoints.getLoggedUser.initiate(undefined, { forceRefetch: true }));
    }
  }, [dispatch, awaitingNotification, modalHandler]);

  return (
    <>
      <PaymentSuccessModal open={isPaymentSuccessModalOpen} tokens={tokens} onClose={() => setIsPaymentSuccessModalOpen(false)} />
      <PaymentErrorModal open={isPaymentErrorModalOpen} onClose={() => setIsPaymentErrorModalOpen(false)} />
    </>
  );
};
