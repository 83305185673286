import { skipToken } from '@reduxjs/toolkit/query';
import DOMPurify from 'dompurify';
import { AudioPlayer } from 'features/ui/audio/audioPlayer';
import { CustomImage } from 'features/ui/image/customImage';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useBeforeUnload, useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { WhiteCloud } from 'shared/images/whiteCloud';
import { Creator } from 'shared/types/story';
import { pxToRem } from 'shared/utils/commonUtils';
import { cGrey200, cSecondary } from 'shared/utils/styleCommon';
import { useGetLoggedUserQuery } from 'store/api/endpoints/accountEndpoints';
import { useSearchSeriesMutation } from 'store/api/endpoints/seriesEndpoints';
import { Operator, SearchOperation, useGetStoryByIdQuery } from 'store/api/endpoints/storyEndpoints';
import { useUpdateUserStoryInfoMutation } from 'store/api/endpoints/userStoryInfoEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { setTemplateId, setTemplateStoryId } from 'store/slices/storyCreatorSlice';
import printIcon from '../../shared/icons/printIcon.svg';
import { BlurOverlay } from './blurOverlay';
import { IPSUM } from './ipsum';
import { StoryPrintout } from './printout/storyPrintout';

export const StoryRenderer = () => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const printoutRef = useRef(null);
  const rootElement = useRef<HTMLDivElement>(null);
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const { storyId } = useParams();
  const { data: story, isError } = useGetStoryByIdQuery(storyId ?? skipToken);
  const [searchSeries, { data: series }] = useSearchSeriesMutation();
  const [updateUserStoryInfo] = useUpdateUserStoryInfoMutation();
  const { refetch: refetchUser } = useGetLoggedUserQuery();
  // other
  const isTemplateOriginalStory = story?.creator === Creator.SYSTEM;
  const { blob } = useFetchBlob(story?.audio?.fileName, isTemplateOriginalStory ? 'common' : story?.ownerId);
  const print = useReactToPrint({
    content: () => printoutRef?.current,
    documentTitle: 'bajka',
    onPrintError: () => {}
  });
  const location = useLocation();

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  useEffect(() => {
    setIsOverlayVisible(story?.creator === Creator.SYSTEM && Boolean(story?.templateId));
    rootElement.current?.scrollIntoView({ behavior: 'instant' });
  }, [story]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addAlert({ color: 'danger', text: 'Nie udało się wyświetlić bajki. Spróbuj ponownie lub wybierz inną bajkę.', clearable: false })
      );
    }
  }, [isError, dispatch]);

  useEffect(() => {
    if (loggedUser && storyId && !isOverlayVisible) {
      const existingUserStoryInfo = loggedUser.userStoryInfo.find(usi => usi.storyId === storyId);
      if (!existingUserStoryInfo) {
        updateUserStoryInfo({
          userId: loggedUser.id,
          userStoryInfo: {
            storyId,
            favorite: false,
            pauseTime: 0,
            read: false
          }
        })
          .unwrap()
          .then(() => refetchUser())
          .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się dodać bajki do biblioteki.' })));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, storyId, isOverlayVisible]);

  useBeforeUnload(() => {
    if (loggedUser && storyId) {
      const existingUserStoryInfo = loggedUser.userStoryInfo.find(usi => usi.storyId === storyId);
      if (existingUserStoryInfo) {
        updateUserStoryInfo({
          userId: loggedUser.id,
          userStoryInfo: {
            storyId,
            favorite: existingUserStoryInfo.favorite,
            pauseTime: seconds.current,
            read: existingUserStoryInfo.read,
            rating: existingUserStoryInfo.rating
          }
        });
      }
    }
  });

  useEffect(() => {
    if (storyId) {
      searchSeries({
        page: 0,
        size: 1,
        searchRequest: {
          operator: Operator.AND,
          searchCriteriaGroups: [
            {
              groupOperator: Operator.AND,
              searchCriteria: [
                {
                  filterKey: 'episodes.episodeId',
                  value: storyId,
                  operation: SearchOperation.EQUAL
                }
              ]
            }
          ]
        }
      });
    }
  }, [searchSeries, storyId]);

  const storyIdx = series?.content[0]?.episodes?.find(e => e.episodeId === storyId)?.index;
  const nextEpisodeId = !_.isNil(storyIdx) ? series?.content[0]?.episodes?.find(e => e.index === storyIdx + 1)?.episodeId : undefined;

  const Overlay = () => {
    return (
      <div className="w-100" style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ position: 'relative' }}>
          <BlurOverlay></BlurOverlay>
          <div className="d-flex flex-column gap-3 align-items-center w-100" style={{ marginTop: 64, zIndex: 100, position: 'absolute' }}>
            <Typography variant="h1" classNames="text-center">
              Czy chcesz dodać kilka rzeczy do Twojej bajki?
            </Typography>
            <Button variant="primary" onClick={() => setIsOverlayVisible(false)}>
              Czytamy
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                if (story?.templateId) {
                  dispatch(setTemplateId(story.templateId));
                  dispatch(setTemplateStoryId(story.id));
                  router.navigate(paths.storyCreator.template.base);
                }
              }}
            >
              Dopasuj
            </Button>
          </div>
          <Typography variant="description" styles={{ color: cGrey200 }}>
            {IPSUM}
          </Typography>
        </div>
      </div>
    );
  };

  const existingUserStoryInfo = loggedUser?.userStoryInfo.find(usi => usi.storyId === storyId);

  const seconds = useRef(existingUserStoryInfo?.pauseTime ?? 0);

  const dirtyHTML = story?.content ?? '';

  const sanitizedHTML = DOMPurify.sanitize(dirtyHTML, {
    RETURN_TRUSTED_TYPE: true,
    FORCE_BODY: true,
    ALLOWED_TAGS: ['html', 'head', 'style', 'body', 'p', 'span', 'div', 'br', 'li', 'ol', 'i'],
    ADD_ATTR: ['class', 'id']
  });

  return (
    <RouteLayout
      hideBottomNavigation
      backRoute={location.state === 'FROM_GENERATOR' ? paths.root : -1}
      backRouteAdditionalAction={() => {
        if (loggedUser && storyId) {
          const existingUserStoryInfo = loggedUser.userStoryInfo.find(usi => usi.storyId === storyId);
          if (existingUserStoryInfo) {
            updateUserStoryInfo({
              userId: loggedUser.id,
              userStoryInfo: {
                storyId,
                favorite: existingUserStoryInfo.favorite,
                pauseTime: seconds.current,
                read: existingUserStoryInfo.read,
                rating: existingUserStoryInfo.rating
              }
            });
          }
        }
      }}
      actions={
        storyId
          ? [
              {
                action: () => {
                  if (existingUserStoryInfo && loggedUser) {
                    updateUserStoryInfo({
                      userId: loggedUser.id,
                      userStoryInfo: {
                        storyId,
                        favorite: !existingUserStoryInfo.favorite,
                        pauseTime: existingUserStoryInfo.pauseTime,
                        read: existingUserStoryInfo.read,
                        rating: existingUserStoryInfo.rating
                      }
                    })
                      .unwrap()
                      .then(() => refetchUser())
                      .catch(() => dispatch(addAlert({ color: 'danger', text: 'Nie udało się dodać bajki do ulubionych.' })));
                  }
                },
                icon: loggedUser?.userStoryInfo.find(usi => usi.storyId === storyId)?.favorite ? (
                  <i style={{ fontSize: pxToRem(24), color: cSecondary }} className="bi bi-heart-fill"></i>
                ) : loggedUser ? (
                  <i style={{ fontSize: pxToRem(24), color: cSecondary }} className="bi bi-heart"></i>
                ) : (
                  <></>
                )
              },
              {
                action: print,
                icon: (
                  <>
                    <img src={printIcon} width={24} height={24} alt="" />
                    <div style={{ display: 'none' }}>
                      <StoryPrintout
                        ref={printoutRef}
                        title={story?.title ?? ''}
                        content={sanitizedHTML}
                        email={loggedUser?.email}
                        childName={story?.recipient?.name}
                      />
                    </div>
                  </>
                )
              }
            ]
          : []
      }
      bottomElement={
        blob && !isOverlayVisible ? (
          <div className="pt-3">
            <AudioPlayer
              trackSrc={blob}
              duration={story?.audio?.lengthInSeconds ?? 0}
              onTimeChangedCallback={s => {
                seconds.current = s;
              }}
              startingTime={existingUserStoryInfo?.pauseTime ?? 0}
            />
          </div>
        ) : undefined
      }
    >
      <div className="w-100 h-100" ref={rootElement}>
        {isOverlayVisible ? (
          <Overlay />
        ) : (
          <div>
            <div style={{ position: 'relative' }}>
              <CustomImage
                directory={story?.creator === Creator.SYSTEM || story?.templateId ? 'common' : story?.ownerId}
                filename={story?.image}
                width="100%"
              />
              {story?.image && <WhiteCloud />}
            </div>

            <div className="p-3" style={{ position: 'relative' }}>
              <CustomImage
                directory="common"
                filename="spiral.png"
                styles={{
                  position: 'absolute',
                  zIndex: 1,
                  right: 30,
                  top: 24,
                  transform: 'rotateY(180deg)',
                  rotate: '30deg',
                  overflow: 'hidden',
                  opacity: 0.7
                }}
              />

              <div className="pt-4">
                <Typography variant="h1" classNames="pb-4">
                  {story?.title}
                </Typography>
              </div>
              <div>
                <Typography variant="description" styles={{ fontFamily: '"PT Serif", serif' }}>
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                </Typography>
              </div>
            </div>
            <div style={{ position: 'relative' }} className="pb-4">
              <CustomImage directory="common" filename={'bottom_image.png'} width="100%" skeleton />

              <div style={{ position: 'absolute', top: 32, left: 0 }} className="w-100 d-flex justify-content-center">
                <Typography variant="h4">Koniec</Typography>
              </div>
            </div>

            {nextEpisodeId && (
              <JustifyCenter classNames="pb-4">
                <Button onClick={() => router.navigate(paths.story + `/${nextEpisodeId}`)}>Poznaj kolejną historię</Button>
              </JustifyCenter>
            )}

            {loggedUser && _.isNil(loggedUser.userStoryInfo.find(usi => usi.storyId === storyId)?.rating) && (
              <JustifyCenter classNames="pb-4">
                <Button
                  variant="link"
                  className="d-flex align-items-center gap-2"
                  onClick={() => router.navigate(paths.rating + `/${storyId}`)}
                >
                  <i className="bi bi-star" />
                  Zakończ i oceń
                </Button>
              </JustifyCenter>
            )}
          </div>
        )}
      </div>
    </RouteLayout>
  );
};
