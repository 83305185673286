export const ShortStoryIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9454 7.22C15.5804 5.855 13.7954 5.16667 11.9987 5.16667V12.1667L16.9454 7.22ZM11.9987 0.5C5.5587 0.5 0.332031 5.72667 0.332031 12.1667C0.332031 18.6067 5.5587 23.8333 11.9987 23.8333C18.4387 23.8333 23.6654 18.6067 23.6654 12.1667C23.6654 5.72667 18.4387 0.5 11.9987 0.5ZM11.9987 21.5C6.84203 21.5 2.66536 17.3233 2.66536 12.1667C2.66536 7.01 6.84203 2.83333 11.9987 2.83333C17.1554 2.83333 21.332 7.01 21.332 12.1667C21.332 17.3233 17.1554 21.5 11.9987 21.5Z"
        fill="#EC8D29"
      />
      <path d="M16.9454 7.22L11.9987 12.1667L18.5 9.56398C18.1572 8.7104 17.639 7.91069 16.9454 7.22Z" fill="#EC8D29" />
    </svg>
  );
};
