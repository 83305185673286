export const PaymentHistoryIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FFEBD6" />
      <path
        d="M22.5 9.28V7C22.5 5.9 21.6 5 20.5 5H6.5C5.39 5 4.5 5.9 4.5 7V21C4.5 22.1 5.39 23 6.5 23H20.5C21.6 23 22.5 22.1 22.5 21V18.72C23.09 18.37 23.5 17.74 23.5 17V11C23.5 10.26 23.09 9.63 22.5 9.28ZM21.5 11V17H14.5V11H21.5ZM6.5 21V7H20.5V9H14.5C13.4 9 12.5 9.9 12.5 11V17C12.5 18.1 13.4 19 14.5 19H20.5V21H6.5Z"
        fill="#EC8D29"
      />
      <path
        d="M17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14C16 14.8284 16.6716 15.5 17.5 15.5Z"
        fill="#EC8D29"
      />
    </svg>
  );
};
