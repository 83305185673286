import { Button } from 'react-bootstrap';
import { pxToRem } from 'shared/utils/commonUtils';
import { cError, cSecondary, cSuccess } from 'shared/utils/styleCommon';
import { RouteLayout } from '../layout/routeLayout';
import { Typography } from '../typography/typography';

type Props = {
  status: 'error' | 'success' | 'info';
  title: string;
  content: JSX.Element;
  actionName: string;
  actionCallback: () => void;
};

export const AlertPage = (props: Props) => {
  const getIcon = () => {
    switch (props.status) {
      case 'error':
        return <i style={{ fontSize: pxToRem(128), color: cError }} className="bi bi-emoji-frown"></i>;
      case 'success':
        return <i style={{ fontSize: pxToRem(128), color: cSuccess }} className="bi bi-check-circle"></i>;
      case 'info':
        return <i style={{ fontSize: pxToRem(128), color: cSecondary }} className="bi bi-info-circle"></i>;
    }
  };

  return (
    <RouteLayout>
      <div className="d-flex flex-column justify-content-around h-100 p-4">
        <div className="d-flex flex-column align-items-center">
          {getIcon()}
          <Typography variant="h1" classNames="text-center">
            {props.title}
          </Typography>
          {props.content}
        </div>
        <Button variant="primary" onClick={() => props.actionCallback()}>
          {props.actionName}
        </Button>
      </div>
    </RouteLayout>
  );
};
