import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'shared/types/users';
import { loadState } from 'store/utils';

const SLICE_NAME = 'authSlice';

export interface AuthState {
  accessToken?: string;
  loggedUser?: User;
}

export const LOGOUT_ACTION_TYPE = `${SLICE_NAME}/authSliceReset`;

const persistedState = loadState<AuthState>(SLICE_NAME);

const initialState: AuthState = {
  accessToken: undefined,
  loggedUser: undefined
};

export const authSlice = createSlice({
  name: SLICE_NAME,
  initialState: persistedState ?? initialState,
  reducers: {
    updateAccessToken(state, action: PayloadAction<string | undefined>) {
      state.accessToken = action.payload;
    },

    updateLoggedUser(state, action: PayloadAction<User | undefined>) {
      state.loggedUser = action.payload;
    },

    // changing name of this function imply the need of changing LOGOUT_ACTION_TYPE const
    authSliceReset() {
      return initialState;
    }
  }
});

export const { updateAccessToken, authSliceReset, updateLoggedUser } = authSlice.actions;

export default authSlice.reducer;
