export const NavSearchIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.2591 23.7581H24.9424L24.4758 23.3081C26.1091 21.4081 27.0924 18.9415 27.0924 16.2581C27.0924 10.2748 22.2424 5.4248 16.2591 5.4248C10.2758 5.4248 5.42578 10.2748 5.42578 16.2581C5.42578 22.2415 10.2758 27.0915 16.2591 27.0915C18.9424 27.0915 21.4091 26.1081 23.3091 24.4748L23.7591 24.9415V26.2581L32.0924 34.5748L34.5758 32.0915L26.2591 23.7581ZM16.2591 23.7581C12.1091 23.7581 8.75911 20.4081 8.75911 16.2581C8.75911 12.1081 12.1091 8.75814 16.2591 8.75814C20.4091 8.75814 23.7591 12.1081 23.7591 16.2581C23.7591 20.4081 20.4091 23.7581 16.2591 23.7581Z"
        fill="currentColor"
      />
    </svg>
  );
};
