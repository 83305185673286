import { Product, ProductType } from 'shared/types/product';
import { HTTP_METHODS, apiSlice } from '../api';

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getProductsByType: builder.query<Product[], { type: ProductType }>({
      query: data => ({
        url: `/public/products?type=${data.type}`,
        method: HTTP_METHODS.GET
      })
    }),
    getProductById: builder.query<Product, string>({
      query: productId => ({
        url: `/public/products/${productId}`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useGetProductsByTypeQuery } = extendedApi;
