import { Badge } from 'react-bootstrap';
import { cBeige, cPrimary, cSecondary, cWhite } from 'shared/utils/styleCommon';
import styled from 'styled-components';

type Props = {
  value: string;
  isActive: boolean;
  isDisabled?: boolean;
  onClickCallback: () => void;
};

const StyledBadge = styled(Badge)<{ isActive: boolean; isDisabled: boolean }>`
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')} !important;
  background-color: ${props => (props.isActive ? cPrimary : cBeige)} !important;
  opacity: ${props => (props.isDisabled ? 0.3 : 1)} !important;
  color: ${props => (props.isActive ? cWhite : cSecondary)} !important;
  padding: 6px 12px 6px 12px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
`;

export const TagBadge = (props: Props) => (
  <StyledBadge
    isActive={props.isActive}
    isDisabled={props.isDisabled ?? false}
    pill
    onClick={props.isDisabled ? undefined : props.onClickCallback}
  >
    {props.value}
  </StyledBadge>
);
