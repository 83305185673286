import { StoryCostBadge } from 'features/ui/payments/storyCostBadge';
import { getTotalCost } from 'features/payments/utils.payments';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { AvatarPreview } from 'features/user-profile/avatarPreview';
import { AVATAR_SIZE } from 'features/user-profile/utils';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { AddIcon } from 'shared/icons/addIcon';
import { pxToRem } from 'shared/utils/commonUtils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRecipient, setTempRecipient } from 'store/slices/storyCreatorSlice';

type Props = {
  prevRoute: string;
};

export const RecipientPicker = (props: Props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  // redux
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const recipient = useAppSelector(state => state.storyCreatorSlice.recipient);
  const tempRecipient = useAppSelector(state => state.storyCreatorSlice.tempRecipient);
  const narrator = useAppSelector(state => state.storyCreatorSlice.narrator);

  const templateMode = props.prevRoute === paths.storyCreator.template.base;

  useEffect(() => {
    if (!tempRecipient && recipient) {
      dispatch(setTempRecipient(recipient));
    }
  }, [dispatch, recipient, tempRecipient]);

  const pathPostfix = templateMode ? 'template' : 'custom';

  const createChildProfilePath = paths.storyCreator[pathPostfix].recipient.createChild;
  const editChildProfilePath = paths.storyCreator[pathPostfix].recipient.editChild;

  const totalCost = getTotalCost(pathPostfix, narrator);

  return (
    <RouteLayout
      backRoute={props.prevRoute}
      backRouteAdditionalAction={() => {
        dispatch(setTempRecipient(undefined));
      }}
      bottomActions={[
        {
          label: 'Zapisz',
          action: () => {
            dispatch(setRecipient(tempRecipient));
            dispatch(setTempRecipient(undefined));
            router.navigate(props.prevRoute);
          }
        }
      ]}
      actions={[
        {
          icon: <StoryCostBadge cost={totalCost} />,
          action: () => {}
        }
      ]}
    >
      <div className="pt-4 mx-3">
        <div className="d-flex justify-content-between">
          <Typography variant="h1">Wybierz dla kogo ma być bajka</Typography>
          <Button variant="link" onClick={() => setIsEditMode(prev => !prev)}>
            {isEditMode ? 'Zakończ' : 'Edytuj'}
          </Button>
        </div>

        <div className="d-flex align-items-start gap-3 pt-5 flex-wrap">
          {loggedUser?.childProfiles.map(p => (
            <div key={p.id}>
              <AvatarPreview
                editable={isEditMode}
                isSelected={tempRecipient === p.id}
                name={p.name}
                filename={p.image}
                onClickCallback={() => {
                  if (isEditMode) {
                    dispatch(setTempRecipient(p.id));
                    router.navigate(editChildProfilePath + `/${p.id}`);
                  } else if (p.id === tempRecipient) {
                    dispatch(setTempRecipient(undefined));
                  } else {
                    dispatch(setTempRecipient(p.id));
                  }
                }}
              />
            </div>
          ))}
          <div
            style={{ maxWidth: pxToRem(AVATAR_SIZE) }}
            role="button"
            className="d-flex flex-column justify-content-start align-items-start"
            onClick={() => router.navigate(createChildProfilePath)}
          >
            <AddIcon />
            <Typography variant="description" classNames="pt-1 text-center">
              Dodaj profil
            </Typography>
          </div>
        </div>
      </div>
    </RouteLayout>
  );
};
