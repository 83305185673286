import { cSecondary } from 'shared/utils/styleCommon';
import { Typography } from '../typography/typography';

type Props = {
  max: number;
  current: number;
};

export const FormLength = (props: Props): JSX.Element => (
  <Typography variant="description-xs" styles={{ color: cSecondary }}>
    {props.current}/{props.max}
  </Typography>
);
