import { StorySeries } from 'shared/types/series';
import { HTTP_METHODS, apiSlice } from '../api';
import { Page, SearchRequest } from './storyEndpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    searchSeries: builder.mutation<Page<StorySeries>, { page: number; size: number; searchRequest: SearchRequest }>({
      query: data => ({
        url: `/public/series/search?page=${data.page}&size=${data.size}&sortField=lastModifiedDate`,
        method: HTTP_METHODS.POST,
        body: data.searchRequest
      })
    }),
    getSeriesById: builder.query<StorySeries, string>({
      query: id => ({
        url: `/public/series/${id}`,
        method: HTTP_METHODS.GET
      })
    })
  }),
  overrideExisting: false
});

export const { useSearchSeriesMutation, useGetSeriesByIdQuery } = extendedApi;
