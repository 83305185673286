import { HomeScreenData } from 'shared/types/homeScreenData';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHomeScreenData: builder.query<HomeScreenData, void>({
      query: () => ({
        url: `/public/home-screen-data`,
        method: HTTP_METHODS.GET
      }),

      providesTags: ['HomeScreenData']
    })
  }),
  overrideExisting: false
});

export const { useGetHomeScreenDataQuery } = extendedApi;
