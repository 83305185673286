import { Typography } from 'features/ui/typography/typography';
import { cGrey200, cLightPrimary, cPrimary, cSecondary, cWhite } from 'shared/utils/styleCommon';

type Props = {
  icon: JSX.Element;
  topText: string;
  bottomText: string;
  isSelected: boolean;
  onClickCallback: (isSelected: boolean) => void;
};

export const SelectableItem = (props: Props): JSX.Element => {
  const border = props.isSelected ? `2px solid ${cPrimary}` : `1px solid ${cGrey200}`;
  const textColor = props.isSelected ? cPrimary : cSecondary;
  const backgroundColor = props.isSelected ? cLightPrimary : cWhite;

  return (
    <div
      className="d-flex align-items-center gap-3 p-2"
      role="button"
      style={{ outline: border, borderRadius: '5px', backgroundColor }}
      onClick={() => props.onClickCallback(!props.isSelected)}
    >
      <div>{props.icon}</div>
      <div className="d-flex flex-column gap-2">
        <Typography variant="h4" styles={{ color: textColor }}>
          {props.topText}
        </Typography>
        <Typography variant="description-sm" styles={{ color: textColor }}>
          {props.bottomText}
        </Typography>
      </div>
    </div>
  );
};
