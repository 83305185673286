import { RouteLayout } from 'features/ui/layout/routeLayout';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { useState } from 'react';
import { SubscriptionType } from 'shared/types/users';
import { Plan, SubscriptionPlan } from './subscriptionPlan';

const subscriptionPlans: Plan[] = [
  {
    image: 'free_sub.png',
    title: 'Free “Młody Odkrywca”',
    description: 'Rozpocznij swoją przygodę ze światem bajek skorzystaj z darmowego planu na start.',
    planItems: [
      {
        title: 'Custom',
        description: 'Brak',
        isIncluded: false
      },
      {
        title: 'Szablony',
        description: '30% + partnerzy',
        isIncluded: true
      },
      {
        title: 'Biblioteka offline',
        description: 'Nie',
        isIncluded: false
      },
      {
        title: 'Ekskluzywne tresci',
        description: 'Brak',
        isIncluded: false
      },
      {
        title: 'Wsparcie',
        description: 'Normalne',
        isIncluded: false
      }
    ],
    subscriptionType: SubscriptionType.FREE
  },
  {
    image: 'basic_sub.png',
    title: 'Podstawowy “Poszukiwacz Przygód”',
    description: 'Rozpocznij swoją przygodę ze światem bajek skorzystaj z darmowego planu na start.',
    planItems: [
      {
        title: 'Custom',
        description: 'Do 8 bajek po 10 min / miesiąc',
        isIncluded: true
      },
      {
        title: 'Szablony',
        description: '30% + partnerzy',
        isIncluded: true
      },
      {
        title: 'Biblioteka offline',
        description: 'Tak',
        isIncluded: true
      },
      {
        title: 'Ekskluzywne tresci',
        description: 'Brak',
        isIncluded: false
      },
      {
        title: 'Wsparcie',
        description: 'Priorytetowe',
        isIncluded: true
      }
    ],
    price: 'XX,XX',
    subscriptionType: SubscriptionType.BASIC
  },
  {
    image: 'advanced_sub.png',
    title: 'Rozbudowany “Mistrz Opowieści”',
    description: 'Rozpocznij swoją przygodę ze światem bajek skorzystaj z darmowego planu na start.',
    planItems: [
      {
        title: 'Custom',
        description: 'Do 12 bajek po 10 min / miesiąc',
        isIncluded: true
      },
      {
        title: 'Szablony',
        description: '30% + partnerzy',
        isIncluded: true
      },
      {
        title: 'Biblioteka offline',
        description: 'Tak',
        isIncluded: true
      },
      {
        title: 'Ekskluzywne tresci',
        description: 'Co miesiąc nowe',
        isIncluded: true
      },
      {
        title: 'Wsparcie',
        description: 'Priorytetowe',
        isIncluded: true
      }
    ],
    price: 'XX,XX',
    subscriptionType: SubscriptionType.ADVANCED
  }
];

export const SubscriptionPlans = () => {
  const [activePlanView, setActivePlanView] = useState(0);

  return (
    <RouteLayout backRoute={-1}>
      <div className="w-100 d-flex flex-column align-items-center">
        <div>
          <div className="d-flex gap-2 m-3">
            <TagBadge value={'Free'} isActive={activePlanView === 0} onClickCallback={() => setActivePlanView(0)} />
            <TagBadge value={'Podstawowy'} isActive={activePlanView === 1} onClickCallback={() => setActivePlanView(1)} />
            <TagBadge value={'Rozbudowany'} isActive={activePlanView === 2} onClickCallback={() => setActivePlanView(2)} />
          </div>
          <SubscriptionPlan plan={subscriptionPlans[activePlanView]} />
        </div>
      </div>
    </RouteLayout>
  );
};
