import { NotificationReadStatus, Notification } from 'shared/types/notification';
import { HTTP_METHODS, apiSlice } from '../api';

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    toggleReadStatus: builder.mutation<void, { userId: string; readStatuses: NotificationReadStatus[] }>({
      query: data => ({
        url: `/users/${data.userId}/notifications/toggle-read-status`,
        method: HTTP_METHODS.PATCH,
        body: data.readStatuses
      }),
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }]
    }),

    getNotifications: builder.query<Notification[], string>({
      query: userId => ({
        url: `/users/${userId}/notifications`,
        method: HTTP_METHODS.GET
      }),
      providesTags: result =>
        result
          ? [...result?.map(({ id }) => ({ type: 'Notification' as const, id })), { type: 'Notification', id: 'LIST' }]
          : [{ type: 'Notification', id: 'LIST' }]
    })
  }),
  overrideExisting: false
});

export const { useGetNotificationsQuery, useToggleReadStatusMutation } = extendedApi;
