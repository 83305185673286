import { RouteLayout } from 'features/ui/layout/routeLayout';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routing/utils';
import { apiSlice } from 'store/api/api';
import { useAppDispatch } from 'store/hooks';
import { authSliceReset } from 'store/slices/authSlice';
import { resetPaymentState } from 'store/slices/paymentSlice';
import { resetCreatorState } from 'store/slices/storyCreatorSlice';
import { resetStoryGeneratorState } from 'store/slices/storyGeneratorSlice';

export const Logout = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(authSliceReset());
    dispatch(resetPaymentState());
    dispatch(resetCreatorState());
    dispatch(resetStoryGeneratorState());
    navigate(paths.root);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RouteLayout>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </RouteLayout>
  );
};
