type Props = {
  fill: string;
};

export const QuestionMarkIcon = (props: Props) => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.5 52V61H29.5V52H38.5ZM16 25C16 15.055 24.055 7 34 7C43.945 7 52 15.055 52 25C52 30.7731 48.4449 33.8799 44.9834 36.9049C41.6996 39.7747 38.5 42.5708 38.5 47.5H29.5C29.5 39.3043 33.7395 36.0546 37.467 33.1975C40.3911 30.9562 43 28.9565 43 25C43 20.05 38.95 16 34 16C29.05 16 25 20.05 25 25H16Z"
          fill={props.fill}
        />
      </g>
    </svg>
  );
};
