import { CustomImage, ThumbnailSize } from 'features/ui/image/customImage';
import { Typography } from 'features/ui/typography/typography';
import { EditIcon } from 'shared/icons/editIcon';
import { cGrey200, cPrimary, cSecondary } from 'shared/utils/styleCommon';
import { useAppSelector } from 'store/hooks';
import { AVATAR_SIZE } from './utils';

type Props = {
  name?: string;
  filename?: string;
  isSelected?: boolean;
  editable?: boolean;
  onClickCallback: () => void;
};

export const AvatarPreview = (props: Props) => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);

  const onClickCallback = () => {
    props.onClickCallback();
  };

  return (
    <div style={{ position: 'relative', maxWidth: AVATAR_SIZE }} role="button" onClick={onClickCallback}>
      <CustomImage
        directory={loggedUser?.id}
        filename={props.filename}
        styles={{
          borderRadius: '50%',
          outline: props.isSelected ? `2px solid ${cPrimary}` : 'none',
          border: !props.filename && !props.isSelected ? `2px solid ${cGrey200}` : 'none'
        }}
        width={AVATAR_SIZE + 'px'}
        height={AVATAR_SIZE + 'px'}
        thumbnail={ThumbnailSize.THUMBNAIL_156X156}
        noImagePlaceholder
        skeleton
      />

      {props.editable && (
        <div style={{ position: 'absolute', top: -4, right: -4 }}>
          <EditIcon />
        </div>
      )}

      <Typography variant="description" classNames="pt-1 text-center" styles={{ color: props.isSelected ? cPrimary : cSecondary }}>
        {props.name}
      </Typography>
    </div>
  );
};
