export const NavLibraryIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33268 12.9998C9.33268 12.4476 8.88497 11.9998 8.33268 11.9998H7.66602C7.11373 11.9998 6.66602 12.4476 6.66602 12.9998V30.6665C6.66602 32.1332 7.86602 33.3332 9.33268 33.3332H26.9993C27.5516 33.3332 27.9993 32.8855 27.9993 32.3332V31.6665C27.9993 31.1142 27.5516 30.6665 26.9993 30.6665H10.3327C9.7804 30.6665 9.33268 30.2188 9.33268 29.6665V12.9998ZM30.666 6.6665H14.666C13.1993 6.6665 11.9993 7.8665 11.9993 9.33317V25.3332C11.9993 26.7998 13.1993 27.9998 14.666 27.9998H30.666C32.1327 27.9998 33.3327 26.7998 33.3327 25.3332V9.33317C33.3327 7.8665 32.1327 6.6665 30.666 6.6665ZM30.666 24.3332C30.666 24.8855 30.2183 25.3332 29.666 25.3332H15.666C15.1137 25.3332 14.666 24.8855 14.666 24.3332V10.3332C14.666 9.78089 15.1137 9.33317 15.666 9.33317H29.666C30.2183 9.33317 30.666 9.78089 30.666 10.3332V24.3332ZM17.3327 16.9998C17.3327 16.4476 17.7804 15.9998 18.3327 15.9998H26.9993C27.5516 15.9998 27.9993 16.4476 27.9993 16.9998V17.6665C27.9993 18.2188 27.5516 18.6665 26.9993 18.6665H18.3327C17.7804 18.6665 17.3327 18.2188 17.3327 17.6665V16.9998ZM17.3327 20.9998C17.3327 20.4476 17.7804 19.9998 18.3327 19.9998H21.666C22.2183 19.9998 22.666 20.4476 22.666 20.9998V21.6665C22.666 22.2188 22.2183 22.6665 21.666 22.6665H18.3327C17.7804 22.6665 17.3327 22.2188 17.3327 21.6665V20.9998ZM17.3327 12.9998C17.3327 12.4476 17.7804 11.9998 18.3327 11.9998H26.9993C27.5516 11.9998 27.9993 12.4476 27.9993 12.9998V13.6665C27.9993 14.2188 27.5516 14.6665 26.9993 14.6665H18.3327C17.7804 14.6665 17.3327 14.2188 17.3327 13.6665V12.9998Z"
        fill="currentColor"
      />
    </svg>
  );
};
