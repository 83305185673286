import { Account, Credentials } from 'shared/types/account';
import { User } from 'shared/types/users';
import { updateAccessToken, updateLoggedUser } from 'store/slices/authSlice';
import { HTTP_METHODS, apiSlice } from '../api';

interface UserUpdateRequest {
  id: string;
  firstName: string;
  email: string;
  playbackRate: number;
  surveyShown: boolean;
}

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation<void, Account>({
      query: body => ({
        url: '/register',
        method: HTTP_METHODS.POST,
        body
      })
    }),
    signIn: builder.mutation<{ id_token: string }, Credentials>({
      query: body => ({
        url: `/authenticate`,
        method: HTTP_METHODS.POST,
        body
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: accessToken } = await queryFulfilled;
          dispatch(updateAccessToken(accessToken.id_token));
          dispatch(extendedApi.endpoints.getLoggedUser.initiate());
        } catch {
          // no-op
        }
      },
      invalidatesTags: [{ type: 'HomeScreenData' }]
    }),
    activate: builder.query<void, string>({
      query: key => ({
        url: `/activate?key=${key}`,
        method: HTTP_METHODS.GET
      })
    }),
    getLoggedUser: builder.query<User, void>({
      query: () => ({
        url: `/account`,
        method: HTTP_METHODS.GET
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: loggedUser } = await queryFulfilled;
          dispatch(updateLoggedUser(loggedUser));
        } catch {
          // no-op
        }
      }
    }),
    requestResetPasswordLink: builder.mutation<void, string>({
      query: body => ({
        url: `/account/reset-password/init`,
        method: HTTP_METHODS.POST,
        body
      })
    }),
    saveNewPassword: builder.mutation<void, { key: string; newPassword: string }>({
      query: body => ({
        url: `/account/reset-password/finish`,
        method: HTTP_METHODS.POST,
        body
      })
    }),
    updateAccount: builder.mutation<void, UserUpdateRequest>({
      query: body => ({
        url: `/account`,
        method: HTTP_METHODS.POST,
        body
      }),
      invalidatesTags: [{ type: 'User' }]
    }),
    deleteAccount: builder.mutation<void, string>({
      query: userId => ({
        url: `/account/${userId}`,
        method: HTTP_METHODS.DELETE
      })
    })
  }),
  overrideExisting: false
});

export const {
  useRegisterMutation,
  useSignInMutation,
  useGetLoggedUserQuery,
  useLazyGetLoggedUserQuery,
  useActivateQuery,
  useRequestResetPasswordLinkMutation,
  useSaveNewPasswordMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation
} = extendedApi;
