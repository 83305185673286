export const TimerBackwardIcon = () => {
  return (
    <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.16699 6.33334L10.5003 3.33334C11.167 2.66667 11.167 1.66667 10.667 1C10.167 0.333335 9.00033 0.166669 8.33366 0.833335L3.66699 5C3.33366 5.33334 3.16699 5.83334 3.16699 6.16667C3.16699 6.66667 3.33366 7 3.66699 7.33334L8.16699 12C8.50033 12.3333 9.00033 12.5 9.33366 12.5C9.83366 12.5 10.167 12.3333 10.5003 12C11.167 11.3333 11.167 10.3333 10.5003 9.66667L7.16699 6.33334Z"
        fill="#112B52"
      />
      <path
        d="M0.833333 14.3333C0.333333 15.8333 0 17.3333 0 18.8333C0 20.6666 0.166667 22.6666 0.833333 24.3333C2.33333 28.6666 5.66667 31.9999 10 33.4999C11.6667 33.9999 13.3333 34.3333 15 34.3333C17 34.3333 19.1667 33.8333 21 32.9999C24.8333 31.3333 27.8333 28.1666 29.1667 24.3333C30.5 20.4999 30.3333 16.4999 28.5 12.8333C26.6667 9.16658 23.8333 6.49992 20 5.16659C18.5 4.66659 17.1667 4.33325 15.6667 4.33325L17 3.16659C17.6667 2.49992 17.6667 1.49992 17 0.833252C16.3333 0.166585 15.3333 0.166585 14.6667 0.833252L10 4.99992C9.83333 5.33325 9.5 5.66659 9.5 6.16659C9.5 6.66659 9.66667 6.99992 10 7.33325L14.6667 11.8333C15 12.1666 15.3333 12.3333 15.8333 12.3333C16.3333 12.3333 16.6667 12.1666 17 11.8333C17.6667 11.1666 17.6667 10.1666 17 9.49992L15 7.66659C16.3333 7.66659 17.5 7.83325 18.8333 8.33325C21.8333 9.33325 24.1667 11.4999 25.5 14.3333C26.8333 17.1666 27 20.3333 26 23.3333C25 26.3333 22.6667 28.8333 19.6667 30.1666C17 31.3333 14 31.4999 11.1667 30.4999C7.83333 29.3333 5.16667 26.6666 4 23.3333C3.5 21.8333 3.33333 20.3333 3.33333 18.8333C3.33333 17.6666 3.66667 16.4999 4 15.3333C4.33333 14.4999 3.83333 13.4999 3 13.1666C2.16667 12.9999 1.16667 13.4999 0.833333 14.3333Z"
        fill="#112B52"
      />
      <path
        d="M9.82009 22.3329H11.3463V18.3267C11.3463 18.1305 11.3517 17.9252 11.3626 17.7108L10.4633 18.4576C10.4051 18.5048 10.347 18.5393 10.2888 18.5611C10.2307 18.5793 10.1744 18.5884 10.1199 18.5884C10.0254 18.5884 9.94 18.5684 9.86369 18.5284C9.79102 18.4848 9.73651 18.4394 9.70017 18.3922L9.16602 17.6836L11.6406 15.6069H13.0359V22.3329H14.3441V23.5539H9.82009V22.3329Z"
        fill="#112B52"
      />
      <path
        d="M20.8747 19.5804C20.8747 20.2744 20.8002 20.8758 20.6513 21.3845C20.5023 21.8933 20.2952 22.3148 20.0299 22.6491C19.7683 22.9834 19.4558 23.2323 19.0924 23.3958C18.7327 23.5593 18.342 23.6411 17.9205 23.6411C17.499 23.6411 17.1084 23.5593 16.7486 23.3958C16.3925 23.2323 16.0837 22.9834 15.822 22.6491C15.5604 22.3148 15.3551 21.8933 15.2061 21.3845C15.0608 20.8758 14.9881 20.2744 14.9881 19.5804C14.9881 18.8863 15.0608 18.285 15.2061 17.7762C15.3551 17.2675 15.5604 16.846 15.822 16.5117C16.0837 16.1774 16.3925 15.9303 16.7486 15.7704C17.1084 15.6069 17.499 15.5251 17.9205 15.5251C18.342 15.5251 18.7327 15.6069 19.0924 15.7704C19.4558 15.9303 19.7683 16.1774 20.0299 16.5117C20.2952 16.846 20.5023 17.2675 20.6513 17.7762C20.8002 18.285 20.8747 18.8863 20.8747 19.5804ZM19.1305 19.5804C19.1305 19.0317 19.096 18.5829 19.027 18.2341C18.9579 17.8816 18.8653 17.6055 18.749 17.4056C18.6364 17.2057 18.5074 17.0695 18.362 16.9968C18.2167 16.9205 18.0695 16.8823 17.9205 16.8823C17.7715 16.8823 17.6244 16.9205 17.479 16.9968C17.3373 17.0695 17.2101 17.2057 17.0975 17.4056C16.9885 17.6055 16.8994 17.8816 16.8304 18.2341C16.765 18.5829 16.7323 19.0317 16.7323 19.5804C16.7323 20.1327 16.765 20.5833 16.8304 20.9321C16.8994 21.281 16.9885 21.5571 17.0975 21.7606C17.2101 21.9605 17.3373 22.0986 17.479 22.1749C17.6244 22.2475 17.7715 22.2839 17.9205 22.2839C18.0695 22.2839 18.2167 22.2475 18.362 22.1749C18.5074 22.0986 18.6364 21.9605 18.749 21.7606C18.8653 21.5571 18.9579 21.281 19.027 20.9321C19.096 20.5833 19.1305 20.1327 19.1305 19.5804Z"
        fill="#112B52"
      />
    </svg>
  );
};
