import { Moral } from 'shared/types/moral';
import { HTTP_METHODS, apiSlice } from '../api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadMoralImage: builder.mutation<void, { userId: string; moralName: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/users/${data.userId}/morals/${data.moralName}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    createMoral: builder.mutation<Moral, { userId: string; moral: Moral }>({
      query: data => {
        return {
          url: `/users/${data.userId}/morals`,
          method: HTTP_METHODS.POST,
          body: data.moral
        };
      }
    }),
    updateMoral: builder.mutation<Moral, { userId: string; moralName: string; moral: Moral }>({
      query: data => {
        return {
          url: `/users/${data.userId}/morals/${data.moralName}`,
          method: HTTP_METHODS.PUT,
          body: data.moral
        };
      }
    }),
    deleteMoral: builder.mutation<void, { userId: string; moralName: string }>({
      query: data => {
        return {
          url: `/users/${data.userId}/morals/${data.moralName}`,
          method: HTTP_METHODS.DELETE
        };
      }
    })
  }),
  overrideExisting: false
});

export const { useUploadMoralImageMutation, useCreateMoralMutation, useUpdateMoralMutation, useDeleteMoralMutation } = extendedApi;
