import { HTTP_METHODS, apiSlice } from '../api';

interface TransactionResponse {
  data?: TransactionResponseData;
  errors?: TransactionErrors;
}

interface TransactionErrors {
  tpayErrors?: PaymentErrors;
  errorCode?: TransactionErrorCode;
}

enum TransactionErrorCode {
  TPAY_TRANSACTION_FAILED,
  UNEXPECTED_TPAY_STATUS,
  TPAY_RESPONSE_VALIDATION_ERROR,
  UNEXPECTED_ERROR
}

interface PaymentErrors {
  errorCode?: string; // example: payment_failed
  errorMessage?: string; // example: Podany kod jest nieprawidłowy, bądź utracił ważność
  fieldName?: string;
  devMessage?: string;
  docUrl?: string;
}

interface TransactionResponseData {
  transactionId?: string;
  confirmationUrl?: string;
}

export const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    initTransaction: builder.mutation<TransactionResponse, { productId: string }>({
      query: data => ({
        url: `/init-transaction`,
        method: HTTP_METHODS.POST,
        body: data
      })
    })
  }),
  overrideExisting: false
});

export const { useInitTransactionMutation } = extendedApi;
