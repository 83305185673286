import { User } from 'shared/types/users';
import { HeroParam } from 'store/slices/storyCreatorSlice';

export const getHeroLabel = (loggedUser?: User, heroParam?: HeroParam): string => {
  if (!heroParam) {
    return 'Brak';
  }

  if (heroParam.random) {
    return 'Losowy';
  }

  if (heroParam.character?.childProfile) {
    return loggedUser?.childProfiles.find(p => p.id === heroParam.character?.id)?.name ?? 'Brak';
  } else {
    return loggedUser?.heroes.find(p => p.id === heroParam.character?.id)?.name ?? 'Brak';
  }
};

export const getHeroName = (loggedUser?: User, heroParam?: HeroParam): string | undefined => {
  if (heroParam?.character?.childProfile) {
    return loggedUser?.childProfiles.find(p => p.id === heroParam?.character?.id)?.name;
  } else {
    return loggedUser?.heroes.find(p => p.id === heroParam?.character?.id)?.name;
  }
};
