export const FacebookIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#FFEBD6" />
      <g clip-path="url(#clip0_2766_3841)">
        <path
          d="M20.75 5H7.25C6.00875 5 5 6.00875 5 7.25V20.75C5 21.9905 6.00875 23 7.25 23H20.75C21.9905 23 23 21.9905 23 20.75V7.25C23 6.00875 21.9905 5 20.75 5Z"
          fill="#EC8D29"
        />
        <path
          d="M17.375 14V11.75C17.375 11.129 17.879 11.1875 18.5 11.1875H19.625V8.375H17.375C15.5105 8.375 14 9.8855 14 11.75V14H11.75V16.8125H14V23H17.375V16.8125H19.0625L20.1875 14H17.375Z"
          fill="#FFEBD6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2766_3841">
          <rect width="18" height="18" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
