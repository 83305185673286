import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const FauxImageWrapper = styled.div<{ height: string; width: string }>`
  position: relative;
  padding-bottom: 100%;
  height: ${props => props.height};
  width: ${props => props.width};
`;

const FauxImage = styled.div`
  background: #dddddd;
  border-radius: 4px;
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Shimmer = styled.div`
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
    height: 100%;
    width: 100%;
    z-index: 1;
    animation: ${shimmerAnimation} 1s infinite;
  }
`;

type Props = {
  width: string;
  height: string;
};

export const Skeleton = (props: Props) => (
  <Shimmer>
    <FauxImageWrapper height={props.height} width={props.width}>
      <FauxImage />
    </FauxImageWrapper>
  </Shimmer>
);
