import { FormError } from 'features/ui/form/formError';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { Typography } from 'features/ui/typography/typography';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { validateEmail, validationMessage } from 'shared/utils/formUtils';
import { useRequestResetPasswordLinkMutation } from 'store/api/endpoints/accountEndpoints';

export const PasswordSettings = () => {
  // rtk
  const [reset, { isLoading }] = useRequestResetPasswordLinkMutation();

  const {
    control,
    formState: { isValid },
    reset: resetForm,
    handleSubmit
  } = useForm<{ email: string }>({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  });

  const onSubmit = (data: { email: string }) => {
    reset(data.email);
    resetForm();
  };

  return (
    <RouteLayout
      backRoute={-1}
      bottomActions={[
        {
          label: 'Wyślij',
          disabled: !isValid || isLoading,
          submittable: true,
          formId: 'resetPasswordForm'
        }
      ]}
    >
      <Form onSubmit={handleSubmit(onSubmit)} id="resetPasswordForm" noValidate className="mx-3 pt-5">
        <Form.Group className="mb-3" controlId="resetPasswordForm.email">
          <Form.Label>E-mail</Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <>
                  <Form.Control {...field} type="email" placeholder="E-mail" isInvalid={fieldState.invalid} required />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              );
            }}
            rules={{
              required: validationMessage.required,
              validate: validateEmail
            }}
          />
        </Form.Group>
        <Typography variant="description">Podaj adres email podany podczas rejestracji, a my wyślemy Ci link do zmiany hasła.</Typography>
      </Form>
    </RouteLayout>
  );
};
