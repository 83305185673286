import paths from 'routing/utils';
import { ChildRibbon } from './childProfileRibbon';
import { RouteLayout } from 'features/ui/layout/routeLayout';

export const ChildProfiles = () => {
  return (
    <RouteLayout backRoute={paths.profile.base}>
      <div className="mt-5 mx-3">
        <ChildRibbon />
      </div>
    </RouteLayout>
  );
};
