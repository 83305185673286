import { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';
import { cPrimary } from 'shared/utils/styleCommon';

interface ILoadableProps {
  loading: boolean;
  children: ReactElement;
}

export const Loadable: React.FC<ILoadableProps> = props => {
  return props.loading ? (
    <div className="d-flex justify-content-center w-100 p-2">
      <Spinner animation="border" color={cPrimary} />
    </div>
  ) : (
    props.children
  );
};
