import { AlertPage } from 'features/ui/alert-page/alertPage';
import { Typography } from 'features/ui/typography/typography';
import { useSearchParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';

enum PROVIDER_ERROR {
  DIFFERENT_PROVIDER_LOCAL = 'DIFFERENT_PROVIDER_LOCAL',
  DIFFERENT_PROVIDER_GOOGLE = 'DIFFERENT_PROVIDER_GOOGLE'
}
export const OAuthRedirectError = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const reason = searchParams.get('reason');

  const getError = (): { errorMessage: string; status: 'error' | 'success' | 'info' } => {
    switch (reason) {
      case PROVIDER_ERROR.DIFFERENT_PROVIDER_LOCAL:
        return {
          errorMessage:
            'Wygląda na to, że jesteś już zarejestrowany w naszym systemie. Użyj swojego konta systemowego, aby się zalogować. Jeżeli nie pamiętasz hasła, możesz je zresetować.',
          status: 'info'
        };
      case PROVIDER_ERROR.DIFFERENT_PROVIDER_GOOGLE:
        return {
          errorMessage:
            'Wygląda na to, że jesteś zarejestrowany przy użyciu swojego konta Google. Użyj swojego konta Google, aby się zalogować.',
          status: 'info'
        };

      default:
        return {
          errorMessage: 'Coś poszło nie tak, spróbuj powrócić do głównej strony',
          status: 'error'
        };
    }
  };

  const error = getError();

  return (
    <AlertPage
      title="Wystąpił problem z logowaniem"
      content={
        <Typography variant="description" classNames="pt-4 text-center">
          {error.errorMessage}
        </Typography>
      }
      status={error.status}
      actionName="Powrót"
      actionCallback={() => router.navigate(paths.root)}
    />
  );
};
