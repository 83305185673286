import { ChildProfile } from 'shared/types/users';

import { HTTP_METHODS, apiSlice } from '../api';

export type ChildProfileSaveRequest = Omit<ChildProfile, 'id'>;

const extendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    uploadProfileImage: builder.mutation<void, { userId: string; profileId: string; file: File }>({
      query: data => {
        const formData = new FormData();
        formData.append('file', data.file);

        return {
          url: `/users/${data.userId}/profiles/${data.profileId}/image`,
          method: HTTP_METHODS.PUT,
          body: formData
        };
      }
    }),
    createProfile: builder.mutation<ChildProfile, { userId: string; profile: ChildProfileSaveRequest }>({
      query: data => {
        return {
          url: `/users/${data.userId}/profiles`,
          method: HTTP_METHODS.POST,
          body: data.profile
        };
      },
      invalidatesTags: [{ type: 'HomeScreenData' }]
    }),
    updateProfile: builder.mutation<ChildProfile, { userId: string; profile: ChildProfile }>({
      query: data => {
        return {
          url: `/users/${data.userId}/profiles`,
          method: HTTP_METHODS.PUT,
          body: data.profile
        };
      },
      invalidatesTags: [{ type: 'HomeScreenData' }]
    }),
    deleteProfile: builder.mutation<void, { userId: string; profileId: string }>({
      query: data => {
        return {
          url: `/users/${data.userId}/profiles/${data.profileId}`,
          method: HTTP_METHODS.DELETE
        };
      },
      invalidatesTags: [{ type: 'HomeScreenData' }]
    })
  }),
  overrideExisting: false
});

export const { useUploadProfileImageMutation, useCreateProfileMutation, useUpdateProfileMutation, useDeleteProfileMutation } = extendedApi;
